import Login from './Login';
import Error from './Error';

export const routes = [
	{
		path: '/no-access',
		name: 'no-access',
		component: Error,
		props: {
			title: 'Forbidden',
			message: 'You do not have permission to access this resource, please contact the #control-centre team'
		}
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/logout',
		name: 'logout',
		component: Login,
		props: { isLogout: true }
	}
];

export default routes;
