<template>
	<DefaultLayout>
		<b-form novalidate class="ProductTypeAddEdit-form" @submit.prevent="handleSubmit">
			<OfsPanel>
				<ContentHeader :title="id ? $t('Edit Product Type') : $t('Add Product Type')" no-padding class="mb-3" />

				<of-form-input
					name="name"
					type="text"
					:label="$t('Name')"
					:placeholder="$t('Product Type name')"
					:disabled="!!$route.params.id"
					:show-errors="true"
					required
				/>
				<of-multi-select
					name="componentCodes"
					:label="$t('Components')"
					:placeholder="$t('Type components, and click enter to select')"
					:options="componentOptions"
					:taggable="true"
				/>

				<div slot="actions">
					<of-submit-button v-t="'Save'" />
				</div>
			</OfsPanel>
		</b-form>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import {
	ContentHeader,
	OfsPanel,
	OfSubmitButton,
	OfFormInput,
	OfMultiSelect,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { displayError } from '../../lib/helpers';
import DefaultLayout from '../../components/DefaultLayout';
import navigationMixin from '../../mixins/navigationMixin';
import { $t } from '../../vuex';

export default {
	name: 'ProductTypeAddEdit',
	components: {
		ContentHeader,
		OfsPanel,
		DefaultLayout,
		OfSubmitButton,
		OfFormInput,
		OfMultiSelect
	},
	mixins: [withForm('ProductTypeForm'), navigationMixin],
	data: () => ({
		componentOptions: []
	}),
	computed: {
		...mapGetters({
			productType: 'productCategory/productCategory'
		}),
		id() {
			return this.$route.params.id;
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getProductType: 'productCategory/get',
			createProductType: 'productCategory/create',
			updateProductType: 'productCategory/update'
		}),
		async fetchData() {
			this.isLoading = true;
			// init formData
			this.setFormData({});
			try {
				if (this.id) {
					await this.getProductType({ id: this.id });
					this.setFormData(this.productType);
				} else {
					this.setFormData({ componentCodes: [] });
				}
			} catch (err) {
				const message = $t(displayError(err));
				this.$notify({ type: 'error ', text: message });
			}
		},
		async handleSubmit() {
			const data = this.formData ? _.cloneDeep(this.formData) : {};
			try {
				if (this.id) {
					await this.updateProductType({ id: this.id, data: data });
				} else {
					await this.createProductType(data);
				}

				this.$notify({
					type: 'success',
					text: this.id ? $t('Product Type has been updated') : $t('Product Type has been created')
				});
				this.goBack({ name: 'productType.list' });
			} catch (err) {
				const message = $t(displayError(err));
				this.$notify({ type: 'error ', text: message });
			}
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.ProductTypeAddEdit {
	&-form {
		max-width: 800px;
	}
}
</style>
