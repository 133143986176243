import axios from 'axios';
import _ from 'lodash';

function makeQueryString(query) {
	const pairs = [];
	_.each(query, (v, k) => {
		if (v) pairs.push(`${k}=${v}`);
	});
	return pairs.join('&');
}

export const facetSearch = async ({ commit, dispatch, state }, { query, filter }) => {
	const path = 'search/facet/shipments';

	const { facetAlias } = state;
	commit('SET_SEARCH_QUERY', { query });
	commit('SET_SEARCH_FILTER', { filter });
	const payload = {
		query: state.search.query,
		facetFilters: _.reduce(
			state.search.filter,
			(acc, facetValue = [], facetName) => {
				const filterKey = facetAlias[facetName] || facetName;
				acc.push(facetValue.map(value => `${filterKey}:${value}`));
				return acc;
			},
			[]
		)
	};
	const {
		data: { hits, facets }
	} = await dispatch('request', { method: 'POST', path, payload });
	commit('SET_SEARCH_RESULTS', { hits });
	commit('SET_SEARCH_FACETS', { facets });
};

export const clearSearch = ({ commit }) => {
	commit('SET_SEARCH_QUERY', { query: '' });
	commit('SET_SEARCH_FILTER', { filter: {} });
	commit('SET_SEARCH_RESULTS', { hits: [] });
	commit('SET_SEARCH_FACETS', { facets: [] });
};

export const getOrderDetails = async ({ dispatch, commit }, id) => {
	const includes = ['batches', 'files', 'jobs', 'shipments'];
	const path = `order/details/${id}`;
	const data = await dispatch('request', { method: 'GET', path, params: { includes } }).then(r => r.data);
	commit('GET_ITEM_SUCCESS', { data });
	return data;
};

export const cancelOrder = ({ dispatch }, id) => {
	const path = `order/${id}/cancel`;
	return dispatch('request', { method: 'PUT', path }).then(r => r.data);
};

export const createPartialShipment = async ({ dispatch }, { id, data }) => {
	return dispatch('request', { method: 'POST', path: `shipment/partial/${id}`, payload: data }).then(r => r.data);
};

export const getThumbnailsFS = ({ dispatch }, fileId) => {
	const path = `file/${fileId}/thumbnails`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const getShipmentsByOrderId = async ({ dispatch, commit }, id) => {
	const path = `shipment/byorder/${id}`;
	const response = await dispatch('request', { method: 'GET', path }).then(r => r.data);
	commit('SET_ORDER_SHIPMENTS', response.data);
};

export const getOrderPostbacks = async ({ dispatch, commit }, id) => {
	const { data } = await dispatch('request', {
		method: 'GET',
		path: 'notification',
		params: {
			where: {
				'source.ref': 'order',
				'source.refId': id
			}
		}
	}).then(r => r.data);
	commit('SET_ORDER_POSTBACKS', data);
};

export const getPostback = ({ dispatch }, id) => {
	const path = `notification/${id}`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const downloadFile = ({ dispatch }, fileId) => {
	const path = `file/download/${fileId}/?_dc=${Math.random()}`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const loadPage = async ({ commit, state, dispatch, rootGetters }, { status }) => {
	commit('SET_STATUS', status);
	const currentAccount = rootGetters['account/currentAccount'] || {};

	const qs = {
		page: state.pagination.page,
		pagesize: state.pagination.pagesize,
		'source.id': currentAccount._id
	};

	if (state.status) {
		qs['orderData.status'] = state.status;
	}

	const path = `order?${makeQueryString(qs)}`;

	const results = await dispatch('request', { method: 'GET', path });
	commit('SET_ORDERS_COUNT', results.data.count);
	commit('SET_ORDERS', results.data.data);
	return results;
};

export const getIsoCountries = async ({ commit }) => {
	const path = '/data/iso.countries.json';
	const { data } = await axios.get(path);
	commit('SET_ISO_COUNTRIES', data);
};

export const getExtraData = async ({ commit, dispatch }) => {
	const path = 'order/extra';
	const { data } = await dispatch('request', { method: 'GET', path });
	commit('SET_EXTRA_DATA', data);
};

export const clearOrderShipments = ({ commit }) => {
	commit('SET_ORDER_SHIPMENTS', []);
};

export const getShipment = ({ dispatch }, id) => {
	const path = `shipment/${id}`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const saveShipment = ({ dispatch }, { id, data }) => {
	const path = `shipment/${id}/shipping`;
	return dispatch('request', { method: 'PUT', path, payload: data }).then(r => r.data);
};

export const getJobsByShipment = ({ dispatch }, id) => {
	const path = `job/byshipment/${id}`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const getJobsByTag = ({ dispatch }, tag) => {
	const path = `${window.$config.apiBase}/job?tag=/${encodeURIComponent(tag)}`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const getSubmissionErrors = async ({ dispatch, commit }, { page, perPage, options }) => {
	const path = 'ordersubmissionerror';

	const params = {
		'filter[offset]': (page - 1) * perPage,
		'filter[limit]': perPage,
		'filter[order]': '_id DESC'
	};
	if (options.asDestination) {
		params['filter[asDestination]'] = true;
		if (options.sourceId) {
			params['filter[where][accountId]'] = options.sourceId;
		}
	}

	const response = await dispatch('request', { method: 'GET', path, query: params });
	commit('SET_SUBMISSION_ERRORS', response.data);

	return response;
};

export const getSubmissionErrorForOrder = async ({ dispatch }, orderId) => {
	return dispatch('request', {
		method: 'GET',
		path: 'ordersubmissionerror',
		query: {
			'filter[where][orderId]': orderId
		}
	});
};

export const getSubmissionErrorsCount = async ({ dispatch, commit }, options) => {
	const path = 'ordersubmissionerror/count';
	const params = {};

	if (options.asDestination) {
		params.asDestination = true;
		if (options.sourceId) {
			params['where[accountId]'] = options.sourceId;
		}
	}

	const response = await dispatch('request', { method: 'GET', path, query: params });
	commit('SET_SUBMISSION_ERRORS_COUNT', response.data.count);

	return response;
};

export const getStockItemsThumbnails = async ({ commit, dispatch }, { shipmentId }) => {
	const path = `shipment/${shipmentId}/stock`;
	try {
		const { data } = await dispatch('request', {
			method: 'GET',
			path
		});
		commit('SET_STOCK_ITEMS_THUMBNAILS', { stockItems: data, shipmentId });
	} catch (err) {
		commit('SET_STOCK_ITEMS_THUMBNAILS', { stockItems: [], shipmentId });
		if (err.response.status !== 400) {
			throw err;
		}
	}
};

export const getSubmissionError = async ({ dispatch, commit }, id) => {
	const response = await dispatch('request', { method: 'GET', path: `ordersubmissionerror/${id}` });
	commit('SET_SUBMISSION_ERROR', response.data);
	return response;
};

export const updateSubmissionError = ({ dispatch }, { id, data }) => {
	const path = `ordersubmissionerror/${id}`;
	return dispatch('request', { method: 'PUT', path, payload: data });
};

export const getBodyUrl = ({ dispatch }, url) => dispatch('request', { method: 'GET', path: url });

export const submit = async ({ dispatch, commit, rootGetters }, { id, data }) => {
	let path = `${window.$config.orderSubmissionApiBase}/order`;
	let method = 'POST';
	let payload = _.cloneDeep(data);

	if (id) {
		path += `/submit/${id}`;
		method = 'PUT';
	} else {
		if (_.get(payload, 'orderData.status')) {
			delete payload.orderData.status;
		}
	}

	await dispatch('account/addPsp', data.destination.name, { root: true });
	await dispatch('account/setPsp', data.destination.name, { root: true });

	const { order } = await dispatch('request', { method, path, payload }).then(r => r.data);
	commit('SET_ORDER', order);
};

export const addFacet = ({ commit }, { key, value }) => {
	commit('ADD_SELECTED_FACET', { key, value });
};

export const removeFacet = ({ commit }, key) => {
	commit('REMOVE_SELECTED_FACET', key);
};

export const setPage = ({ commit }, page) => {
	commit('SET_PAGE', page);
};

export const setPageSize = ({ commit }, pagesize) => {
	commit('SET_PAGE_SIZE', pagesize);
};

export const createAgentJob = ({ commit, dispatch }, job) => {
	commit('CREATE_AGENT_JOB');
	return dispatch('request', { method: 'POST', path: `${window.$config.apiBase}/job`, payload: job });
};

export const getPrinters = ({ commit, dispatch }) => {
	commit('GET_PRINTERS');
	return dispatch('request', { method: 'GET', path: 'printer' });
};

export const printLabel = ({ dispatch }, { shipmentIndex, packetIndex, shipmentId, printer }) => {
	const url = `shipment/${shipmentId}/reprint`;
	const data = {
		shipmentIndex,
		packetIndex,
		type: 'label',
		printers: {
			carrier: printer,
			label: printer
		}
	};
	return dispatch('request', { method: 'POST', path: url, payload: data });
};

export const getConfigOptions = async ({ dispatch, commit }) => {
	const { data } = await dispatch('request', { method: 'GET', path: 'order/extra' });
	commit('SET_CONFIG_OPTIONS', data);
};

export const resetOrder = ({ commit }, order) => {
	commit('RESET_ORDER', order);
};

export const getPreflightProfiles = async ({ dispatch, commit }) => {
	const { data: response } = await dispatch('request', { method: 'GET', path: 'file/preflight-profile' });
	commit('SET_PREFLIGHT_PROFILES', response.data);
};

export const getThumbnails = ({ dispatch }, fileid) => {
	const path = `file/${fileid}/thumbnails`;
	return dispatch('request', { method: 'GET', path }).then(r => r.data);
};

export const getDownload = async ({ dispatch }, { fileId, params = { preview: true } }) => {
	const path = `file/download/${fileId}`;
	const { data } = await dispatch('request', { method: 'GET', path, params });
	return data;
};

export const setShippingMethod = ({ commit }, shippingMethod) => {
	commit('SET_SHIPPING_METHOD', shippingMethod);
};

export const addShippingMethod = ({ commit, getters }, shippingMethod) => {
	const existingShippingMethod = _.find(getters.shippingMethods, {
		value: shippingMethod
	});

	if (!existingShippingMethod) {
		commit('ADD_SHIPPING_METHOD', shippingMethod);
	}
};

export const clearShippingMethods = ({ commit }) => {
	commit('CLEAR_SHIPPING_METHODS');
};

export const fetchFiles = async ({ dispatch, commit }, { query, reset }) => {
	const path = 'file/gallery';
	const response = await dispatch('request', { method: 'GET', path, query }).then(r => r.data);
	commit('ADD_FILES', { files: response.data, reset });
	commit('SET_FILE_COUNT', response.count);
};

export const resetFileState = ({ commit }) => {
	commit('SET_FILE_COUNT', Infinity);
	commit('SET_FILES', []);
};

export const fetchFile = async ({ dispatch, commit }, { id, query }) => {
	const path = `file/${id}`;
	const response = await dispatch('request', { method: 'GET', path, query }).then(r => r.data);
	commit('SET_FILE', response);
};
