<template>
	<b-modal
		:title="$t('Refetch Item')"
		:ok-title="$t('Refetch')"
		:ok-disabled="!isValidForm"
		ok-variant="primary"
		@ok="submit"
		v-model="isVisible"
	>
		<p>{{ $t('Please select the component to be refetched and update URL if needed before refecthing.') }}</p>
		<b-row v-for="component in components" :key="component.code">
			<template v-if="formData">
				<b-col cols="2">
					<of-toggle class="RefetchModal__toggle" :name="`components.[${component.code}].active`" />
				</b-col>
				<b-col>
					<OfFormInput
						:label="`'${capitalize(component.code)}' ${$t('Component URL')}`"
						:placeholder="component.url"
						:show-errors="true"
						:disabled="!isComponentActive(component.code)"
						:name="`components.[${component.code}].url`"
					/>
				</b-col>
			</template>
		</b-row>
	</b-modal>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, OfToggle, withForm, validateWithMessage } from '@workflow-solutions/ofs-vue-layout';
import { required, requiredIf, minLength, url } from 'vuelidate/lib/validators';
import { displayError } from '../../../lib/helpers';

const formName = 'refetchForm';

export default {
	components: {
		OfFormInput,
		OfToggle
	},
	mixins: [withForm(formName)],
	props: {
		components: {
			type: Array,
			default() {
				return [];
			}
		},
		itemId: {
			type: String
		},
		accountId: {
			type: String
		},
		show: Boolean,
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	computed: {
		isVisible: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		isValidForm() {
			return !this.$v.$invalid && this.activeComponents.length > 0;
		},
		validationRules() {
			const componentRules = {};

			_.forEach(this.components, c => {
				componentRules[c.code] = {
					url: {
						required: validateWithMessage(this.$t('Please provide a URL for this component'), requiredIf(function(co) {
							return co?.active === true;
						})),
						url: validateWithMessage(this.$t('Please enter a valid URL'), url)
					}
				};
			});

			let rules = {
				formData: {
					components: {
						required,
						minLength: minLength(1),
						...componentRules
					}
				}
			};

			return rules;
		},
		activeComponents() {
			return _.map(
				_.filter(this.formData?.components, (c, key) => {
					return c.active === true;
				}),
				c => ({
					_id: c._id,
					code: c.code,
					url: c.url,
					pageCount: c.pageCount,
					sourceComponentId: c.sourceComponentId,
					fileId: c.fileId
				})
			);
		}
	},
	watch: {
		components: {
			immediate: true,
			handler(components) {
				let newComponents = {};
				components.forEach(co => {
					newComponents[co.code] = {
						...co,
						active: false
					};
				});
				this.initFormData({
					components: newComponents
				});
			}
		}
	},
	methods: {
		...mapActions({
			refetchItem: 'fulfilmentItem/refetch'
		}),
		async submit(e) {
			// Disable modal close
			e.preventDefault();

			try {
				await this.refetchItem({
					id: this.itemId,
					accountId: this.accountId,
					components: this.activeComponents
				});
				this.onClose(true);
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				this.onClose();
			}
		},
		isComponentActive(code) {
			return _.get(this.formData, `components[${code}].active`);
		},
		capitalize: _.capitalize
	}
};
</script>

<style lang="scss" scoped>
.RefetchModal {
	&__toggle {
		margin: 21px 0 0;
	}
}
</style>
