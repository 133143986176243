import Vue from 'vue';
import { each } from 'lodash';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'role',
		pluralName: 'roles',
		transport: 'connect',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'count' // -> `count` in the response payload
				}
			}
		},
		state: {
			resources: []
		},
		actions: {
			async getResources({ dispatch, commit }) {
				const routes = await dispatch('request', { method: 'GET', path: 'role/routes' }).then(r => r.data);
				commit('SET_ROLE_RESOURCES', routes);
				return routes;
			},
			async getWhitelistedRoles({ dispatch, commit }) {
				const roles = [];
				const { data } = await dispatch(
					'api/request',
					{ method: 'GET', path: 'role/lean' },
					{ root: true }
				).then(r => r.data);

				each(data, role => {
					const blacklistedRoles = [
						'admin',
						'anonymous',
						'syncserver',
						'printserver',
						'enquiries',
						'authorized',
						'hmac'
					];
					if (!blacklistedRoles.includes(role.name)) {
						roles.push(role);
					}
				});
				commit('FIND_SUCCESS', { data: roles });
			}
		},
		mutations: {
			SET_ROLE_RESOURCES(state, resources) {
				state.resources = resources;
			}
		},
		getters: {
			resources: state => state.resources
		}
	});
}
