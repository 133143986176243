var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"select",staticClass:"vue-dynamic-select MultiInput",class:{
                'vue-dynamic-select--focus': _vm.hasFocus,
                'vue-dynamic-select--empty': _vm.options.length === 0
            },attrs:{"tabindex":"0"},on:{"mouseover":function($event){_vm.isHovering = true},"mouseout":function($event){_vm.isHovering = false},"focusin":_vm.handleFocusIn}},[_c('div',{staticClass:"vue-dynamic-select__contents"},[_vm._l((_vm.options),function(ref){
            var value = ref.value;
            var valid = ref.valid;
            var message = ref.message;
            var id = ref.id;
return _c('div',{key:id,class:{
                            'selected-option--invalid': !valid,
                            'selected-option': true
                        },attrs:{"id":("option-" + id + "-" + _vm._uid)}},[_c('span',{domProps:{"textContent":_vm._s(value)}}),_c('a',{staticClass:"vue-dynamic-select__delete",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeOption(id)}}},[_c('icon',{staticClass:"vue-dynamic-select__delete-icon",attrs:{"name":"times-circle"}})],1),_c('b-tooltip',{attrs:{"disabled":valid,"target":("option-" + id + "-" + _vm._uid),"custom-class":"MultiInput_tooltip"}},[_c('template',{slot:"default"},[_c('div',{domProps:{"innerHTML":_vm._s(message)}})])],2)],1)}),(_vm.hasFocus)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"search",staticClass:"search",attrs:{"disabled":_vm.disableSearchInput,"autocomplete":"off"},domProps:{"value":(_vm.search)},on:{"keydown":_vm.handleKeyPress,"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}):_vm._e(),(_vm.showPlaceholder && !_vm.disableSearchInput)?_c('div',{staticClass:"placeholder",domProps:{"textContent":_vm._s(_vm.placeholder)}}):_vm._e()],2),_c('span',{ref:"clearBtn",staticClass:"vue-dynamic-select__clearButton",style:({ visibility: _vm.isClearBtnVisible ? 'visible' : 'hidden' }),attrs:{"tabindex":"0"},on:{"click":_vm.handleClearBtnClick}},[_c('icon',{attrs:{"name":"times-circle"}})],1)]),(_vm.disableSearchInput)?_c('label',{staticClass:"m-1 MultiInput-limit-label",domProps:{"textContent":_vm._s(_vm.limitMessage)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }