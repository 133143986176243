<template>
	<DefaultLayout class="LinkedAccountAdmin">
		<OfsPanel class="mx-3 mb-3">
			<ListTable
				:table-title="$t('Linked Accounts')"
				:config="config"
				bordered
				:items="linkedAccounts"
				:current-page="currentPage"
				:fields="fields"
				:total-items="count"
				:per-page="perPage"
				:is-busy="isBusy"
				:selected="selected"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				:active-filter-options="activeFilterOptions"
				:default-active-filter-value="filter"
				@table-change="handleTableChange"
			>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<div class="TableSearch" :class="{ 'TableSearch--show-clear': accountName }">
						<font-awesome-icon class="TableSearch-icon" icon="search" />
						<b-form-input
							v-model.trim="accountName"
							v-focus
							:placeholder="$t('Search by Account Name')"
							class="TableSearch-input"
							@change="handleSearchChange(true)"
						/>
						<div class="TableSearch-clear">
							<font-awesome-icon icon="times-circle" @click="clearSearch()" />
						</div>
					</div>
				</template>
				<template slot="TableButtons-Slot-right" slot-scope="{}">
					<b-btn class="ml-2" variant="primary" @click="openNewLinkModal">
						{{ $t('New Link') }}
					</b-btn>
				</template>
				<template slot="empty" slot-scope="{}">
					<span>
						<i>{{ $t('No data') }}</i>
					</span>
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template v-slot:cell(active)="data">
					<of-toggle
						v-if="isActive"
						:value="isActive"
						:label-cols="0"
						class="mb-0"
						@input="openDeactivateLinkModal(data.item)"
					/>
					<of-toggle
						v-else
						:value="isActive"
						:label-cols="0"
						class="mb-0"
						@input="handleActivate(data.item)"
					/>
				</template>
			</ListTable>
		</OfsPanel>
		<new-link-admin-modal :visible="isNewLinkModalVisible" @hide="handleNewLinkModalClose" />
		<deactivate-link-admin-modal
			:visible="isDeactivateLinkModalVisible"
			:item="selectedItem"
			:name-a="partnerNameA"
			:name-b="partnerNameB"
			@update="handleItemUpdated"
			@hide="handleDeactivateLinkModalClose"
		/>
	</DefaultLayout>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { ListTable, OfsPanel, OfToggle } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import tableMixin from '../../mixins/tableMixin';
import { displayError } from '../../lib/helpers';
import isCustomerServiceTeamMixin from '../../mixins/isCustomerServiceTeamMixin';
import DeactivateLinkAdminModal from './LinkedAccount/DeactivateLinkAdminModal';
import NewLinkAdminModal from './LinkedAccount/NewLinkAdminModal';
import { $t } from '../../vuex';

export default {
	name: 'LinkedAccountAdmin',
	components: {
		ListTable,
		OfsPanel,
		OfToggle,
		DefaultLayout,
		DeactivateLinkAdminModal,
		NewLinkAdminModal
	},
	mixins: [isCustomerServiceTeamMixin, tableMixin],
	data() {
		return {
			isBusy: false,
			fields: [
				{ key: 'nameA', label: this.$t('Account A Name') },
				{ key: 'nameB', label: this.$t('Account B Name') },
				{ key: 'createdAt', label: this.$t('Date added') },
				{ key: 'active', label: this.$t('Active') }
			],
			config: {
				activeFilter: { visible: true, title: this.$t('View Active/Inactive') },
				search: { visible: false },
				breadcrumbs: { visible: false },
				refresh: { visible: false },
				columns: { visible: false }
			},
			activeFilterOptions: [
				{ text: this.$t('Active'), value: true },
				{ text: this.$t('Inactive'), value: false }
			],
			selected: [],
			isNewLinkModalVisible: false,
			isDeactivateLinkModalVisible: false,
			selectedItem: null,
			partnerNameA: '',
			partnerNameB: '',
			accountName: ''
		};
	},
	computed: {
		...mapGetters({
			count: 'accountlink/allLinksCount',
			adminLinkedAccounts: 'accountlink/allLinks'
		}),
		filter() {
			const queryFilter = _.get(this.$route, 'query.filter', true);
			return typeof queryFilter === 'string' ? queryFilter === 'true' : queryFilter;
		},
		linkedAccounts() {
			return this.adminLinkedAccounts.map(accountlink => {
				const link = {};
				link._id = accountlink._id;
				link.nameA = accountlink.accountNameA;
				link.nameB = accountlink.accountNameB;
				link.createdAt = this.formatDate(accountlink.createdAt);
				link.active = accountlink.active;
				return link;
			});
		},
		isActive() {
			return this.filter;
		}
	},
	watch: {
		filter() {
			this.fetchData();
		}
	},
	created() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getAdminAccountLinks: 'accountlink/fetchAllLinks',
			updateAccountLink: 'accountlink/update'
		}),
		async fetchData(searchChanged) {
			try {
				const page = searchChanged ? this.firstPage : this.currentPage;
				const queryParams = { filter: this.filter, currentPage: page, perPage: this.perPage };
				const query = {
					active: this.filter,
					page: this.currentPage,
					pagesize: this.perPage
				};

				this.isBusy = true;

				if (this.accountName) {
					queryParams.accountName = this.accountName;
					query.name = this.accountName;
				}
				this.updateUrl(queryParams);
				await this.getAdminAccountLinks({ query });
			} catch (err) {
				const message = $t(displayError(err));
				this.$notify({ type: 'error ', text: message });
			} finally {
				this.isBusy = false;
			}
		},
		openNewLinkModal() {
			this.isNewLinkModalVisible = true;
		},
		handleNewLinkModalClose(refetch = false) {
			this.isNewLinkModalVisible = false;
			if (refetch) {
				this.fetchData();
			}
		},
		openDeactivateLinkModal(item) {
			this.selectedItem = item;
			this.partnerNameA = item.nameA;
			this.partnerNameB = item.nameB;
			this.isDeactivateLinkModalVisible = true;
		},
		handleDeactivateLinkModalClose() {
			this.isDeactivateLinkModalVisible = false;
		},
		handleItemUpdated: function() {
			return this.fetchData();
		},
		// activate doesn't require confirmation modal so we handle it here,
		// while deactivation is triggered from deactivate modal
		async handleActivate(item) {
			try {
				await this.updateAccountLink({ id: item._id, data: { active: true } });
				this.$notify({ type: 'success', text: $t('Account link activated') });
				this.fetchData();
			} catch (err) {
				this.$notify({ type: 'error', text: $t(displayError(err)) });
			}
		},
		formatDate(time) {
			return moment(time).accountFormat(' LT');
		},
		handleSearchChange: _.debounce(function(searchChanged) {
			this.fetchData(searchChanged);
		}, 500),
		clearSearch() {
			this.accountName = '';
			this.fetchData(true);
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.LinkedAccountAdmin {
	.search-input {
		height: 35px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		background-color: white;
		overflow: hidden;

		input[type='text']::-webkit-inner-spin-button,
		input[type='text']::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		input {
			border: none;
			height: 32px;
			padding-left: 15px;
			padding-right: 15px;

			&:focus {
				box-shadow: none;
			}
		}

		input::-webkit-autofill {
			background-color: white !important;
		}

		input::placeholder {
			color: $of-color-grey-3;
		}

		border: $of-color-grey-3 1px solid;

		.magnifying-glass {
			position: relative;
			left: 5px;
			margin-left: 5px;
			color: $of-color-grey-3;
		}

		.clear-input {
			position: relative;
			right: 5px;

			svg {
				margin-right: 5px;
				cursor: pointer;
			}
		}
	}
}
</style>
