import RoleList from './RoleList';
import RoleAddEdit from './RoleAddEdit';

const routes = [
	{
		path: '/role',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'role.list',
				path: 'list',
				component: RoleList
			},
			{
				name: 'role.edit',
				path: 'edit/:id',
				component: RoleAddEdit
			},
			{
				name: 'role.add',
				path: 'add',
				component: RoleAddEdit
			}
		]
	}
];

export default routes;
