import Vue from 'vue';
import VueResource from 'vue-resource';
import OfsCrud from '@workflow-solutions/ofs-vuex-crud';
import authModule from './auth';
import autoflowHttpTransport from './autoflowHttpTransport';
import registerOrdersModule from './orders';
import registerNotificationModule from './notification';
import registerAccountModule from './account';
import registerAccountLinkModule from './accountlink';
import registerMaterialModule from './materials';
import registerHpSpecTemplateModule from './hpSpecTemplates';
import registerFulfilmentDiscountProfileModule from './fulfilmentDiscountProfile';
import registerFulfilmentFeeModule from './fulfilmentFee';
import registerFulfilmentFeePriceModule from './fulfilmentFeePrice';
import registerFulfilmentCountryModule from './fulfilmentCountry';
import registerFulfilmentPriceConfigurationModule from './fulfilmentPriceConfiguration';
import registerFulfilmentCurrencyModule from './fulfilmentCurrency';
import registerFulfilmentConversionRateModule from './fulfilmentConversionRate/index';
import registerFulfilmentSpecificationModule from './fulfilmentSpecification';
import registerFulfilmentTariffModule from './fulfilmentTariffs';
import registerShipmentPriceSheetModule from './shipmentPriceSheet';
import registerShipmentServiceLevelModule from './shipmentServiceLevel';
import registerShipmentWeightBandModule from './shipmentWeightBand';
import registerSpecTemplatesModule from './specTemplates';
import registerComponentsModule from './components';
import registerLangStoreModule from './lang';
import registerUserModule from './user';
import registerShipmentModule from './shipment';
import registerFeatureFlagsModule from './featureFlags';
import registerFulfilmentOrderModule from './fulfilmentOrder';
import registerUIModule from './ui';
import httpOfBaseApiTransportInstance from './httpOfBaseApiTransportInstance';
import httpConnectProviderInstance from './httpConnectTransportInstance';
import httpProductApiTransportInstance from './httpProductApiTransportInstance';
import httpFulfilmentApiTransportInstance from './httpFulfilmentApiTransportInstance';
import httpControlCentreApiTransportInstance from './httpControlCentreApiTransportInstance';
import registerFulfilments from './fulfilment';
import registerFulfilmentItems from './fulfilmentItem';
import registerControlCentre from './controlCentre';
import registerControlCentreAccount from './controlCentreAccount';
import registerAutoflowAccount from './autoflowAccount';
import registerAccountTools from './accountTools';
import registerControlCentreFeature from './controlCentreFeature';
import registerControlCentreDataReadyOrder from './controlCentreDataReadyOrder';
import registerControlCentreSegment from './controlCentreSegment';
import registerControlCentreLog from './controlCentreLogs';
import registerFulfilmentSubmissionError from './fulfilmentSubmissionError';
import registerRoleModule from './role';
import registerDeviceTypeModule from './device-type';
import registerProductTypeModule from './productType';
import registerTransformerModule from './transformer';

const setupModules = (store, i18n) => {
	Vue.use(VueResource);
	Vue.use(OfsCrud, { store });

	// Register Transports
	Vue.$ofsCrud.registerTransport({ name: 'autoflow', fn: () => autoflowHttpTransport });
	Vue.$ofsCrud.registerTransport({ name: 'ofBaseApi', fn: () => httpOfBaseApiTransportInstance });
	Vue.$ofsCrud.registerTransport({ name: 'fulfilmentApi', fn: () => httpFulfilmentApiTransportInstance });
	Vue.$ofsCrud.registerTransport({ name: 'connect', fn: () => httpConnectProviderInstance });
	Vue.$ofsCrud.registerTransport({ name: 'productApi', fn: () => httpProductApiTransportInstance });
	Vue.$ofsCrud.registerTransport({ name: 'controlCentreApi', fn: () => httpControlCentreApiTransportInstance });
	Vue.$ofsCrud.registerTransport({ name: 'fulfilmentApi', fn: () => httpFulfilmentApiTransportInstance });

	// Register Modules
	Vue.$ofsCrud.registerAuthModule(authModule);
	registerAccountModule(store);
	registerAccountLinkModule();
	registerAutoflowAccount();
	registerMaterialModule();
	registerHpSpecTemplateModule();
	registerFulfilmentSpecificationModule();
	registerFulfilmentOrderModule();
	registerNotificationModule();
	registerFulfilmentCountryModule();
	registerFulfilmentDiscountProfileModule();
	registerFulfilmentFeeModule();
	registerFulfilmentFeePriceModule();
	registerFulfilmentPriceConfigurationModule();
	registerFulfilmentCurrencyModule();
	registerFulfilmentConversionRateModule();
	registerFulfilmentTariffModule();
	registerShipmentPriceSheetModule();
	registerShipmentServiceLevelModule();
	registerShipmentWeightBandModule();
	registerSpecTemplatesModule(store);
	registerComponentsModule(store);
	registerLangStoreModule(store, i18n);
	registerUserModule(store);
	registerShipmentModule(store);
	registerOrdersModule(store);
	registerFeatureFlagsModule(store);
	registerUIModule(store);
	registerFulfilments();
	registerFulfilmentItems();
	registerFulfilmentSubmissionError();
	registerControlCentre();
	registerControlCentreAccount();
	registerAccountTools();
	registerControlCentreDataReadyOrder();
	registerControlCentreFeature();
	registerControlCentreSegment();
	registerControlCentreLog();
	registerRoleModule();
	registerDeviceTypeModule();
	registerProductTypeModule();
	registerTransformerModule();
};

export default setupModules;
