<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3" />
			<b-form>
				<b-row>
					<b-col lg="8">
						<b-row>
							<b-col lg="6">
								<of-form-input name="name" data-test-id="materialName" :label="$t('Name')" />
							</b-col>
							<b-col lg="6">
								<of-form-input
									name="description"
									data-test-id="materialDescription"
									:label="$t('Description')"
								/>
							</b-col>
						</b-row>
						<b-row>
							<b-col lg="6">
								<of-multi-select
									name="type"
									:label="$t('Type')"
									:horizontal="false"
									:allow-clear="false"
									data-test-id="materialType"
									track-by="value"
									:options="materialOptions"
									:searchable="false"
									:placeholder="$t('Material Type')"
									:disabled="true"
								/>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<hr class="form-divider" />
				<h3 class="form-header">{{ $t('What grades would you like to offer?') }}</h3>
				<b-row v-for="(grade, index) in formData.grades" :key="`grade-${index}`">
					<b-col lg="8">
						<b-row>
							<b-col md="6">
								<of-form-input
									:name="`grades[${index}].weight`"
									:label="$t('Paper Weight')"
									:normalize="valueToInt"
									data-test-id="materialPaperWeight"
									type="number"
								>
									<template slot="append">
										<b-form-select
											v-model="formData.grades[index].weightUnit"
											:options="weightUnits"
											class="GradeUnit"
											disabled
										/>
									</template>
								</of-form-input>
							</b-col>
							<b-col md="6">
								<of-form-input
									:name="`grades[${index}].grade`"
									:label="$t('Paper Grade')"
									:normalize="valueToFloat"
									data-test-id="materialPaperGrade"
									type="number"
								>
									<template slot="append">
										<div class="GradeOptions">
											<b-form-select
												v-model="formData.grades[index].gradeUnit"
												:options="gradeUnits"
												class="GradeUnit"
												disabled
											/>
											<a class="RemoveGrade" href="#" @click.prevent="removeGrade(index)">
												<font-awesome-icon
													v-b-tooltip.hover
													icon="minus-circle"
													class="RemoveGradeIcon"
													:title="$t('Remove Material Grade')"
												/>
											</a>
										</div>
									</template>
								</of-form-input>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row>
					<b-col lg="8">
						<ofs-feature-button
							type="add"
							:label="
								formData.grades && formData.grades.length
									? $t('Add another')
									: $t('Add a material grade')
							"
							@click="addGrade"
						/>
					</b-col>
				</b-row>
			</b-form>
			<template slot="actions">
				<of-submit-button @click.prevent="save">{{ $t('Save') }}</of-submit-button>
			</template>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	ContentHeader,
	OfsPanel,
	OfsFeatureButton,
	OfFormInput,
	OfMultiSelect,
	OfSubmitButton,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { required, minLength, minValue } from 'vuelidate/lib/validators';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import { displayError, valueToInt, valueToFloat } from '../../../lib/helpers';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';
import { mapActions, mapGetters } from 'vuex';

const formName = 'editMaterial';

export default {
	components: {
		ContentHeader,
		Loader,
		OfsPanel,
		OfsFeatureButton,
		DefaultLayout,
		OfFormInput,
		OfMultiSelect,
		OfSubmitButton
	},
	mixins: [isFulfilmentAdminMixin, withForm(formName)],
	data() {
		return {
			isLoading: false
		};
	},
	computed: {
		...mapGetters({
			material: 'material/material'
		}),
		id() {
			return this.$route.params.id;
		},
		pageTitle() {
			return this.id ? this.$t('Edit Material') : this.$t('New Material');
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Materials'),
					to: {
						name: 'admin.materials'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		materialOptions() {
			return [{ text: this.$t('Paper'), value: 'paper' }];
		},
		gradeUnits() {
			return [
				{ text: this.$t('μm'), value: 'microns' },
				{ text: this.$t('ppi'), value: 'ppi' }
			];
		},
		weightUnits() {
			return [{ text: this.$t('g/sm'), value: 'gsm' }];
		},
		validationRules() {
			let rules = {
				formData: {
					name: {
						required
					},
					type: {
						required
					},
					grades: {
						required,
						minLength: minLength(1),
						$each: {
							weight: {
								required,
								minValue: minValue(0)
							},
							weightUnit: {
								required
							},
							grade: {
								required,
								minValue: minValue(0)
							},
							gradeUnit: {
								required
							}
						}
					}
				}
			};

			return rules;
		}
	},
	watch: {
		$route: 'fetchData',
		id: 'fetchData',
		material: {
			immediate: true,
			handler(value) {
				// If the material updates, update the form data
				this.setFormData(value);
			}
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			findMaterial: 'material/findById',
			updateMaterial: 'material/update',
			createMaterial: 'material/create',
			updateFormSummary: 'form/updateFormSummary'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				if (this.id) {
					await this.findMaterial({ id: this.id });
				} else {
					// If a new material, initialise the form
					this.initFormData({
						type: 'paper',
						grades: []
					});
				}
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t('Unable to load material') });
				this.$router.push({ name: 'admin.materials' });
			} finally {
				this.isLoading = false;
			}
		},
		async save() {
			try {
				if (this.id) {
					await this.updateMaterial({
						id: this.id,
						data: this.formData
					});
					this.$notify({ type: 'success', text: this.$t('Material updated successfully') });
				} else {
					await this.createMaterial(this.formData);
					this.$notify({ type: 'success', text: this.$t('Material created successfully') });
				}
				this.$router.push({ name: 'admin.materials' });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		},
		async removeGrade(gradeIndex) {
			const { grade, gradeUnit, weight, weightUnit } = this.formData.grades.find(
				(_grade, index) => gradeIndex === index
			);
			const modalTitle = `${weight}(${weightUnit}) / ${grade}(${gradeUnit})`;

			const confirm = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to delete this Grade?'), {
				title: `${this.$t('Delete Grade')}: ${modalTitle}`,
				size: 'md',
				okTitle: this.$t('Yes'),
				cancelTitle: this.$t('No')
			});

			if (confirm) {
				const newGrades = this.formData.grades.filter((_grade, index) => gradeIndex !== index);
				this.setFormData({ ...this.formData, grades: newGrades });
			}
		},
		async addGrade() {
			const formData = _.cloneDeep(this.formData);
			formData.grades.push({
				grade: null,
				gradeUnit: 'microns',
				weight: null,
				weightUnit: 'gsm'
			});
			await this.setFormData(formData);
		},
		valueToInt,
		valueToFloat
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.GradeOptions {
	display: flex;
	align-items: center;
}

.GradeUnit {
	height: 100%;
	border-radius: 0 3px 3px 0;
}

.RemoveGrade {
	display: flex;
	flex: 1;
	height: 100%;
	align-items: center;
	min-width: 30px;
	width: 30px;
	justify-content: flex-end;

	&Icon {
		color: $of-color-grey-2;
	}

	&:hover {
		.RemoveGradeIcon {
			color: $of-color-grey-1;
		}
	}
}
</style>
