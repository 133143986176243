<template>
	<DefaultLayout class="RoleList">
		<OfsPanel class="mx-3 mb-3">
			<ListTable
				:table-title="$t('Role list')"
				:config="config"
				bordered
				:items="roles"
				:current-page="currentPage"
				:fields="fields"
				:total-items="count"
				:per-page="perPage"
				:fetch-data="fetchData"
				:is-busy="isLoading"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="handleRowClick"
				@table-change="handleTableChange"
			>
				<template slot="empty" slot-scope="{}">
					<span>
						<i>{{ $t('No data') }}</i>
					</span>
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
			</ListTable>
		</OfsPanel>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ListTable, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import tableMixin from '../../mixins/tableMixin';
import navigationMixin from '../../mixins/navigationMixin';
import _throttle from 'lodash/throttle';
import { $t } from '../../vuex';

export default {
	name: 'RoleList',
	components: {
		ListTable,
		OfsPanel,
		DefaultLayout
	},
	mixins: [tableMixin, navigationMixin],
	data() {
		return {
			isLoading: false,
			fields: [{ key: 'name', label: this.$t('Name') }],
			config: {
				filter: { visible: false },
				search: { visible: false },
				breadcrumbs: { visible: false },
				refresh: { visible: true, title: this.$t('Refresh') },
				columns: { visible: false },
				add: {
					visible: true,
					href: { name: 'role.add' },
					title: this.$t('Add New')
				}
			}
		};
	},
	computed: {
		...mapGetters({
			count: 'role/count',
			roles: 'role/roles'
		})
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getRoles: 'role/find'
		}),
		fetchData: _throttle(
			async function() {
				try {
					this.isLoading = true;
					const query = { direction: 1, sort: 'name', pagesize: this.perPage, page: this.currentPage };
					this.updateUrl();
					await this.getRoles({ query });
				} catch (err) {
					const message = $t(this.$errorUtils.displayError(err));
					this.$notify({ type: 'error ', text: message });
				} finally {
					this.isLoading = false;
				}
			},
			500,
			{ trailing: false }
		),
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') {
				return;
			}

			this.goTo({ name: 'role.edit', params: { id: item._id } });
		}
	}
};
</script>
