import AccessController from './AccessController';

const routes = [
	{
		path: '/access-controller',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'access-controller',
				path: '',
				component: AccessController
			}
		]
	}
];

export default routes;
