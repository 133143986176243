<template>
	<DefaultLayout>
		<ofs-panel>
			<section v-if="isLoading">
				<Loader />
			</section>
			<list-table
				v-else
				:table-title="pageTitle"
				:items="currencies"
				:fields="fields"
				:total-items="currencyCount"
				:per-page="perPage"
				:current-page="currentPage"
				:is-busy="isBusy"
				hover
				@row-clicked="itemClicked"
				@table-change="handleTableChange"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<b-button id="TableButtons-question" variant="secondary" class="mr-2">
						<icon name="question-circle" />
					</b-button>
					<b-tooltip target="TableButtons-question" variant="light">
						<h3>{{ $t('Currencies') }}</h3>
						<div>{{ currenciesTooltipText1 }} {{ currenciesTooltipText2 }}</div>
					</b-tooltip>
					<b-button variant="primary" :to="{ name: 'admin.currencies.create' }">
						{{ $t('New Currency') }}
					</b-button>
				</template>
				<!-- <template v-slot:cell(actions)="{ item }">
					<b-dropdown no-caret size="sm" data-test-id="specTemplateActions">
						<template slot="button-content">
							<font-awesome-icon icon="ellipsis-h" />
						</template>
						<b-dropdown-item @click="removeCurrency(item)">
							{{ $t('Delete') }}
						</b-dropdown-item>
					</b-dropdown>
				</template> -->
			</list-table>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { OfsPanel, ListTable } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';

import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import isFulfilmentAdminMixin from '../../mixins/isFulfilmentAdminMixin';
import tableMixin from '../../mixins/tableMixin';
import { displayError } from '../../lib/helpers';

const formName = 'conversionRates';
export default {
	components: {
		DefaultLayout,
		OfsPanel,
		Loader,
		ListTable
	},
	mixins: [isFulfilmentAdminMixin, tableMixin],
	data() {
		return {
			pageTitle: this.$t('Currencies'),
			sort: {
				name: 1
			},
			isLoading: false,
			isBusy: false,
			fields: [
				{ key: 'name', label: this.$t('Currency'), sortable: true },
				{ key: 'isoCode', label: this.$t('Code') },
				{ key: 'symbol', label: this.$t('Symbol') }
				// the following fields were removed during development: might be useful later:
				// { key: 'rate', label: this.$t('USD $ Rate'), tdClass: 'text-right' }
				// { key: 'actions', label: ' ', tdClass: 'text-right' }
			],
			currenciesTooltipText1: this.$t('Select and set currencies you would like to make available to brands.'),
			currenciesTooltipText2: this.$t('Conversion rates will be set on x day and be updated per monthly periods.')
		};
	},
	computed: {
		...mapGetters({
			currencies: 'currency/currencies',
			currencyCount: 'currency/count'
		})
	},
	async mounted() {
		this.isLoading = true;
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			getCurrencies: 'currency/find',
			saveCurrency: 'currency/create',
			deleteCurrency: 'currency/deleteById'
		}),
		async fetchData() {
			try {
				const query = {
					query: {
						$limit: this.perPage,
						$skip: this.perPage * (this.currentPage - 1),
						$sort: this.sort
					}
				};

				this.isBusy = true;
				await this.getCurrencies({
					query
				});
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		itemClicked(item) {
			this.selectedCurrency = item;
			this.$router.push({ name: 'admin.currencies.view', params: { id: item._id } });
		},
		async removeCurrency(currency) {
			// not used at the moment
			const confirm = await this.$bvModal.msgBoxConfirm(
				this.$t('Are you sure you want to delete this Currency and its Rates history?'),
				{
					title: this.$t('Delete Currency'),
					size: 'sm',
					okTitle: this.$t('Yes'),
					cancelTitle: this.$t('No')
				}
			);

			if (confirm) {
				try {
					this.isBusy = true;
					await this.deleteCurrency({
						id: currency._id
					});
					this.$notify({
						type: 'success',
						text: this.$t('The Currency was successfully deleted'),
						title: this.$t('Currency Deleted')
					});
					await this.fetchData();
				} catch (err) {
					this.$notify({ type: 'error', text: displayError(err) });
				} finally {
					this.isBusy = false;
				}
			}
		}
	}
};
</script>

<style lang="scss"></style>
