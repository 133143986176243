<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3" />
			<b-form>
				<b-row>
					<b-col lg="6">
						<of-form-input name="name" :label="$t('Name')" />
					</b-col>
					<b-col lg="6">
						<of-multi-select
							name="currencyIsoCode"
							:label="$t('Currency')"
							:options="currencyOptions"
							:disabled="id"
							:allow-clear="false"
							@input="updateFormData({ countryIsoCode: null, stateIds: [] })"
							data-test-id="ShipmentPriceSheetFormCurrency"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col lg="6">
						<of-multi-select
							name="countryIsoCode"
							:label="$t('Country')"
							data-test-id="ShipmentPriceSheetFormCountry"
							:options="countryOptions"
							:disabled="id || !formData.currencyIsoCode"
							:allow-clear="false"
						/>
					</b-col>
					<b-col v-show="states.length" lg="6">
						<of-multi-select
							name="stateIds"
							:label="$t('States')"
							data-test-id="ShipmentPriceSheetFormState"
							:options="states"
							track-by="_id"
							label-by="displayName"
							:multiple="true"
							@input="changeState"
						/>
					</b-col>
				</b-row>
				<template v-if="shouldShowEntries">
					<hr class="form-divider" />
					<p>{{ weightBandsTitle }}</p>

					<Loader v-if="isLoadingPriceSheet" />
					<b-table-simple responsive>
						<b-thead>
							<b-tr>
								<b-th
									v-for="field in fields"
									:key="field.key"
									:sticky-column="field.key === 'name'"
									:class="{
										ShipmentPriceSheetEntriesTable__format: field.key !== 'weightBand'
									}"
								>
									{{ field.label }}
								</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<template>
								<b-tr v-for="(item, index) in tableItems" :key="`entriy-${index}`">
									<b-td
										v-for="f in fields"
										:key="f.key"
										:class="{
											PriceConfigurationTable__value: f.key !== 'weightBand',
											PriceConfigurationTable__name: f.key === 'weightBand'
										}"
										:sticky-column="f.key === 'weightBand'"
									>
										<template v-if="f.key === 'weightBand'">
											<span>
												{{ !item.weightLte ? '&gt;' : '' }}
												<span>{{ item.weightGt }}</span>
												{{ item.weightLte && ' - ' }}
												<span v-if="item.weightLte">{{ item.weightLte }}</span>
											</span>
										</template>
										<template v-else>
											<of-form-input
												class="EntriesTable__input"
												:name="`entries[${getSheetEntryIndexForItem(item, f.key)}].netAmount`"
												data-test-id="EntriesTableNetAmount"
												type="number"
												no-label
												:placeholder="!item.weightLte ? $t('Not allowed') : ''"
												:disabled="!item.weightLte"
												:normalize="valueToFloat"
											/>
										</template>
									</b-td>
								</b-tr>
							</template>
						</b-tbody>
					</b-table-simple>
				</template>
			</b-form>
			<template slot="actions">
				<of-submit-button @click.prevent="save">{{ $t('Save') }}</of-submit-button>
			</template>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { ContentHeader, OfsPanel, OfFormInput, OfMultiSelect, OfSubmitButton, withForm } from '@workflow-solutions/ofs-vue-layout';
import { required, minLength, maxLength, minValue, decimal } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import { displayError, valueToFloat } from '../../../lib/helpers';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';
import { mounted } from '../../../lib/aceEditor';

const formName = 'editShipping';
export default {
	components: {
		ContentHeader,
		Loader,
		OfsPanel,
		DefaultLayout,
		OfFormInput,
		OfMultiSelect,
		OfSubmitButton
	},
	mixins: [isFulfilmentAdminMixin, withForm(formName)],
	data() {
		return {
			isLoading: false,
			isLoadingPriceSheet: false,
			weightBandsTitle: this.$t('Add weight band cost for each shipping method.')
		};
	},
	computed: {
		...mapGetters({
			countries: 'country/countries',
			currencies: 'currency/currencies',
			shipmentPriceSheet: 'shipmentPriceSheet/shipmentPriceSheet',
			weightBands: 'shipmentWeightBand/shipmentWeightBands',
			serviceLevels: 'shipmentServiceLevel/shipmentServiceLevels'
		}),
		id() {
			return this.$route.params.id;
		},
		pageTitle() {
			return this.id ? this.$t('Edit Shipment Price Sheet') : this.$t('New Shipment Price Sheet');
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Shipping'),
					to: {
						name: 'admin.shipping'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		shouldShowEntries() {
			return this.id;
		},
		currencyOptions() {
			return this.currencies.map(c => ({
				text: `${c.name} (${c.isoCode}-${c.symbol})`,
				value: c._id
			}));
		},
		countryOptions() {
			return _.filter(this.countries, c => {
				return c.defaultCurrencyIsoCode === this.currency?.isoCode;
			}).map(c => ({
				text: c.name,
				value: c._id
			}));
		},
		currency() {
			return _.find(this.currencies, {
				isoCode: this.formData?.currencyIsoCode
			});
		},
		states() {
			const countryCode = _.get(this.formData, 'countryIsoCode');
			return _.get(_.find(this.countries, { _id: countryCode }), 'states', []);;
		},
		validationRules() {
			const maxDecimalCheck = amount => {
				if (amount) {
					return /^\d*(\.\d{0,4})?$/.test(amount);
				}
				return true;
			};
			const rules = {
				formData: {
					name: {
						required,
						minLength: minLength(1),
						maxLength: maxLength(255)
					},
					currencyIsoCode: { required },
					countryIsoCode: { required },
					stateIds: { minLength: minLength(0) },
					entries: {
						$each: {
							netAmount: {
								decimal,
								minValue: minValue(0),
								maxDecimalCheck
							}
						}
					}
				}
			};
			return rules;
		},
		defaultEntry() {
			return {
				shipmentPriceSheetId: this.id,
				shipmentServiceLevelId: null,
				weightUnit: 'g',
				weightGt: null,
				weightLte: null,
				netAmount: null,
				currencyIsoCode: _.get(this.shipmentPriceSheet, 'currencyIsoCode', null)
			};
		},
		fields() {
			// field hardcoded in a logically ascending order for now
			const levelsFields = [
				{ key: 'economy', label: this.$t('Economy') },
				{ key: 'standard', label: this.$t('Standard') },
				{ key: 'express', label: this.$t('Express') }
			];

			return [{ label: this.$t('Weight Bands (g)'), key: 'weightBand' }, ...levelsFields];
		},
		tableItems() {
			// Dynamically build the table rows
			const items = [];

			_.forEach(this.weightBands || [], weightBand => {
				const { weightGt, weightLte } = weightBand;
				const item = {
					weightGt,
					weightLte
				};

				_.forEach(this.serviceLevels || [], sl => {
					const { _id, code } = sl;
					item[code] = { _id, code, netAmount: 0 };
				});

				_.forEach(this.shipmentPriceSheet.entries || [], e => {
					if (!(e.weightGt === weightGt && e.weightLte == weightLte)) return;
					const { _id, code } = _.find(this.serviceLevels, { _id: e.shipmentServiceLevelId });

					item[code].netAmount = e.netAmount;
				});

				items.push(item);
			});

			return items;
		}
	},
	watch: {
		$route: 'fetchData',
		id: 'fetchData',
		'formData.countryIsoCode': 'setDefaultCurrency'
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		...mapActions({
			findCountries: 'country/findAll',
			findCurrencies: 'currency/findAll',
			findWeightBands: 'shipmentWeightBand/findAll',
			findServiceLevels: 'shipmentServiceLevel/findAll',
			findShipmentPriceSheet: 'shipmentPriceSheet/findById',
			updateShipmentPriceSheet: 'shipmentPriceSheet/update',
			createShipmentPriceSheet: 'shipmentPriceSheet/create'
		}),
		valueToFloat,
		async fetchData() {
			this.isLoading = true;
			this.isLoadingPriceSheet = true;
			try {
				const query = {
					query: {
						$sort: { name: 1 }
					}
				};
				const countriesReq = this.findCountries({ query });
				const currenciesReq = this.findCurrencies({ query });
				const weightBandsReq = this.findWeightBands();
				const serviceLevelsReq = this.findServiceLevels();

				await Promise.all([countriesReq, currenciesReq, weightBandsReq, serviceLevelsReq]);

				if (this.id) {
					await this.findShipmentPriceSheet({
						id: this.id,
						query: {
							query: {
								$populate: {
									path: 'entries',
									$limit: 1000
								}
							}
						}
					});

					// Create Price Sheet
					const priceConfiguration = {
						...this.shipmentPriceSheet
					};
					priceConfiguration.entries = this.generateEntries();

					this.initFormData(priceConfiguration);
				} else {
					this.initFormData({
						name: null,
						currencyIsoCode: null,
						countryIsoCode: null,
						stateIds: [],
						entries: []
					});
				}
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t('Unable to load Shipment Price Sheet') });
				this.$router.push({ name: 'admin.shipping' });
			} finally {
				this.isLoading = false;
				this.isLoadingPriceSheet = false;
			}
		},
		getSheetEntryIndexForItem(item, levelCode) {
			// This function returns the index for a price sheet entry
			// in formData.entries - so that we can dynamically update the form

			const index = _.findIndex(this.formData.entries, {
				weightGt: item.weightGt,
				weightLte: item.weightLte,
				shipmentServiceLevelId: item[levelCode]._id
			});

			return index;
		},
		generateEntries() {
			const commonEntryFields = {
				shipmentPriceSheetId: this.id,
				weightUnit: 'g', // maybe this should be dynamic 🤔
				netAmount: null,
				currencyIsoCode: this.shipmentPriceSheet.currencyIsoCode
			};

			const generatedEntries = _.flatMap(this.weightBands, weightBand => [
				..._.flatMap(this.serviceLevels, serviceLevel => [
					{
						...commonEntryFields,
						shipmentServiceLevelId: serviceLevel._id,
						weightGt: weightBand.weightGt,
						weightLte: weightBand.weightLte
					}
				])
			]);

			// update generated entries with existing values
			if (Array.isArray(this.shipmentPriceSheet?.entries)) {
				_.forEach(this.shipmentPriceSheet.entries, entry => {
					const { shipmentServiceLevelId, weightGt, weightLte, netAmount } = entry;
					const foundEntry = _.find(generatedEntries, { shipmentServiceLevelId, weightGt, weightLte });
					if (foundEntry) {
						foundEntry.netAmount = netAmount;
					}
				});
			}

			return generatedEntries;
		},
		async save() {
			try {
				// Clean null values
				const data = _.clone(this.formData);
				_.remove(data.entries, e => {
					return e.netAmount === null || e.netAmount === '';
				});

				if (this.id) {
					await this.updateShipmentPriceSheet({
						id: this.id,
						data
					});
					this.$notify({ type: 'success', text: this.$t('Shipment Price Sheet updated successfully') });
					this.$router.push({ name: 'admin.shipping' });
				} else {
					const response = await this.createShipmentPriceSheet(data);
					this.$notify({ type: 'success', text: this.$t('Shipment Price Sheet created successfully') });
					this.$router.push({ name: 'admin.shipping.view', params: { id: response._id } });
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		},
		setDefaultCurrency(countryIsoCode, oldValue) {
			const updatedProperties = {
				stateIds: []
			};

			if (countryIsoCode) {
				const country = this.countries.find(country => country.isoCode === countryIsoCode);
				updatedProperties.currencyIsoCode = country.defaultCurrencyIsoCode;
				if (!oldValue) {
					// dont clear states on init
					delete updatedProperties.stateIds;
				}
			}

			this.updateFormData(updatedProperties);
		},
		async changeState(val) {
			// Default state to an empty array if cleared
			if (val === null) {
				await this.$nextTick();

				this.updateFormData({
					stateIds: []
				});
			}
		}
	}
};
</script>

<style lang="scss">
.ShipmentPriceSheetEntriesTable {
	&__format {
		text-align: center;
	}
}
</style>
