import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'accountTools',
		basePath: '',
		pluralName: '',
		options: {
			namespaced: true
		},
		transport: 'connect',
		actions: {
			async reassignDevices({ dispatch }, data) {
				return dispatch('request', {
					method: 'POST',
					path: 'device/reassign',
					payload: data
				});
			},
			async syncNotSyncedSubBatches({ dispatch }, data) {
				return dispatch('request', {
					method: 'POST',
					path: 'batch/syncnotsyncedsubbatches',
					payload: data
				});
			}
		}
	});
}
