import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'productCategory',
		pluralName: 'productCategories',
		transport: 'connect',
		basePath: 'product/category',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'count' // -> `count` in the response payload
				}
			}
		}
	});
}
