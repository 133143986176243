import { createRouter } from '@workflow-solutions/siteflow-section-layout';
import store, { i18n } from '../vuex';
import routes from './routes';
import menuStructure from './menuStructure';

const router = createRouter({
	routes: [...routes]
});

/**
 * Translating route meta fields
 */
const waitForLanguageLoaded = async () => {
	const tokens = store.getters['lang/tokens'];
	if (tokens && Object.keys(tokens).length) return null;

	return new Promise(resolve => {
		const unsubscribe = store.subscribe(mutation => {
			if (mutation.type === 'lang/SET_TOKENS') {
				unsubscribe();
				resolve();
			}
		});
	});
};

const FIELDS = ['title', 'status'];
// eslint-disable-next-line
const DEFAULT_TITLE = 'Control Centre';

router.beforeEach(async (to, from, next) => {
	await waitForLanguageLoaded();
	const { matched } = router.matcher.match({ ...to });
	FIELDS.forEach(field => {
		matched.forEach(match => {
			if (match.meta && match.meta[field]) {
				match.meta[field] = i18n.t(match.meta[field]);
			}
		});
	});

	document.title = (to.meta && to.meta.title) || DEFAULT_TITLE;

	next();
}); 

export default router;
export { routes, menuStructure };
