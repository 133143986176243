<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3" />
			<b-form>
				<b-row>
					<b-col lg="10">
						<b-row>
							<b-col lg="4">
								<of-multi-select
									name="hpSpecificationTemplateId"
									:label="$t('Spec Template')"
									:allow-clear="false"
									:disabled="id"
									data-test-id="PriceConfigurationSpecTemplate"
									:options="specTemplateOptions"
								/>
							</b-col>
							<b-col lg="4">
								<of-multi-select
									name="currencyIsoCode"
									:options="currencyOptions"
									:allow-clear="false"
									:disabled="id"
									:label="$t('Currency')"
									data-test-id="PriceConfigurationCurrency"
								/>
							</b-col>
							<b-col lg="4">
								<of-multi-select
									name="countryIsoCodes"
									:label="$t('Countries')"
									:options="countryOptions"
									:multiple="true"
									data-test-id="PriceConfigurationCountries"
								/>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<template v-if="shouldShowPriceSheet">
					<hr class="form-divider" />
					<p>
						{{
							$t(
								'Enter the desired prices of all reproductions and finishes you would like to make available. A maximum of 4 decimals places is allowed for pricing.'
							)
						}}
					</p>

					<Loader v-if="isLoadingPriceSheet" />

					<b-table-simple v-else responsive>
						<b-thead>
							<b-tr>
								<b-th
									v-for="field in fields"
									:key="field.key"
									:class="{
										PriceConfigurationTable__format: field.key !== 'name'
									}"
									:sticky-column="field.key === 'name'"
								>
									{{ field.label }}
								</b-th>
							</b-tr>
						</b-thead>
						<b-tbody>
							<template>
								<b-tr v-for="(item, index) in tableItems" :key="`pricesheet-${index}`">
									<template v-if="item.type === 'heading'">
										<b-th
											sticky-column
											:colspan="fields.length - 1"
											class="PriceConfigurationTable__group"
										>
											{{ item.label }}
										</b-th>
										<b-th sticky-column class="PriceConfigurationTable__group"></b-th>
									</template>
									<template v-else-if="item.type === 'subheading'">
										<b-th
											sticky-column
											:colspan="fields.length - 1"
											class="PriceConfigurationTable__section"
										>
											<div class="flex-column d-flex">
												{{ item.label }}
												<span class="PriceConfigurationTable__info">{{
													item.label === 'cover' ? $t('price per copy') : $t('price per page')
												}}</span>
											</div>
										</b-th>
										<b-th sticky-column class="PriceConfigurationTable__section"></b-th>
									</template>
									<template v-else>
										<b-td
											v-for="f in fields"
											:key="f.key"
											:class="{
												PriceConfigurationTable__value: f.key !== 'name',
												PriceConfigurationTable__name: f.key === 'name'
											}"
											:sticky-column="f.key === 'name'"
										>
											<template v-if="f.key === 'name'">
												<span class="d-flex flex-column">
													<span>{{ item.label }}</span>
													<span
														v-if="item.badge1 || item.badge2"
														class="PriceConfigurationTable__badges"
													>
														<li v-if="item.badge1" class="PriceConfigurationTable__badge">
															{{ item.badge1 }}
														</li>
														<li
															v-if="item.badge2"
															class="PriceConfigurationTable__badge PriceConfigurationTable__badge--highlight"
														>
															{{ item.badge2 }}
														</li>
													</span>
												</span>
											</template>
											<template v-else>
												<of-form-input
													class="SizeTable__input SizeTable__name"
													:name="
														`entries[${getSheetEntryIndexForItem(item, f.key)}].netAmount`
													"
													data-test-id="SizeTableName"
													type="number"
													no-label
													:normalize="valueToFloat"
												/>
											</template>
										</b-td>
									</template>
								</b-tr>
							</template>
						</b-tbody>
					</b-table-simple>
				</template>
			</b-form>
			<template slot="actions">
				<of-submit-button @click.prevent="save">{{ id ? $t('Save') : $t('Generate') }}</of-submit-button>
			</template>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import { ContentHeader, OfsPanel, OfFormInput, OfMultiSelect, OfSubmitButton, withForm } from '@workflow-solutions/ofs-vue-layout';
import { required, minValue } from 'vuelidate/lib/validators';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import { displayError, valueToFloat } from '../../../lib/helpers';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';
import { mapActions, mapGetters } from 'vuex';

const formName = 'editPriceConfiguration';

export default {
	components: {
		ContentHeader,
		Loader,
		OfsPanel,
		DefaultLayout,
		OfFormInput,
		OfMultiSelect,
		OfSubmitButton
	},
	mixins: [isFulfilmentAdminMixin, withForm(formName)],
	data() {
		return {
			isLoading: false,
			isLoadingPriceSheet: false,
			basePriceConfiguration: {
				name: '',
				hpSpecificationTemplateId: null,
				currencyIsoCode: null,
				countryIsoCodes: [],
				entries: []
			}
		};
	},
	computed: {
		...mapGetters({
			priceConfiguration: 'priceConfiguration/priceConfiguration',
			materials: 'material/materials',
			countries: 'country/countries',
			currencies: 'currency/currencies',
			specTemplate: 'hpSpecTemplate/hpSpecTemplate',
			specTemplates: 'hpSpecTemplate/hpSpecTemplates'
		}),
		id() {
			return this.$route.params.id;
		},
		pageTitle() {
			return this.id ? this.formData.name : this.$t('New Price Configuration');
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Price Configurations'),
					to: {
						name: 'admin.priceConfigurations'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		shouldShowPriceSheet() {
			return !!this.formData.hpSpecificationTemplateId && this.id;
		},
		specTemplateName() {
			return _.get(
				_.find(this.specTemplates, {
					_id: this.formData?.hpSpecificationTemplateId
				}),
				'name'
			);
		},
		currencyOptions() {
			return this.currencies.map(c => ({
				text: c.name,
				value: c._id
			}));
		},
		countryOptions() {
			return _.filter(this.countries, c => {
				return c.defaultCurrencyIsoCode === this.currency?.isoCode;
			}).map(c => ({
				text: c.name,
				value: c._id
			}));
		},
		currency() {
			return _.find(this.currencies, {
				isoCode: this.formData?.currencyIsoCode
			});
		},
		currencyName() {
			return `${this.currency?.name} (${this.currency?.isoCode})`;
		},
		countryNames() {
			return _.map(
				_.filter(this.countryOptions, c => {
					return (this.formData?.countryIsoCodes || []).includes(c.value);
				}) || [],
				'text'
			).join(', ');
		},
		specTemplateOptions() {
			return this.specTemplates.map(c => ({
				text: c.name,
				value: c._id
			}));
		},
		fields() {
			// Dynamically build the table fiels
			const fields = [
				{
					key: 'name',
					label: ''
				}
			];

			_.forEach(this.specTemplate.formats || [], f => {
				fields.push({
					key: f._id,
					label: f.name,
					thClass: 'PriceConfigurationTable__format',
					tdClass: 'PriceConfigurationTable__value'
				});
			});

			return fields;
		},
		validationRules() {
			const maxDecimalCheck = netAmount => {
				if (netAmount) {
					return /^\d*(\.\d{0,4})?$/.test(netAmount);
				}

				return true;
			};

			let rules = {
				formData: {
					name: {
						required
					},
					countryIsoCodes: {
						required
					},
					currencyIsoCode: {
						required
					},
					hpSpecificationTemplateId: {
						required
					},
					entries: {
						$each: {
							netAmount: {
								minValue: minValue(0),
								maxDecimalCheck // Maximum of 4 decimal places allowed
							}
						}
					}
				}
			};

			return rules;
		},
		materialGradeHash() {
			// This is used for performant lookups of materials by materialGradeId
			const hash = {};

			_.each(this.materials, m => {
				_.each(m.grades, g => {
					hash[g._id] = {
						label: m.name,
						weight: `${g.weight} ${g.weightUnit}`,
						grade: `${g.grade} ${g.gradeUnit}`
					};
				});
			});

			return hash;
		},
		tableItems() {
			// Dynamically build the table rows
			const items = [
				{
					type: 'format',
					label: this.$t('Base Price (Price per Copy)')
				}
			];

			// Loop Reproduction Qualities
			_.forEach(this.specTemplate.reproductions || [], r => {
				items.push({
					type: 'heading',
					label: r.name
				});
				// Loop Components
				_.forEach(r.components || [], c => {
					items.push({
						type: 'subheading',
						label: c.code
					});

					// Loop Material Options
					_.forEach(c.materialOptions || [], mo => {
						// Check if corresponding material grade still exists
						if (this.materialGradeHash[mo.materialGradeId]) {
							const materialGrade = this.getMaterialGrade(mo.materialGradeId);

							items.push({
								type: 'reproduction-option',
								label: materialGrade.label,
								badge1: materialGrade.weight,
								badge2: materialGrade.grade,
								reproductionId: r._id,
								componentCode: c.code,
								materialId: mo._id,
								materialGradeId: mo.materialGradeId
							});
						}
					});
				});
			});

			// Finishing Options
			// Must build a custom structure for the UI, hence the unnecessary loops
			// Finishing Option > Component > Value
			const finishingOptions = {};

			// Loop Components
			_.forEach(this.specTemplate.components || [], c => {
				// Loop Finishing Options
				_.forEach(c.finishingOptions || [], (fo, index) => {
					if (fo.editable) {
						if (!finishingOptions[fo.name])
							finishingOptions[fo.name] = {
								components: {}
							};

						if (!finishingOptions[fo.name].components[c.code])
							finishingOptions[fo.name].components[c.code] = [];

						_.forEach(fo.enum, fv => {
							finishingOptions[fo.name].components[c.code].push({
								type: 'finishing-option',
								label: _.capitalize(fv),
								componentCode: c.code,
								finishingOptionId: fo._id,
								finishingOptionValue: fv
							});
						});
					}
				});
			});

			// Lets now add the finishing options to the tableItems
			_.forEach(finishingOptions, (fo, finishingOptionName) => {
				let headingAdded = false;

				_.forEach(fo.components, (co, componentName) => {
					if (co.length) {
						if (!headingAdded) {
							// Only add finishing option heading if we have available finishing options
							items.push({
								type: 'heading',
								label: finishingOptionName
							});
							headingAdded = true;
						}

						// Only add component subheading if we dont have any finishing options
						items.push({
							type: 'subheading',
							label: componentName
						});

						// Push finishing option values
						items.push(...co);
					}
				});
			});

			return items;
		}
	},
	watch: {
		$route: 'fetchData',
		id: 'fetchData',
		'formData.hpSpecificationTemplateId': {
			async handler(value) {
				if (this.id) return;
				try {
					// If the spec template is changed, load the new spec and show a loader
					this.isLoadingPriceSheet = true;
					if (value) await this.findSpecTemplate({ id: value });
					this.updateConfigurationName();
				} catch (err) {
					this.$notify({ type: 'error', text: this.$t('Unable to load Specification Template') });
				} finally {
					this.isLoadingPriceSheet = false;
				}
			}
		},
		'formData.currencyIsoCode': {
			async handler(newValue, oldValue) {
				// If the currency is changed, clear the selected countries
				if (oldValue && this.formData && this.formData.countryIsoCodes) {
					this.formData.countryIsoCodes = [];
				}
				this.updateConfigurationName();
			}
		},
		'formData.countryIsoCodes': 'updateConfigurationName'
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			findCountries: 'country/find',
			findMaterials: 'material/find',
			findPriceConfiguration: 'priceConfiguration/findById',
			updatePriceConfiguration: 'priceConfiguration/update',
			createPriceConfiguration: 'priceConfiguration/create',
			findCurrencies: 'currency/find',
			findSpecTemplates: 'hpSpecTemplate/find',
			findSpecTemplate: 'hpSpecTemplate/findById',
			updateFormSummary: 'form/updateFormSummary'
		}),
		valueToFloat,
		async fetchData() {
			this.isLoading = true;

			const maxItemsQuery = {
				query: {
					query: {
						$limit: 1000,
						$sort: { name: 1 }
					}
				}
			};

			try {
				// Load initial data (max 1000 items)
				await this.findSpecTemplates(maxItemsQuery);
				await this.findCountries(maxItemsQuery);
				await this.findMaterials(maxItemsQuery);
				await this.findCurrencies(maxItemsQuery);

				if (this.id) {
					await this.findPriceConfiguration({
						id: this.id,
						query: {
							query: {
								$populate: {
									path: 'entries',
									$limit: 1000
								}
							}
						}
					});

					await this.findSpecTemplate({ id: this.priceConfiguration.hpSpecificationTemplateId });

					// Create Price Sheet
					const priceConfiguration = {
						...this.priceConfiguration
					};

					// Dynamically Generate Price Sheet Entries
					priceConfiguration.entries = this.generatePriceSheet(
						this.priceConfiguration.id,
						this.priceConfiguration.hpSpecificationTemplateId,
						this.priceConfiguration.currencyIsoCode,
						this.priceConfiguration.entries
					);

					this.initFormData(priceConfiguration);
				} else {
					// If a new price configuration, initialise the form
					this.initFormData(this.basePriceConfiguration);
				}
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t('Unable to load Price Configuration') });
				this.$router.push({ name: 'admin.priceConfigurations' });
			} finally {
				this.isLoading = false;
			}
		},
		async save() {
			try {
				// Clean null values
				const data = _.clone(this.formData);
				_.remove(data.entries, e => {
					return e.netAmount === null || e.netAmount === '';
				});

				if (this.id) {
					await this.updatePriceConfiguration({
						id: this.id,
						data
					});
					this.$notify({ type: 'success', text: this.$t('Price Configuration updated successfully') });
					this.$router.push({ name: 'admin.priceConfigurations' });
				} else {
					const response = await this.createPriceConfiguration(data);
					this.$notify({ type: 'success', text: this.$t('Price Configuration created successfully') });
					this.$router.push({ name: 'admin.priceConfigurations.view', params: { id: response._id } });
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		},
		updateConfigurationName() {
			if (this.formData)
				this.formData.name = `${this.specTemplateName} (${this.currency?.isoCode}) ${this.countryNames}`;
		},
		generatePriceSheet(priceSheetId, hpSpecificationTemplateId, currencyIsoCode, entries) {
			const commonEntryFields = {
				priceSheetId,
				hpSpecificationTemplateId,
				netAmount: null,
				currencyIsoCode
			};

			return _.flatMap(this.specTemplate.formats, format => [
				// base price of the format
				{
					...commonEntryFields,
					entryType: 'format',
					formatId: format._id,
					pricePer: 'copy',
					netAmount: _.get(
						_.find(entries, {
							entryType: 'format',
							pricePer: 'copy',
							formatId: format._id
						}),
						'netAmount',
						null
					)
				},

				// reproduction option prices for every format and component
				..._.flatMap(this.specTemplate.reproductions, reproduction =>
					_.flatMap(reproduction.components, reproductionComponent =>
						_.flatMap(reproductionComponent.materialOptions, materialOption => ({
							...commonEntryFields,
							formatId: format._id,
							pricePer: reproductionComponent.code === 'cover' ? 'copy' : 'page',
							entryType: 'reproduction-option',
							reproductionId: reproduction._id,
							materialGradeId: materialOption.materialGradeId,
							componentCode: reproductionComponent.code,
							netAmount: _.get(
								_.find(entries, {
									formatId: format._id,
									pricePer: reproductionComponent.code === 'cover' ? 'copy' : 'page',
									entryType: 'reproduction-option',
									reproductionId: reproduction._id,
									materialGradeId: materialOption.materialGradeId,
									componentCode: reproductionComponent.code
								}),
								'netAmount',
								null
							)
						}))
					)
				),

				// finishing option prices for every format and component
				..._.flatMap(this.specTemplate.components, component =>
					_.flatMap(component.finishingOptions, finishingOption =>
						_.flatMap(finishingOption.enum, finishingOptionValue => ({
							...commonEntryFields,
							formatId: format._id,
							pricePer: component.code === 'cover' ? 'copy' : 'page',
							entryType: 'finishing-option',
							finishingOptionId: finishingOption._id,
							finishingOptionValue,
							componentCode: component.code,
							netAmount: _.get(
								_.find(entries, {
									formatId: format._id,
									pricePer: component.code === 'cover' ? 'copy' : 'page',
									entryType: 'finishing-option',
									finishingOptionId: finishingOption._id,
									finishingOptionValue,
									componentCode: component.code
								}),
								'netAmount',
								null
							)
						}))
					)
				)
			]);
		},
		getMaterialGrade(materialGradeId) {
			return this.materialGradeHash[materialGradeId];
		},
		getSheetEntryIndexForItem(item, formatId) {
			// This function returns the index for a price sheet entry
			// in formData.entries - so that we can dynamically update the form
			const index = _.findIndex(this.formData.entries, e => {
				if (item.type === 'reproduction-option') {
					return (
						e.entryType === 'reproduction-option' &&
						e.materialGradeId === item.materialGradeId &&
						e.formatId === formatId &&
						e.componentCode === item.componentCode &&
						e.reproductionId === item.reproductionId
					);
				} else if (item.type === 'finishing-option') {
					return (
						e.entryType === 'finishing-option' &&
						e.finishingOptionId === item.finishingOptionId &&
						e.finishingOptionValue === item.finishingOptionValue &&
						e.formatId === formatId &&
						e.componentCode === item.componentCode
					);
				} else if (item.type === 'format') {
					return e.entryType === 'format' && e.formatId === formatId;
				}

				return false;
			});

			return index;
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.PriceConfigGenerate {
	margin-bottom: 1rem;
}

.PriceConfigurationTable {
	&__format {
		text-align: center;

		& > div {
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 45px;
			white-space: nowrap;
		}
	}

	&__name {
		min-width: 250px;
	}

	&__value {
		min-width: 100px;
	}

	&__badges {
		display: flex;
		margin-top: 5px;
	}

	&__badge {
		margin-right: 5px;
		display: inline-flex;
		min-width: 50px;
		padding: 0 4px;
		height: 17px;
		line-height: 17px;
		justify-content: center;
		border-radius: 3px;
		text-transform: uppercase;
		font-size: 11px;
		font-weight: 700;
		white-space: nowrap;
		color: $of-color-white;
		background-color: $of-color-grey-2;

		&--highlight {
			color: $of-color-white;
			background-color: $of-color-grey-1;
		}
	}

	&__info {
		font-weight: normal;
		color: $of-color-grey-2;
		font-size: 10px;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
}

th {
	&.PriceConfigurationTable {
		&__group {
			font-size: 18px;
			font-weight: 600;
			text-transform: capitalize;
			background: #fff !important; // todo - find better way
		}

		&__section {
			padding-top: 6px;
			padding-bottom: 6px;
			text-transform: capitalize;
			font-weight: 600;
			background: $of-color-highlights !important; // todo - find better way
		}
	}
}

.flex-grow {
	flex: 1;
}
</style>
