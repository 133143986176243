<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3" />
			<b-form>
				<b-tabs content-class="pt-3">
					<b-tab :title="$t('Details')">
						<b-row>
							<b-col lg="8">
								<b-row>
									<b-col lg="6">
										<of-form-input
											name="name"
											data-test-id="specTemplateName"
											:label="$t('Name')"
										/>
									</b-col>
									<b-col lg="6">
										<of-form-input
											name="description"
											data-test-id="specTemplateDescription"
											:label="$t('Description')"
										/>
									</b-col>
								</b-row>
								<b-row>
									<b-col lg="6">
										<of-form-input
											name="siteflowSKU"
											data-test-id="specTemplateSiteflowSKU"
											:label="$t('Site Flow SKU')"
										/>
									</b-col>
									<b-col lg="6">
										<of-multi-select
											name="tags"
											data-test-id="specTemplateTags"
											:label="$t('Tags')"
											:placeholder="$t('Tags')"
											:multiple="true"
											:taggable="true"
											:tag-placeholder="$t('Add this as new tag')"
										/>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<of-multi-select
											name="tariffIds"
											data-test-id="specTemplateHsCodes"
											:label="$t('HS Codes')"
											:placeholder="$t('Select HS Codes')"
											:options="tariffOptions"
											:multiple="true"
											:allow-clear="false"
											:taggable="false"
										/>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</b-tab>
					<b-tab :title="$t('Components')">
						<b-tabs vertical pills>
							<b-tab
								v-for="(component, index) in formData.components"
								:key="component.code"
								:title="startCase(component.code)"
							>
								<template v-if="hasFinishingOption(index, 'lamination')">
									<b-col lg="8">
										<b-row>
											<b-col md="6">
												<of-multi-select
													data-test-id="componentDefaultLamination"
													:label="$t('Default Lamination')"
													:name="getFinishingOptionValue(index, 'lamination')"
													:options="
														formatOptions(
															formData.components[index].finishingOptions[0].enum || []
														)
													"
													:allow-clear="false"
												/>
											</b-col>
										</b-row>
									</b-col>
									<hr class="form-divider" />
								</template>
								<b-col lg="8">
									<b-row>
										<b-col md="6">
											<of-form-input
												data-test-id="componentPageCount"
												:name="`components[${index}].pages.count`"
												:label="$t('Page Count')"
												:normalize="valueToInt"
												type="number"
											/>
										</b-col>
										<b-col md="6">
											<of-multi-select
												data-test-id="componentPreflightProfile"
												:label="$t('Preflight Profiles')"
												:name="`components[${index}].preflightProfile`"
												:options="preflightProfiles"
												:allow-clear="false"
												track-by="value"
												label-by="name"
											/>
										</b-col>
									</b-row>
									<b-row>
										<b-col md="6">
											<of-multi-select
												data-test-id="componentPageMethod"
												:label="$t('Page Method')"
												:name="`components[${index}].pages.method`"
												:options="pageCountMethods"
												:allow-clear="false"
												track-by="value"
												label-by="text"
											/>
										</b-col>
									</b-row>
									<b-row>
										<b-col md="6">
											<of-form-checkbox
												no-label
												data-test-id="componentIncludeSpine"
												:right-side-label="$t('Include Spine')"
												:name="`components[${index}].includeSpine`"
											/>
											<of-form-checkbox
												v-if="hasFinishingOption(index, 'simplex')"
												class="mt-2"
												no-label
												data-test-id="componentSimplex"
												:right-side-label="$t('Simplex')"
												:name="getFinishingOptionValue(index, 'simplex')"
											/>
										</b-col>
									</b-row>
								</b-col>
							</b-tab>
						</b-tabs>
					</b-tab>
					<b-tab :title="$t('Formats')">
						<b-table :items="formData.formats" :fields="sizeFields" responsive class="SizeTable">
							<template #head()="data">
								<div
									class="SizeTableHeader"
									:class="{ 'SizeTableHeader--centered': data.column !== 'name' }"
								>
									{{ data.label }}
									<strong v-if="data.column === 'overall'" class="SizeTableHeader__dimensions">
										W x H (mm)
									</strong>
									<strong
										v-else-if="data.column !== 'name' && data.column !== 'actions'"
										class="SizeTableHeader__dimensions"
									>
										W x H x B (mm)
									</strong>
								</div>
							</template>
							<template #cell()="data">
								<div
									v-if="data.field.key === 'name'"
									class="SizeTable__inputs"
									:data-test-id="`sizeTableName-${data.index}`"
								>
									<of-form-input
										class="SizeTable__input SizeTable__name"
										:name="`formats[${data.index}].name`"
										no-label
									/>
								</div>
								<div v-else-if="data.field.key === 'actions'">
									<b-dropdown no-caret size="sm" :data-test-id="`sizeTableActions-${data.index}`">
										<template slot="button-content">
											<font-awesome-icon icon="ellipsis-h" />
										</template>
										<b-dropdown-item @click="duplicateSize(data.item)">
											{{ $t('Duplicate') }}
										</b-dropdown-item>
										<b-dropdown-item @click="deleteSize(data.item)">
											{{ $t('Delete') }}
										</b-dropdown-item>
									</b-dropdown>
								</div>
								<div v-else-if="data.field.key === 'overall'" class="SizeTable__inputs">
									<of-form-input
										class="SizeTable__input"
										:data-test-id="`sizeTableWidth-${data.index}`"
										:name="`formats[${data.index}].width`"
										:normalize="valueToInt"
										type="number"
										no-label
									/>
									<of-form-input
										class="SizeTable__input"
										:data-test-id="`sizeTableHeight-${data.index}`"
										:name="`formats[${data.index}].height`"
										:normalize="valueToInt"
										type="number"
										no-label
									/>
								</div>
								<div v-else class="SizeTable__inputs">
									<of-form-input
										class="SizeTable__input"
										:data-test-id="
											`sizeTable${getComponentIndex(data.field.key)}CompWidth-${data.index}`
										"
										:name="
											`formats[${data.index}].components[${getComponentIndex(
												data.field.key
											)}].width`
										"
										:normalize="valueToInt"
										type="number"
										no-label
									/>
									<of-form-input
										class="SizeTable__input"
										:data-test-id="
											`sizeTable${getComponentIndex(data.field.key)}CompHeight-${data.index}`
										"
										:name="
											`formats[${data.index}].components[${getComponentIndex(
												data.field.key
											)}].height`
										"
										:normalize="valueToInt"
										type="number"
										no-label
									/>
									<of-form-input
										class="SizeTable__input"
										:data-test-id="
											`sizeTable${getComponentIndex(data.field.key)}CompBleed-${data.index}`
										"
										:name="
											`formats[${data.index}].components[${getComponentIndex(
												data.field.key
											)}].bleed`
										"
										:normalize="valueToInt"
										type="number"
										no-label
									/>
								</div>
							</template>
						</b-table>
						<ofs-feature-button
							type="add"
							data-test-id="addFormatBtn"
							:label="
								formData.formats && formData.formats.length ? $t('Add another') : $t('Add a format')
							"
							@click="addSize"
						/>
					</b-tab>
					<b-tab :title="$t('Reproductions')">
						<b-tabs vertical pills>
							<b-tab
								v-for="(reproduction, index) in formData.reproductions"
								:key="`${reproduction.name}-${index}`"
								:title="reproduction.name"
							>
								<h2 class="mt-1">{{ startCase(reproduction.name) }}</h2>
								<of-form-input
									data-test-id="reproductionDescription"
									:label="$t('Description')"
									:name="`reproductions[${index}].description`"
								/>
								<hr class="form-divider" />
								<template v-for="(rc, i) in reproduction.components">
									<section :key="i" class="mb-3">
										<!-- <reproduction-item :reproduction="rc" /> -->
										<h3 class="form-header mb-1">{{ startCase(rc.code) }}</h3>
										<of-multi-select
											:data-test-id="`reproductionColors${i}Comp`"
											:options="colorOptions"
											:label="$t('Colors')"
											:name="`reproductions[${index}].components[${i}].colors`"
											multiple
											taggable
											:allow-clear="false"
										/>
										<p>
											{{
												$t(
													`Assign material qualities that the ${rc.code} component will be available in.`
												)
											}}
										</p>
										<div
											v-for="(materialOption, g) in rc.materialOptions"
											:key="`${index}-${rc.code}-${g}`"
											class="MaterialGrade"
										>
											<of-multi-select
												class="MaterialGradeSelect"
												:data-test-id="`reproduction${i}Comp${g}Grade`"
												:options="getReproductionMaterialOptions(index, i, materialOption)"
												:name="
													`reproductions[${index}].components[${i}].materialOptions[${g}].materialGradeId`
												"
												no-label
												:allow-clear="false"
											/>
											<a
												class="MaterialGradeRemove"
												href="#"
												:data-test-id="`reproduction${i}Comp${g}GradeRemove`"
												@click.prevent="removeGrade(index, i, g)"
											>
												<font-awesome-icon
													v-b-tooltip.hover
													icon="minus-circle"
													class="MaterialGradeRemoveIcon"
													:title="$t('Remove Material Grade')"
												/>
											</a>
										</div>
										<ofs-feature-button
											type="add"
											:data-test-id="`reproductionAddMaterial${i}Comp`"
											:label="
												rc.materialOptions && rc.materialOptions.length
													? $t('Add another')
													: $t('Add material')
											"
											@click="addMaterial(index, i)"
										/>
									</section>
								</template>
							</b-tab>
						</b-tabs>
					</b-tab>
				</b-tabs>
			</b-form>
			<template slot="actions">
				<of-submit-button @click.prevent="save">{{ $t('Save') }}</of-submit-button>
			</template>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	ContentHeader,
	OfsPanel,
	OfFormInput,
	OfFormCheckbox,
	OfsFeatureButton,
	OfMultiSelect,
	OfSubmitButton,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { required, minLength, minValue } from 'vuelidate/lib/validators';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import getPreflightProfiles from '../../../data/preflightProfiles';
import { displayError, valueToInt } from '../../../lib/helpers';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';
import { mapActions, mapGetters } from 'vuex';

const formName = 'editSpecTemplate';

export default {
	components: {
		ContentHeader,
		Loader,
		OfsPanel,
		OfsFeatureButton,
		DefaultLayout,
		OfFormInput,
		OfFormCheckbox,
		OfMultiSelect,
		OfSubmitButton
	},
	mixins: [isFulfilmentAdminMixin, withForm(formName)],
	data() {
		return {
			isLoading: false,
			componentReproductions: {},
			colorOptions: [
				{
					text: this.$t('4 Color'),
					value: '4Color'
				},
				{
					text: this.$t('Mono'),
					value: 'mono'
				}
			]
		};
	},
	computed: {
		...mapGetters({
			materials: 'material/materials',
			specTemplate: 'hpSpecTemplate/hpSpecTemplate',
			tariffs: 'tariff/tariffs'
		}),
		id() {
			return this.$route.params.id;
		},
		pageTitle() {
			return this.id ? this.$t('Edit Specification Template') : this.$t('New Specification Template');
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Spec Templates'),
					to: {
						name: 'admin.hp-spec-templates'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		numComponents() {
			return (this.formData?.componentCodes || []).length;
		},
		pageCountMethods() {
			return [
				{ text: this.$t('Exact'), value: 'exact' },
				{ text: this.$t('Multiple'), value: 'multiple' }
			];
		},
		sizeFields() {
			let fields = [
				{ label: this.$t('Name'), key: 'name', stickyColumn: true },
				{ label: this.$t('Finished Size'), key: 'overall' }
			];

			(this.formData?.componentCodes || []).forEach(c => {
				fields.push({ label: _.startCase(c), key: c });
			});

			fields.push({ label: ' ', key: 'actions' });

			return fields;
		},
		tariffOptions() {
			return (this.tariffs || []).map(t => ({
				text: t.name,
				value: t._id
			}));
		},
		validationRules() {
			let rules = {
				formData: {
					name: {
						required
					},
					siteflowSKU: {
						required
					},
					tariffIds: {
						required,
						minLength: minLength(1)
					},
					reproductions: {
						required,
						minLength: minLength(1),
						$each: {
							components: {
								required,
								minLength: minLength(this.numComponents),
								$each: {
									colors: {
										required,
										minLength: minLength(1)
									},
									materialOptions: {
										required,
										minLength: minLength(1),
										isUnique(value) {
											// Disallow duplicate materialGradeIds
											return _.uniq(value.map(v => v.materialGradeId)).length === value.length;
										},
										$each: {
											materialGradeId: {
												required
											}
										}
									}
								}
							}
						}
					},
					components: {
						required,
						minLength: minLength(1),
						$each: {
							preflightProfile: {
								required
							},
							pages: {
								required,
								method: {
									required
								},
								count: {
									required,
									minValue: minValue(1)
								}
							}
						}
					},
					formats: {
						required,
						minLength: minLength(1),
						$each: {
							width: {
								required,
								minValue: minValue(1)
							},
							height: {
								required,
								minValue: minValue(1)
							},
							name: {
								required,
								isUnique(value, format) {
									// Test to ensure format name is unique
									const formatNames =
										_.filter(this.formData.formats, f => f !== format).map(f => f.name) || [];
									return value !== '' && !formatNames.includes(value);
								}
							},
							components: {
								required,
								minLength: minLength(this.numComponents),
								$each: {
									width: {
										required,
										minValue: minValue(1)
									},
									height: {
										required,
										minValue: minValue(1)
									},
									bleed: {
										required,
										minValue: minValue(0)
									},
									code: {
										required
									}
								}
							}
						}
					}
				}
			};

			return rules;
		},
		materialOptions() {
			const materials = [];

			this.materials.forEach(material => {
				material.grades.forEach(grade => {
					if (grade) {
						materials.push({
							text: `${material.name} (${grade.weight} ${grade.weightUnit}) ${grade.grade} ${grade.gradeUnit}`,
							value: grade._id
						});
					}
				});
			});

			return materials;
		},
		preflightProfiles() {
			return getPreflightProfiles();
		}
	},
	watch: {
		$route: 'fetchData',
		id: 'fetchData',
		specTemplate: {
			immediate: true,
			handler(value) {
				// If the material updates, update the form data
				this.setFormData(value);
			}
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			findMaterials: 'material/find',
			findSpecTemplate: 'hpSpecTemplate/findById',
			updateSpecTemplate: 'hpSpecTemplate/update',
			createSpecTemplate: 'hpSpecTemplate/create',
			updateFormSummary: 'form/updateFormSummary',
			findTariffs: 'tariff/findAll'
		}),
		valueToInt,
		async fetchData() {
			this.isLoading = true;
			try {
				await this.findMaterials({
					query: {
						query: {
							$limit: 1000,
							$sort: { name: 1 }
						}
					}
				});

				await this.findTariffs({
					query: {
						query: {
							$limit: 1000,
							$sort: { name: 1 }
						}
					}
				});

				if (this.id) {
					await this.findSpecTemplate({ id: this.id });
				} else {
					// If a new material, initialise the form
					this.initFormData({
						type: 'paper',
						componentCodes: ['cover', 'text'],
						productType: 'book',
						siteflowSKU: 'PUB-BK-PFB',
						tags: ['Publishing', 'Book', 'Perfect Bound'],
						formats: [],
						components: [
							{
								preflightProfile: 'pdfx4',
								code: 'cover',
								required: true,
								includeSpine: false,
								finishingOptions: [
									{
										enum: ['None', 'Matte', 'Gloss'],
										name: 'lamination',
										defaultValue: 'None',
										editable: true
									},
									{
										enum: [],
										name: 'simplex',
										defaultValue: false,
										editable: false
									}
								],
								pages: { method: 'exact', count: 1 }
							},
							{
								preflightProfile: 'pdfx4',
								code: 'text',
								required: true,
								includeSpine: false,
								finishingOptions: [
									{
										enum: [],
										name: 'simplex',
										defaultValue: false,
										editable: false
									}
								],
								pages: { method: 'multiple', count: 1 }
							}
						],
						reproductions: [
							{
								name: this.$t('Premium Color'),
								description: this.$t('Premium: Cover & text w. Indigo'),
								components: [
									{
										colors: ['4 Color'],
										materialOptions: [],
										code: 'cover'
									},
									{
										colors: ['4 Color'],
										materialOptions: [],
										code: 'text'
									}
								]
							},
							{
								name: this.$t('Standard Color'),
								description: this.$t('Cover w. Indigo, Text w. PWI'),
								components: [
									{
										colors: ['4 Color'],
										materialOptions: [],
										code: 'cover'
									},
									{
										colors: ['Mono'],
										materialOptions: [],
										code: 'text'
									}
								]
							},
							{
								name: this.$t('Standard Mono'),
								description: this.$t('Cover w. Indigo, text in mono'),
								components: [
									{
										colors: ['Mono'],
										materialOptions: [],
										code: 'cover'
									},
									{
										colors: ['Mono'],
										materialOptions: [],
										code: 'text'
									}
								]
							}
						],
						tariffIds: []
					});
				}
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t('Unable to load Specification Template') });
				this.$router.push({ name: 'admin.hp-spec-templates' });
			} finally {
				this.isLoading = false;
			}
		},
		async save() {
			try {
				if (this.id) {
					await this.updateSpecTemplate({
						id: this.id,
						data: this.formData
					});
					this.$notify({ type: 'success', text: this.$t('Specification Template updated successfully') });
				} else {
					await this.createSpecTemplate(this.formData);
					this.$notify({ type: 'success', text: this.$t('Specification Template created successfully') });
				}
				// Redirect back to the list view on save
				this.$router.push({ name: 'admin.hp-spec-templates' });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		},
		startCase: _.startCase,
		getComponentIndex(key) {
			return (this.formData?.componentCodes || []).indexOf(key);
		},
		async deleteSize(item) {
			const confirm = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to delete this Format?'), {
				title: this.$t('Delete Format'),
				size: 'sm',
				okTitle: this.$t('Yes'),
				cancelTitle: this.$t('No')
			});

			if (confirm) {
				this.formData.formats = _.without(this.formData.formats, item);
			}
		},
		duplicateSize(item) {
			let newItem = _.clone(item);
			delete newItem._id;
			this.formData.formats.push(newItem);
			this.focusLastSizeRow();
		},
		addSize() {
			const size = {
				name: '',
				width: null,
				height: null,
				components: _.map(this.formData.componentCodes, code => ({
					code,
					width: null,
					height: null,
					bleed: null
				}))
			};
			this.formData.formats.push(size);
			this.focusLastSizeRow();
		},
		addMaterial(reproductionIndex, componentIndex) {
			const material = {
				materialGradeId: null
			};
			this.formData.reproductions[reproductionIndex].components[componentIndex].materialOptions.push(material);
		},
		removeGrade(reproductionIndex, componentIndex, materialGradeIndex) {
			const formData = _.cloneDeep(this.formData);
			formData.reproductions[reproductionIndex].components[componentIndex].materialOptions.splice(
				materialGradeIndex,
				1
			);
			this.setFormData(formData);
		},
		async focusLastSizeRow() {
			await this.$nextTick(() => {
				const inputs = this.$el.querySelectorAll('.SizeTable__name input');
				const nameInput = inputs[inputs.length - 1];
				if (nameInput) {
					nameInput.focus();
				}
			});
		},
		formatOptions(options) {
			return (options || []).map(o => ({
				value: o,
				text: o
			}));
		},
		getFinishingOptionIndex(componentIndex, finishingOption) {
			return _.findIndex(
				this.formData.components[componentIndex].finishingOptions,
				fo => fo.name === finishingOption
			);
		},
		hasFinishingOption(componentIndex, finishingOption) {
			return this.getFinishingOptionIndex(componentIndex, finishingOption) > -1;
		},
		getFinishingOptionValue(componentIndex, finishingOption) {
			const finishingOptionIndex = this.getFinishingOptionIndex(componentIndex, finishingOption);
			return `components[${componentIndex}].finishingOptions[${finishingOptionIndex}].defaultValue`;
		},
		getReproductionMaterialOptions(reproductionIndex, componentIndex, materialOption) {
			// Currently selected materialGradeId
			const { materialGradeId } = materialOption;
			// Previously selected materialGradeIds for reproduction component
			const materialGradeIds = _.map(
				this.formData.reproductions[reproductionIndex].components[componentIndex].materialOptions,
				m => m.materialGradeId
			);

			// Filter available material options so that previously selected options can not be re-selected
			return _.filter(this.materialOptions, m => {
				return m.value === materialGradeId || !materialGradeIds.includes(m.value);
			});
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.SizeTableHeader {
	display: flex;
	flex-direction: column;

	&--centered {
		align-items: center;
	}

	&__dimensions {
		opacity: 0.5;
	}
}

.SizeTable {
	min-height: 200px;

	&__inputs {
		display: flex;
	}

	.SizeTable__input {
		padding: 0 5px;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}

		> input {
			padding: 6px 10px;
			font-size: 14px;
			line-height: 1.2;
			height: auto;
			min-width: 60px;
		}

		&.SizeTable__name {
			> input {
				min-width: 220px;
			}
		}
	}
}

// Fix for sticky column styling
.table.b-table > thead > tr > .table-b-table-default {
	background: #f2f6fe;
	color: #4f6381;
}

.MaterialGrade {
	display: flex;
	align-items: center;
	padding-bottom: 15px;

	&Select {
		flex: 1;
	}

	&Remove {
		display: flex;
		flex: 1;
		height: 100%;
		align-items: center;
		min-width: 30px;
		max-width: 30px;
		width: 30px;
		justify-content: flex-end;

		&Icon {
			color: $of-color-grey-2;
		}

		&:hover {
			.MaterialGradeRemoveIcon {
				color: $of-color-grey-1;
			}
		}
	}
}
</style>
