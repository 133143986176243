import { mapGetters } from 'vuex';

const isFulfilmentAdminMixin = {
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.aclCheck();
		});
	},
	watch: {
		aclGroups() {
			this.aclCheck();
		}
	},
	computed: {
		...mapGetters({
			aclGroups: 'controlCentre/aclGroups'
		})
	},
	methods: {
		aclCheck() {
			if (!this.aclGroups['fulfilment-admin']) {
				this.$router.push('/no-access');
			}
		}
	}
};

export default isFulfilmentAdminMixin;
