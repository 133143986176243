import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'controlCentreLog',
		pluralName: 'logs',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'total'
				}
			}
		},
		transport: 'controlCentreApi'
	});
}
