import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'autoflowAccount',
		basePath: 'accounts',
		pluralName: 'accounts',
		options: {
			namespaced: true
		},
		transport: 'autoflow',
		actions: {
			async updateAccountGlobalCatalog({ dispatch }, { id, data }) {
				return dispatch('request', {
					method: 'PATCH',
					path: `accounts/${id}/global-catalog`,
					payload: data
				});
			}
		}
	});
}