<template>
	<DefaultLayout>
		<ofs-panel>
			<section v-if="isLoading">
				<Loader />
			</section>
			<list-table
				v-else
				:table-title="$t('Price Configurations')"
				:items="priceConfigurations"
				:fields="fields"
				:total-items="priceConfigurationCount"
				:per-page="perPage"
				:current-page="currentPage"
				:is-busy="isBusy"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="itemClicked"
				@table-change="handleTableChange"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<b-button variant="primary" :to="{ name: 'admin.priceConfigurations.create' }">
						{{ $t('New Price Configuration') }}
					</b-button>
				</template>
				<template v-slot:cell(currencyIsoCode)="data">
					{{ `${getCurrencyForIsoCode(data.item.currencyIsoCode)} (${data.item.currencyIsoCode})` }}
				</template>
				<template v-slot:cell(countries)="data">
					{{ getCountriesForIsoCodes(data.item.countryIsoCodes) || '-' }}
				</template>
				<template v-slot:cell(actions)="data">
					<b-dropdown no-caret size="sm" data-test-id="priceConfigurationActions">
						<template slot="button-content">
							<font-awesome-icon icon="ellipsis-h" />
						</template>
						<b-dropdown-item @click="deleteItem(data.item)">
							{{ $t('Delete') }}
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</list-table>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import { OfsPanel, ListTable } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import { displayError } from '../../lib/helpers';
import isFulfilmentAdminMixin from '../../mixins/isFulfilmentAdminMixin';
import tableMixin from '../../mixins/tableMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		Loader,
		ListTable
	},
	mixins: [isFulfilmentAdminMixin, tableMixin],
	data() {
		return {
			sort: {
				name: 1
			},
			isLoading: false,
			isBusy: false,
			fields: [
				{ key: 'name', label: this.$t('Name') },
				{ key: 'currencyIsoCode', label: this.$t('Currency') },
				{ key: 'countries', label: this.$t('Countries') },
				{ key: 'actions', label: ' ', tdClass: 'text-right' }
			]
		};
	},
	computed: {
		...mapGetters({
			countries: 'country/countries',
			currencies: 'currency/currencies',
			priceConfigurations: 'priceConfiguration/priceConfigurations',
			priceConfigurationCount: 'priceConfiguration/count'
		})
	},
	async mounted() {
		this.isLoading = true;

		const maxItemsQuery = {
			query: {
				query: {
					$limit: 1000
				}
			}
		};

		await this.findCountries(maxItemsQuery);
		await this.findCurrencies(maxItemsQuery);
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			findCountries: 'country/find',
			findCurrencies: 'currency/find',
			findPriceConfigurations: 'priceConfiguration/find',
			deletePriceConfiguration: 'priceConfiguration/deleteById'
		}),
		async fetchData() {
			try {
				const query = {
					query: {
						$limit: this.perPage,
						$skip: this.perPage * (this.currentPage - 1),
						$sort: this.sort
					}
				};

				this.isBusy = true;
				await this.findPriceConfigurations({
					query
				});
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		getCountriesForIsoCodes(isoCodes) {
			return _.join(
				_.map(
					_.filter(this.countries, c => isoCodes.includes(c.isoCode)),
					'name'
				),
				', '
			);
		},
		getCurrencyForIsoCode(isoCode) {
			return _.get(
				_.find(this.currencies, {
					isoCode
				}),
				'name'
			);
		},
		itemClicked(item) {
			this.$router.push({ name: 'admin.priceConfigurations.view', params: { id: item._id } });
		},
		async deleteItem(item) {
			const confirm = await this.$bvModal.msgBoxConfirm(
				this.$t('Are you sure you want to delete this Price Configuration?'),
				{
					title: this.$t('Delete Price Configuration'),
					size: 'sm',
					okTitle: this.$t('Yes'),
					cancelTitle: this.$t('No')
				}
			);

			if (confirm) {
				try {
					this.isBusy = true;
					await this.deletePriceConfiguration({
						id: item._id
					});
					this.$notify({
						type: 'success',
						text: this.$t('Price Configuration successfully deleted'),
						title: this.$t('Price Configuration Deleted')
					});
					await this.fetchData();
				} catch (err) {
					this.$notify({ type: 'error', text: displayError(err) });
				} finally {
					this.isBusy = false;
				}
			}
		}
	}
};
</script>

<style lang="scss"></style>
