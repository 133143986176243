<template>
	<list-table
		:items="fulfilmentItems"
		:fields="fulfilmentItemsTableFields"
		:total-items="fulfilmentItemsCount"
		:per-page="perPage"
		:current-page="currentPage"
		:is-busy="isBusy"
		:page-position-prefix="$t('Showing')"
		:page-position-join="$t('of')"
		hover
		@row-clicked="openItem"
		@table-change="handleTableChange"
	>
		<template slot="prev-text" slot-scope="{}">
			{{ $t('Prev') }}
		</template>
		<template slot="next-text" slot-scope="{}">
			{{ $t('Next') }}
		</template>
		<template slot="empty">
			<span>
				<i>{{ $t('No Data') }}</i>
			</span>
		</template>
		<template #cell(status)="{ item }">
			<ofs-badge :status="getStatus(item)" :text="getFulfilmentBadgeStatusText(getStatus(item))" />
		</template>
	</list-table>
</template>
<script>
import _ from 'lodash';
import { ListTable, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import { displayError, getFulfilmentBadgeStatusText } from '../../../lib/helpers';
import tableMixin from '../../../mixins/tableMixin';

export default {
	components: {
		OfsBadge,
		ListTable
	},
	mixins: [tableMixin],
	props: {
		accountId: {
			type: String,
			default: ''
		}
	},
	data() {
		const emptyFormatter = value => value || '-';

		const fulfilmentItemsTableFields = [
			{ key: 'status', label: this.$t('Status') },
			{ key: 'itemId.sourceItemId', label: this.$t('Order Source Item ID') },
			{ key: 'siteFlowSourceItemId', label: this.$t('Site Flow Source Item ID'), formatter: emptyFormatter },
			{ key: 'itemId.sourceSpecId', label: this.$t('Source Spec ID'), formatter: emptyFormatter },
			{ key: 'itemId.sourceProductId', label: this.$t('Source Product ID'), formatter: emptyFormatter }
		];

		return {
			isBusy: true,
			fulfilmentItemsTableFields
		};
	},
	computed: {
		...mapGetters({
			fulfilmentItems: 'fulfilment/items',
			fulfilmentItemsCount: 'fulfilment/itemsCount',
			lang: 'lang/lang'
		}),
		id() {
			return this.$route.params.id;
		}
	},
	watch: {
		id: 'fetchData'
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			findFulfilmentItems: 'fulfilment/findItems'
		}),
		async fetchData() {
			this.isBusy = true;
			try {
				if (this.id) {
					const query = {
						query: {
							$limit: this.perPage,
							$skip: this.perPage * (this.currentPage - 1),
							$sort: this.sort,
							$populate: {
								path: 'itemId'
							}
						}
					};
					await this.findFulfilmentItems({ id: this.id, query, accountId: this.accountId });
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		openItem(item) {
			const { fulfilmentId, id, itemId: { sourceProductId } } = item;
			if (sourceProductId) return;
			this.$router.push({
				name: 'admin.fulfilments.item.view',
				params: {
					id: fulfilmentId,
					itemId: id
				}
			});
		},
		getStatus(item) {
			return `${_.get(item, 'itemId.status', 'null')}`;
		},
		getFulfilmentBadgeStatusText
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
</style>
