<template>
	<DefaultLayout>
		<ofs-panel>
			<section v-if="isLoading">
				<Loader />
			</section>
			<list-table
				v-else
				:table-title="$t('Fulfilments')"
				:items="fulfilments"
				:fields="fields"
				:total-items="fulfilmentCount"
				:per-page="perPage"
				:current-page="currentPage"
				:is-busy="isBusy"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="itemClicked"
				@table-change="handleTableChange"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template #cell(accountId)="{ item: { accountId } }">
					<span>{{ getAccountName(accountId) }}</span>
				</template>
				<template #cell(status)="{ item: { status } }">
					<ofs-badge
						:status="String(status).toLowerCase()"
						:text="getFulfilmentBadgeStatusText(status)"
						:class="`FulfilmentStatus-${String(status).toLowerCase()}`"
					/>
				</template>
				<template #cell(createdAt)="{ item: { createdAt } }">
					{{ formatDate(createdAt) }}
				</template>
				<template #cell(siteFlowOrderDestination.name)="{ item: { siteFlowOrderDestination } }">
					{{ (siteFlowOrderDestination && siteFlowOrderDestination.name) || '-' }}
				</template>
				<template slot="TableHeader" slot-scope="{}">
					<OfFilterBar
						:filters="filters"
						:reset-label="$t('Reset Filters')"
						:values="queryFilters"
						@change="filtersChanged"
					/>
				</template>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<div class="Fulfilments-search">
						<b-form-input
							v-model="search"
							v-focus
							:placeholder="$t('Search Fulfilments')"
							class="Fulfilments-search-input"
							@input="doSearch()"
						/>
						<font-awesome-icon class="Fulfilments-search-icon" icon="search" />
					</div>
					<OfInlineFilter
						:filters="filters"
						:label="$t('Filter')"
						:reset-label="$t('Reset Filters')"
						:values="queryFilters"
						class="ml-1"
						@change="filtersChanged"
					/>
				</template>
			</list-table>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import { OfsPanel, ListTable, OfsBadge, OfFilterBar, OfInlineFilter } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import { displayError, getFulfilmentBadgeStatusText } from '../../lib/helpers';
import isFulfilmentAdminMixin from '../../mixins/isFulfilmentAdminMixin';
import tableMixin from '../../mixins/tableMixin';
import { dateFormatMixin } from '../../mixins/dateFormatMixin';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		OfsBadge,
		Loader,
		OfInlineFilter,
		OfFilterBar,
		ListTable
	},
	mixins: [isFulfilmentAdminMixin, tableMixin, dateFormatMixin()],
	data() {
		return {
			search: null,
			sort: { createdAt: -1 },
			isLoading: false,
			isBusy: false,
			fields: [
				{ key: 'status', label: this.$t('Status'), sortable: true },
				{ key: 'accountId', label: this.$t('Brand Account') },
				{ key: 'sourceOrderId', label: this.$t('Source Order ID') },
				{ key: 'siteFlowOrderDestination.name', label: this.$t('Site Flow Account') },
				{ key: 'siteFlowSourceOrderId', label: this.$t('Site Flow Source Order ID') },
				{ key: 'createdAt', label: this.$t('Created'), sortable: true }
			]
		};
	},
	computed: {
		...mapGetters({
			fulfilments: 'fulfilment/fulfilments',
			fulfilmentCount: 'fulfilment/count',
			availableAccounts: 'auth/availableAccounts',
			vars: 'account/vars'
		}),
		filters() {
			return [
				{
					header: this.$t('Status'),
					key: 'status',
					type: 'checkbox',
					items: [
						{ title: this.$t('null'), value: null },
						{ title: this.$t('submitted'), value: 'submitted' },
						{ title: this.$t('submissionError'), value: 'submissionError' },
						{ title: this.$t('resubmitted'), value: 'resubmitted' },
						{ title: this.$t('dataready'), value: 'dataready' },
						{ title: this.$t('printready'), value: 'printready' },
						{ title: this.$t('error'), value: 'error' },
						{ title: this.$t('shipped'), value: 'shipped' },
						{ title: this.$t('rejected'), value: 'rejected' },
						{ title: this.$t('cancelled'), value: 'cancelled' }
					],
					defaultValue: ['cancelled', 'error', 'submissionError']
				},
				{
					header: this.$t('Brand Account'),
					key: 'accountId',
					type: 'checkbox',
					items: _.map(this.availableBrandAccounts, ({ _id, name }) => ({ title: name, value: _id }))
				},
				{
					header: this.$t('Site Flow Account'),
					key: 'siteFlowOrderDestination.id',
					type: 'checkbox',
					items: _.map(this.availableSiteflowAccounts, ({ _id, name }) => ({ title: name, value: _id }))
				}
			];
		},
		availableBrandAccounts() {
			return _.filter(this.availableAccounts, a => a && (a.type === 'brand' || _.includes(a.types, 'brand')));
		},
		availableSiteflowAccounts() {
			return _.filter(this.availableAccounts, a => a && (a.type === 'psp' || _.includes(a.types, 'psp')));
		},
		// override mixin computed value with special handling of null status
		queryFilters() {
			let status = null;
			const statusArray = _.get(this.$route.query.filters, 'status');
			if (_.isArray(statusArray) || statusArray === '') {
				status = statusArray.length === 0 ? [null] : _.map(statusArray, v => (!v ? null : v));
			}
			return {
				...this.$route.query.filters,
				...(status && { status })
			};
		}
	},
	async mounted() {
		this.isLoading = true;
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			findFulfilments: 'fulfilment/find'
		}),
		async fetchData() {
			try {
				const query = {
					query: {
						$limit: this.perPage,
						$skip: this.perPage * (this.currentPage - 1),
						$sort: this.sort
					}
				};

				this.setQueryWhereFromQueryFilters(query);

				if (this.search) {
					_.set(query, 'query.$where.search', this.search);
				}

				this.isBusy = true;
				this.updateUrl({ filter: this.filter, queryFilters: this.queryFilters });
				await this.findFulfilments({ query });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		itemClicked(item) {
			this.$router.push({ name: 'admin.fulfilments.view', params: { id: item._id } });
		},
		filtersChanged(queryFilters) {
			this.updateUrl({
				currentPage: this.firstPage,
				perPage: this.minPerPage,
				queryFilters,
				filter: this.filter
			});
		},
		doSearch: _.debounce(function doSearch() {
			this.updateUrl({
				currentPage: this.firstPage
			});
			this.fetchData();
		}, 1000),
		getFulfilmentBadgeStatusText,
		getAccountName(id) {
			return this.vars.userAccountLookup[id] || id;
		}
	}
};
</script>

<style lang="scss">
.Fulfilments {
	&-search {
		display: flex;
		align-items: center;
		position: relative;
		margin-right: 10px;

		&-input {
			min-width: 200px;
			padding-right: 35px;
		}

		&-icon {
			font-size: 17px;
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
			pointer-events: none;
		}
	}
}
</style>
