<template>
	<b-modal
		v-model="isVisible"
		:title="modalTitle"
		:ok-title="$t('Save')"
		:ok-disabled="!isValidForm"
		ok-variant="primary"
		@ok="submit"
	>
		<template>
			<of-form-input name="name" :label="$t('Name')" />
			<of-form-input name="netAmount" :normalize="valueToFloat" type="number" min="0" :label="$t('Amount')" />
			<of-form-input
				v-if="componentRequired"
				name="componentCode"
				:label="$t('Component Code')"
				:description="$t('Apply fee if it matches this component')"
			/>
			<of-multi-select
				name="currencyIsoCode"
				:allow-clear="false"
				track-by="value"
				:label="$t('Currency')"
				:options="currencyOptions"
				:searchable="true"
				:placeholder="$t('Currency')"
				@input="currencyChanged"
			/>
			<of-multi-select
				name="countryIsoCodes"
				multiple
				track-by="value"
				:label="$t('Countries')"
				:options="countryOptions"
				:searchable="true"
				:placeholder="$t('Countries')"
			/>
		</template>
	</b-modal>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { OfFormInput, OfMultiSelect, withForm, validateWithMessage } from '@workflow-solutions/ofs-vue-layout';
import { required, requiredIf, minLength, maxLength, minValue } from 'vuelidate/lib/validators';
import { displayError, valueToFloat, maxDecimals } from '../../../lib/helpers';

const formName = 'feePriceForm';

export default {
	components: {
		OfFormInput,
		OfMultiSelect
	},
	mixins: [withForm(formName)],
	props: {
		fee: {
			type: Object,
			required: true
		},
		feePrice: {
			type: [Object, null],
			default: null
		},
		show: Boolean,
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	computed: {
		...mapGetters({
			currencies: 'currency/currencies',
			countries: 'country/countries'
		}),
		id() {
			return this.feePrice?.id;
		},
		componentRequired() {
			return this.fee?.pricePer === 'page';
		},
		modalTitle() {
			return this.feePrice ? this.$t('Edit Bloc') : this.$t('Add Bloc');
		},
		currencyOptions() {
			return this.currencies.map(c => ({
				text: c.name,
				value: c._id
			}));
		},
		countryOptions() {
			return _.filter(this.countries, c => c.defaultCurrencyIsoCode === this.formData.currencyIsoCode).map(c => ({
				text: c.name,
				value: c._id
			}));
		},
		isVisible: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		isValidForm() {
			return !this.$v.$invalid;
		},
		validationRules() {
			const maxFourDecimals = maxDecimals(4);
			const minVal0 = minValue(0);
			const optionalWithMinValue = value => (_.isNil(value) ? true : minVal0(value) && maxFourDecimals(value));
			let rules = {
				formData: {
					name: {
						required,
						minLength: minLength(1),
						maxLength: maxLength(255)
					},
					netAmount: {
						optionalWithMinValue
					},
					componentCode: {
						requiredIf: requiredIf(function() {
							return this.componentRequired;
						})
					},
					currencyIsoCode: {
						required
					},
					countryIsoCodes: {
						required,
						minLength: minLength(1)
					}
				}
			};

			return rules;
		}
	},
	watch: {
		feePrice: {
			immediate: true,
			handler(feePrice) {
				if (feePrice) {
					this.initFormData(feePrice);
				} else {
					this.resetForm();
				}
			}
		},
		isVisible: function(val) {
			if (!val) {
				this.resetForm();
			}
		}
	},
	methods: {
		...mapActions({
			addFeePrice: 'feePrice/create',
			updateFeePrice: 'feePrice/update'
		}),
		async submit(e) {
			// Disable modal close
			e.preventDefault();

			try {
				if (this.id) {
					await this.updateFeePrice({
						id: this.id,
						data: this.formData
					});
				} else {
					await this.addFeePrice({
						feeId: this.fee._id,
						currencyIsoCode: this.fee.currencyIsoCode,
						countryIsoCodes: this.fee.countryIsoCodes,
						...this.formData
					});
					this.resetForm();
				}
				this.onClose(true);
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				this.onClose();
			}
		},
		currencyChanged(val) {
			if (val) {
				this.updateFormData({
					countryIsoCodes: []
				});
			}
		},
		resetForm() {
			this.resetFormData();
			this.initFormData({
				name: null,
				netAmount: null,
				currencyIsoCode: null,
				countryIsoCodes: []
			});
		},
		valueToFloat
	}
};
</script>
