<template>
	<DefaultLayout>
		<b-form novalidate @submit.prevent="submit">
			<OfsPanel>
				<ContentHeader :no-padding="true" :title="$t('Access Controller')"></ContentHeader>
				<h3 v-t="'Search'" class="mt-3"></h3>
				<b-row>
					<b-col>
						<OfMultiSelect
							name="affectedAccountId"
							data-test-id="destinationAccountId"
							:label="$t('Destination Account')"
							:options="affectedAccountOptions"
							:placeholder="$t('Select destination account')"
							label-by="name"
							track-by="_id"
							:filter-inactive="false"
						/>
					</b-col>
				</b-row>
				<hr class="form-divider my-3" />
				<b-row>
					<b-col>
						<OfMultiSelect
							name="destinationAccountId"
							data-test-id="sourceAccountId"
							:label="$t('Source Account')"
							:options="destinationAccountOptions"
							:placeholder="$t('Select source account')"
							:filter-inactive="false"
							label-by="name"
							track-by="_id"
						/>
					</b-col>
					<b-col>
						<OfMultiSelect
							name="role"
							data-test-id="role"
							track-by="name"
							label-by="name"
							:options="roles"
							:label="$t('Role')"
							:placeholder="$t('Select role')"
							:filter-inactive="false"
						/>
					</b-col>
				</b-row>
				<b-row class="mt-2">
					<b-table v-if="filteredUsers.length" :items="filteredUsers" :fields="fields">
						<template #head(actions)>
							<b-checkbox @input="selectAllUsers"></b-checkbox>
						</template>
						<template #cell(actions)="{ item }">
							<b-checkbox v-model="item.selected"></b-checkbox>
						</template>
						<template #cell(roles)="{ item }">
							<div v-for="r in filterUserAccountRoles(item.accountRoles)" :key="r && r._id">
								{{ r && r.name }} ({{ r && r.role }})
							</div>
						</template>
					</b-table>
				</b-row>
				<template slot="actions">
					<of-submit-button v-t="'Submit'" variant="primary"></of-submit-button>
				</template>
			</OfsPanel>
		</b-form>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import DefaultLayout from '../../components/DefaultLayout';
import { required, minLength } from 'vuelidate/lib/validators';
import { OfsPanel, ContentHeader, withForm, OfSubmitButton, OfMultiSelect } from '@workflow-solutions/ofs-vue-layout';
import { $t } from '../../vuex';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		ContentHeader,
		OfMultiSelect,
		OfSubmitButton
	},
	mixins: [withForm('AccessController')],
	data() {
		const fields = [
			{ key: 'actions', label: '' },
			{ key: 'name', label: this.$t('Name') },
			{ key: 'username', label: this.$t('Username') },
			{ key: 'email', label: this.$t('Email') },
			{ key: 'roles', label: this.$t('Roles') }
		];
		return {
			affectedUsers: [],
			destinationUsers: [],
			fields
		};
	},
	computed: {
		...mapGetters({
			vars: 'account/vars',
			roles: 'role/roles',
			accounts: 'account/accounts'
		}),
		validationRules() {
			return {
				formData: {
					affectedAccountId: {
						required
					},
					destinationAccountId: {
						required
					},
					role: {
						required
					},
					userIds: {
						required,
						minLength: minLength(1)
					}
				}
			};
		},
		switchableAccountList() {
			return sortBy(
				filter(
					this.accounts,
					account =>
						account?.name.length &&
						account?.name !== 'admin' &&
						account?.role !== 'simple' && // is a valid role
						(account?.type === 'psp' || this.vars?.admin) // is a psp account, or admin user
				),
				'name'
			);
		},
		affectedAccountOptions() {
			return filter(this.switchableAccountList, i => i._id !== this.formData.destinationAccountId);
		},
		destinationAccountOptions() {
			return filter(this.switchableAccountList, i => i._id !== this.formData.affectedAccountId);
		},
		filteredUsers() {
			return this.affectedUsers.filter(item => {
				return !this.destinationUsers.map(acc => acc._id).includes(item._id);
			});
		},
		selectedUsers() {
			return filter(this.filteredUsers, user => user.selected);
		}
	},
	watch: {
		'formData.destinationAccountId'(val) {
			if (!val) this.destinationUsers = [];
			else this.fetchUsers(val, 'destinationUsers');
		},
		'formData.affectedAccountId'(val) {
			if (!val) this.affectedUsers = [];
			else this.fetchUsers(val, 'affectedUsers');
		},
		selectedUsers(val) {
			if (Array.isArray(val)) this.updateFormData({ userIds: val.map(e => e._id) });
		}
	},
	mounted() {
		this.getRoles();
		this.initFormData({});
	},
	methods: {
		...mapActions({
			getRoles: 'role/findAll',
			getUsersForAccount: 'user/getUsersForAccount',
			changeUserPermissions: 'user/changeUserPermissions'
		}),
		async fetchUsers(id, userType) {
			this[userType] = await this.getUsersForAccount({ id });
		},
		filterUserAccountRoles(roles) {
			const affectedAccount = find(this.accounts, acc => acc._id === get(this, 'formData.affectedAccountId'));
			return filter(roles, role => role?.name === affectedAccount?.name);
		},
		selectAllUsers(bool) {
			this.filteredUsers.forEach(u => (u.selected = bool));
		},
		async submit() {
			try {
				await this.changeUserPermissions(this.formData);
				this.$notify({ type: 'success', text: $t('Permissions updated') });
			} catch (err) {
				const message = $t(this.$errorUtils.displayError(err));
				this.$notify({ type: 'error ', text: message });
			}
			this.getRoles();
			this.setFormData({});
		}
	}
};
</script>
