import AccountList from './List';
import AccountEdit from './Edit';
import LinkedAccountList from './LinkedAccountList';

const routes = [
	{
		path: '/accounts',
		name: 'accounts',
		component: { template: '<router-view />' },
		children: [
			{
				path: 'list',
				name: 'accounts.list',
				component: AccountList
			},
			{
				path: 'list/:id',
				name: 'accounts.edit',
				component: AccountEdit
			}
		]
	},
	{
		path: '/linked-accounts',
		name: 'linkedaccounts',
		component: { template: '<router-view />' },
		children: [
			{
				path: 'all',
				name: 'linked-accounts.list',
				component: LinkedAccountList
			}
		]
	}
];

export default routes;
