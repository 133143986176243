import _ from 'lodash';

export default {
	loadSiteFlowSectionFeatures: async ({ commit, dispatch }) => {
		const { data } = await dispatch('request', {
			method: 'GET',
			path: 'siteflow/migration'
		});
		commit('SET_SITEFLOW_SECTIONS', data);
	}
};
