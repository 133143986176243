import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'accountlink',
		pluralName: 'accountlinks',
		transport: 'connect',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'count' // -> `count` in the response payload
				}
			}
		},
		state: {
			allLinks: [],
			allLinksCount: 0
		},
		getters: {
			allLinksCount: state => state.allLinksCount,
			allLinks: state => state.allLinks
		},
		mutations: {
			SET_ALL_ACCOUNT_LINKS(state, { links, count }) {
				state.allLinksCount = count;
				state.allLinks = links;
			}
		},
		actions: {
			async fetchAllLinks({ dispatch, commit }, { query }) {
				const path = 'accountlink/get-all-links';
				const response = await dispatch('request', { method: 'GET', path, params: query }).then(r => r.data);
				commit('SET_ALL_ACCOUNT_LINKS', { links: response.data, count: response.count });
				return response;
			}
		}
	});
}
