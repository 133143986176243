<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3" />
			<b-form>
				<b-row>
					<b-col>
						<of-multi-select
							name="_id"
							:label="$t('Currency')"
							:options="currenciesJson"
							:placeholder="$t('Select currency...')"
							data-test-id="currencyName"
							track-by="code"
							label-by="name"
							:disabled="id"
							:allow-clear="false"
						></of-multi-select>
					</b-col>
					<b-col>
						<of-form-input
							class="SizeTable__input"
							name="_id"
							data-test-id="currencyCode"
							:label="$t('Code')"
							readonly
						/>
					</b-col>
					<b-col>
						<of-form-input
							class="SizeTable__input"
							name="symbol"
							data-test-id="currencySymbol"
							:label="$t('Symbol')"
							readonly
						/>
					</b-col>
				</b-row>
			</b-form>
			<template v-if="!id" slot="actions">
				<of-submit-button @click.prevent="save">{{ $t('Save') }}</of-submit-button>
			</template>
			<Rates :currency="id" />
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { OfsPanel, ContentHeader, OfMultiSelect, OfFormInput, OfSubmitButton, withForm } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import _get from 'lodash/get';
import { required } from 'vuelidate/lib/validators';

import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';
import { displayError } from '../../../lib/helpers';
import Rates from './Rates';

const formName = 'editCurrency';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		Loader,
		ContentHeader,
		OfMultiSelect,
		OfFormInput,
		OfSubmitButton,
		Rates
	},
	mixins: [isFulfilmentAdminMixin, withForm(formName)],
	data() {
		return {
			isLoading: false
		};
	},
	computed: {
		...mapGetters({
			currenciesJson: 'currency/currenciesJson',
			currency: 'currency/currency'
		}),
		id() {
			return this.$route.params.id;
		},
		selectedCurrency() {
			return _get(this.formData, '_id');
		},
		pageTitle() {
			return this.id ? `${this.formData.name} (${this.formData._id})` : this.$t('New Currency');
		},
		breadcrumbs() {
			const lastItem = this.id
				? { text: this.id, to: { name: 'admin.currencies.view', params: { id: this.id } } }
				: { text: this.pageTitle, to: { name: 'admin.currencies.create' } };
			return [
				{
					text: this.$t('Currencies'),
					to: {
						name: 'admin.currencies'
					}
				},
				lastItem
			];
		},
		validationRules() {
			return {
				formData: {
					name: {
						required
					},
					_id: {
						required
					},
					symbol: {
						required
					}
				}
			};
		}
	},
	watch: {
		selectedCurrency: function(code, oldCode) {
			if (!code || !code.length) {
				return this.setFormData({
					name: null,
					_id: null,
					symbol: null
				});
			}

			const selectedCurrencyData = this.currenciesJson.find(c => c.code === code);
			const { code: currencyIsoCode, name, symbol } = selectedCurrencyData;
			this.setFormData({
				_id: currencyIsoCode,
				name,
				currencyIsoCode,
				symbol
			});
		}
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		...mapActions({
			getCurrenciesJson: 'currency/getCurrenciesJson',
			findCurrency: 'currency/findById',
			createCurrency: 'currency/create'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				if (!this.currenciesJson?.length) {
					await this.getCurrenciesJson();
				}
				if (this.id) {
					await this.findCurrency({ id: this.id });

					this.setFormData(this.currency);
				} else {
					// If a new currency, initialise the form
					this.initFormData({});
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				this.$router.push({ name: 'admin.currencies' });
			} finally {
				this.isLoading = false;
			}
		},
		async save() {
			try {
				await this.createCurrency(this.formData);
				this.$notify({ type: 'success', text: this.$t('Currency created successfully') });
				this.$router.push({ name: 'admin.currencies.view', params: { id: this.formData._id } });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		}
	}
};
</script>
