<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3" />
			<b-form>
				<b-row>
					<b-col lg="10">
						<b-row>
							<b-col lg="6">
								<of-form-input name="name" data-test-id="hsCodeName" :label="$t('Name')" />
							</b-col>
							<b-col lg="6">
								<of-form-input name="hsCode" data-test-id="hsCode" :label="$t('HS Code')" />
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<hr class="form-divider" />
				<h3 class="form-header">{{ taxCodesTitle }}</h3>
				<b-table :items="formData.taxes" :fields="taxFields" class="TaxTable mb-3">
					<template #cell(name)="data">
						<of-form-input :name="`taxes[${data.index}].name`" data-test-id="TaxTableName" no-label />
					</template>
					<template #cell(countryIsoCode)="data">
						<div>
							<of-multi-select
								:name="`taxes[${data.index}].countryIsoCode`"
								data-test-id="TaxTableCountryIsoCode"
								no-label
								:options="countries"
								track-by="isoCode"
								label-by="name"
								@input="clearState(data.index)"
							/>
						</div>
					</template>
					<template #cell(stateId)="{ item, index }">
						<of-multi-select
							:name="`taxes[${index}].stateId`"
							data-test-id="TaxTableState"
							no-label
							:options="getStates(item.countryIsoCode)"
							track-by="_id"
							label-by="displayName"
						/>
					</template>
					<template #cell(taxRate)="data">
						<of-form-input
							type="number"
							:name="`taxes[${data.index}].taxRate`"
							min="0"
							max="1"
							step="0.0001"
							data-test-id="TaxTableTaxRate"
							no-label
							:normalize="valueToFloat"
						/>
					</template>
					<template #cell(actions)="{ index }">
						<div class="TaxTable-remove" data-test-id="TaxTableRemove" @click="removeTax(index)">
							<icon name="times-circle" />
						</div>
					</template>
				</b-table>
				<ofs-feature-button type="add" :label="featureButtonLabel" @click="addTax" />
			</b-form>
			<template slot="actions">
				<of-submit-button @click.prevent="save">{{ $t('Save') }}</of-submit-button>
			</template>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	ContentHeader,
	OfsPanel,
	OfsFeatureButton,
	OfFormInput,
	OfMultiSelect,
	OfSubmitButton,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { required, minLength, maxLength, between } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import _filter from 'lodash/filter';

import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import { displayError, valueToFloat, maxDecimals } from '../../../lib/helpers';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';

const formName = 'editTariff';
export default {
	components: {
		ContentHeader,
		Loader,
		OfsPanel,
		OfsFeatureButton,
		DefaultLayout,
		OfFormInput,
		OfMultiSelect,
		OfSubmitButton
	},
	mixins: [isFulfilmentAdminMixin, withForm(formName)],
	props: {
		duplicateTariff: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			isLoading: false,
			taxCodesTitle: this.$t('Add tax codes and rates for all locations this HS CODE covers.'),
			taxFields: [
				{ label: this.$t('Tax Code'), key: 'name' },
				{ label: this.$t('Country'), key: 'countryIsoCode', tdClass: 'tariffTableFixedWidth' },
				{ label: this.$t('State/Region'), key: 'stateId', tdClass: 'tariffTableFixedWidth' },
				{ label: this.$t('Rate'), key: 'taxRate' },
				{ label: this.$t(''), key: 'actions', tdClass: 'text-right' }
			]
		};
	},
	computed: {
		...mapGetters({
			countries: 'country/countries',
			tariff: 'tariff/tariff'
		}),
		id() {
			return this.$route.params.id;
		},
		pageTitle() {
			return this.id ? this.$t('Edit HS Code') : this.$t('New HS Code');
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('HS Codes'),
					to: {
						name: 'admin.tariffs'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		featureButtonLabel() {
			return this.formData.taxes && this.formData.taxes.length ? this.$t('Add another') : this.$t('Add a tax');
		},
		validationRules() {
			const isUniqueCountryAndState = (value, tax) => {
				const otherTaxes = _filter(this.formData.taxes, t => t !== tax);
				return !_.some(otherTaxes, { countryIsoCode: tax.countryIsoCode, stateId: tax.stateId });
			};

			const maxFourDecimals = maxDecimals(4);

			const rules = {
				formData: {
					name: {
						required,
						maxLength: maxLength(255)
					},
					hsCode: {
						required,
						minLength: minLength(1)
					},
					taxes: {
						required,
						minLength: minLength(1),
						$each: {
							name: {
								required,
								maxLength: maxLength(255)
							},
							countryIsoCode: {
								required,
								isUnique: isUniqueCountryAndState
							},
							stateId: {
								isUnique: isUniqueCountryAndState
							},
							taxRate: {
								between: between(0, 1),
								maxFourDecimals
							}
						}
					}
				}
			};
			return rules;
		}
	},
	watch: {
		$route: 'fetchData',
		id: 'fetchData',
		tariff: {
			immediate: true,
			handler(value) {
				// If the tariff updates, update the form data
				this.setFormData(value);
			}
		}
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		...mapActions({
			findCountries: 'country/find',
			findTariff: 'tariff/findById',
			createTariff: 'tariff/create',
			updateTariff: 'tariff/update'
		}),
		valueToFloat,
		async fetchData() {
			this.isLoading = true;

			const maxItemsQuery = {
				query: {
					query: {
						$limit: 1000,
						$sort: { name: 1 }
					}
				}
			};

			try {
				// load countries
				await this.findCountries(maxItemsQuery);

				if (this.id) {
					await this.findTariff({ id: this.id });
				} else {
					if (this.duplicateTariff) {
						this.initFormData(this.duplicateTariff);
					} else {
						this.initFormData({
							name: '',
							hsCode: '',
							taxes: []
						});
					}
				}
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t('Unable to load HS Code') });
				this.$router.push({ name: 'admin.tariffs' });
			} finally {
				this.isLoading = false;
			}
		},
		addTax() {
			const tax = {
				name: '',
				countryIsoCode: '',
				stateId: null,
				taxRate: null
			};
			this.formData.taxes.push(tax);
			this.focusLastTaxRow();
		},
		async focusLastTaxRow() {
			await this.$nextTick(() => {
				const inputs = this.$el.querySelectorAll('[data-test-id="TaxTableName"] input');
				const nameInput = inputs[inputs.length - 1];
				nameInput.focus();
			});
		},
		clearState(taxIndex) {
			const newTaxes = this.formData.taxes.map((tax, index) => {
				if (taxIndex === index) {
					return { ...tax, stateId: null };
				}
				return tax;
			});

			this.setFormData({ ...this.formData, taxes: newTaxes });
		},
		async removeTax(taxIndex) {
			const taxToRemove = this.formData.taxes.find((_tax, index) => taxIndex === index);

			const confirm = await this.$bvModal.msgBoxConfirm(
				this.$t('Are you sure you want to delete this Tax Rate?'),
				{
					title: `${this.$t('Delete Tax Rate')}: ${taxToRemove?.name}`,
					size: 'md',
					okTitle: this.$t('Yes'),
					cancelTitle: this.$t('No')
				}
			);

			if (confirm) {
				const newTaxes = this.formData.taxes.filter((_tax, index) => taxIndex !== index);

				this.setFormData({ ...this.formData, taxes: newTaxes });
			}
		},
		getStates(countryCode) {
			return _.get(_.find(this.countries, { _id: countryCode }), 'states', []);
		},
		async save() {
			try {
				if (this.id) {
					await this.updateTariff({
						id: this.id,
						data: this.formData
					});
					this.$notify({ type: 'success', text: this.$t('HS Code updated successfully') });
				} else {
					await this.createTariff(this.formData);
					this.$notify({ type: 'success', text: this.$t('HS Code created successfully') });
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.$router.push({ name: 'admin.tariffs' });
			}
		}
	}
};
</script>

<style lang="scss">
.tariffTableFixedWidth {
	width: 25%;
}

.TaxTable {
	&-remove {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 25px;
		margin-left: auto;
		cursor: pointer;
	}
}
</style>
