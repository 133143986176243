<template>
	<b-modal :visible="visible" modal-class="DeactivateLinkModal" size="sm" @hide="handleClose">
		<div>
			<font-awesome-icon icon="exclamation-triangle" class="icon-warning" />
			<div class="mb-1 deactivate-info">
				<span>
					<!-- eslint-disable-next-line -->
					{{ $t('Are you sure you want to deactivate the account link between ') }}<b>{{ nameA }}</b>{{ $t(' and ') }}<b>{{ nameB }}</b>
				</span>
				<span>{{ $t('?') }}</span>
			</div>
		</div>
		<section slot="modal-footer">
			<b-btn v-t="'Cancel'" class="mr-1" @click="$emit('hide')" />
			<b-btn v-t="'Deactivate'" variant="primary" class="mr-1" @click="handleDeactivate(item)" />
		</section>
	</b-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object,
			default: () => ({})
		},
		nameA: {
			type: String,
			default: ''
		},
		nameB: {
			type: String,
			default: ''
		}
	},
	methods: {
		...mapActions({
			updateAccountLink: 'accountlink/update'
		}),
		async handleDeactivate(item) {
			try {
				await this.updateAccountLink({ id: item._id, data: { active: false } });
				this.$notify({ type: 'success', text: this.$t('Account link deactivated') });
				this.$emit('update');
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t(this.$errorUtils.displayError(err)) });
			} finally {
				this.handleClose();
			}
		},
		handleClose() {
			this.$emit('hide');
		}
	}
};
</script>

<style lang="scss">
.DeactivateLinkModal {
	.modal-header {
		border-bottom: 0 none;
		padding-bottom: 5px;
	}

	.modal-footer {
		display: flex;
		justify-content: center;
	}

	.icon-warning {
		display: block;
		margin: 5px auto 30px;
		width: 50px;
		height: 45px;
		color: #18a3cd;
	}

	.deactivate-info {
		text-align: center;
		padding: 20px;
	}
}
</style>
