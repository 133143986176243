<template>
	<div>
    	<div
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
            tabindex="0"
            @focusin="handleFocusIn"
            ref="select"
            class="vue-dynamic-select MultiInput"
            :class="{
                'vue-dynamic-select--focus': hasFocus,
                'vue-dynamic-select--empty': options.length === 0
            }"
        >
            <div class="vue-dynamic-select__contents">
                <template>
                    <div
                        :class="{
                            'selected-option--invalid': !valid,
                            'selected-option': true
                        }"
                        v-for="{ value, valid, message, id } in options"
                        :key="id"
                        :id="`option-${id}-${_uid}`"
                    >
                        <span v-text="value"></span>
                        <a href="#" class="vue-dynamic-select__delete" @click.prevent.stop="removeOption(id)">
                           <icon name="times-circle" class="vue-dynamic-select__delete-icon"></icon>
                        </a>

                       <b-tooltip :disabled="valid" :target="`option-${id}-${_uid}`" custom-class='MultiInput_tooltip'>
                            <template slot="default">
                               <div v-html="message"/>
                            </template>
                        </b-tooltip>
                    </div>
                </template>
                <input
                    v-if="hasFocus"
                    :disabled="disableSearchInput"
                    autocomplete="off"
                    class="search"
                    ref="search"
                    v-model="search"
                    @keydown="handleKeyPress"
                />
                <div v-if="showPlaceholder && !disableSearchInput" class="placeholder" v-text="placeholder"></div>
            </div>
            <span
                ref="clearBtn"
                tabindex="0"
                :style="{ visibility: isClearBtnVisible ? 'visible' : 'hidden' }"
                class="vue-dynamic-select__clearButton"
                @click="handleClearBtnClick"
            >
                <icon name="times-circle"></icon>
            </span>
        </div>
        <label v-if="disableSearchInput" class='m-1 MultiInput-limit-label' v-text="limitMessage"/>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { withBaseInputConfig } from '@workflow-solutions/ofs-vue-layout';

export default {
	mixins: [withBaseInputConfig()],
	props: {
		placeholder: {
			type: String,
			default: 'Add expression and press enter',
			required: false
		},
		// Maximux number of values
		limit: {
			type: Number,
			default: 50
		},
		// Error message if the limit has reached
		limitMessage: {
			type: String,
			default: 'You have reached the limit'
		},
		// Outside options to be included in validation
		extraOptions: {
			type: Array,
			default: []
		}
	},
	data() {
		return {
			hasFocus: false,
			search: null,
			isHovering: false,
			options: []
		};
	},
	created() {
		this.setOptions(this.value);
		this.$watch('value', () => { this.setOptions(this.value); });
	},
	mounted() {
		// Add onclick method to body to hide result list when component loses focus
		window.addEventListener('click', this.loseFocus);
	},
	destroyed() {
		window.removeEventListener('click', this.loseFocus);
	},
	computed: {
		showPlaceholder() {
			return !this.hasFocus;
		},
		isClearBtnVisible() {
			return this.isHovering && !_.isEmpty(this.options);
		},
		disableSearchInput() {
			return _.size(this.options) === this.limit;
		}
	},
	watch: {
		hasFocus(hasFocus) {
			if (hasFocus) {
				if (this.$refs.search) {
					this.$refs.search.focus();
				}
			} else {
				this.search = null;
				if (this.$refs.search) {
					this.$refs.search.blur();
				}
			}
		}
	},
	methods: {
		...mapActions({
			updateFormField: 'form/updateFormField'
		}),
		handleClearBtnClick($event) {
			$event.preventDefault();
			$event.stopPropagation();
			this.options = [];
			this.onInputChange([]);
		},
		handleKeyPress(event) {
			if (event.keyCode === 8 && _.isEmpty(this.search)) {
				// if backspace pressed
				this.removeLastOption();
			} else if (event.keyCode === 27) {
				// if escape pressed
				this.hasFocus = false;
			} else if (event.keyCode === 13) {
				// If enter pressed
				const value = _.trim(this.search);
				if (_.isEmpty(value)) {
					this.search = null;
				} else {
					this.addOption(value);
				}
				event.preventDefault();
			}
		},
		handleFocusIn() {
			// dont open the dropdown if we clicked on the clear button or the field is disabled
			if (this.$refs.clearBtn === document.activeElement || this.disabled) return;
			this.hasFocus = true;
			if (this.$refs.select === document.activeElement) {
				this.$nextTick(() => {
					if (this.$refs.search) {
						this.$refs.search.focus();
					}
				});
			}
		},
		loseFocus(event) {
			if (!this.$refs.select.contains(event.target)) {
				this.hasFocus = false;
			}
		},
		addOption(value) {
			this.options.push(this.getNewOption(value));
			this.updateInputValue();
			this.search = null;
		},
		updateInputValue() {
			this.options = this.validateOptions(this.options);
			this.onInputChange(this.options);
		},
		removeOption(id) {
			_.remove(this.options, option => option.id === id);
			this.updateInputValue(this.options);
		},
		removeLastOption() {
			if (_.isEmpty(this.options)) return;
			this.options.pop();
			this.updateInputValue(this.options);
		},
		getNewOption(value) {
			const id = this.generateOptionId();
			return { value, id };
		},
		generateOptionId() {
			return Math.random() * 10000;
		},
		setOptions(values = []) {
			const newOptions = this.validateOptions(values);
			if (!_.isEqual(this.options, newOptions)) {
				this.options = newOptions;
				this.updateInputValue(this.options);
			}
		},
		validateOptions(options) {
			return _.map(options, (option, i) => ({ ...option, ...this.validateOption(option, i) }));
		},
		validateOption({ value, id }, index) {
			const duplicate = _.find(this.options, (item, i) => item.value.toLowerCase() === value.toLowerCase() && index !== i) ||
							  _.find(this.extraOptions, exp => exp.value.toLowerCase() === value.toLowerCase());
			if (duplicate) return { valid: false, message: this.$t('Duplicated Expression') };
			if (!this.isValidRegExp(value))
				return {
					valid: false,
					message: this.$t('Invalid Expression<br/>Click <a href="http://regex101.com">here</a> for more information')
				};
			return { valid: true, id: id ? id : this.generateOptionId() };
		},
		isValidRegExp(regExp) {
			try {
				new RegExp(regExp);
			} catch (e) {
				return false;
			}
			return true;
		}
	}
};
</script>
<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.MultiInput {
	.selected-option {
		background-color: $of-color-highlights;
		border-radius: 3px;
		padding: 4px 8px;
		
		span {
			color: $of-color-grey-1;
			font-size: 14px;
		}
		
		&--invalid {
			background-color: rgba($of-color-red, 0.2) !important;
		}
	}

	.vue-dynamic-select__delete-icon {
		margin-left: 10px;
		width: 10px;
		height: 10px;

		path {  
			fill: $of-color-grey-1;
		}
	}

	&__delete-icon {
		margin-left: 10px;
		width: 12px;
		height: 12px;
	}

    &-limit-label {
        color: $of-color-red;
        font-size: 14px;
    }

    input.search:disabled {
        cursor: no-drop;
    }
}

.MultiInput_tooltip {
    border-radius: 5px;

	.tooltip-inner {
		min-width: 180px;
		max-width: 450px;
		background: $of-color-dark;
		padding: 12px 15px;  
		font-size: 14px;
	}
}

.MultiInput_tooltip.tooltip.b-tooltip {
	opacity: 1;
}
</style>
