import Vue from 'vue';
import 'vue-awesome/icons';
import 'font-awesome/css/font-awesome.min.css';
import '@workflow-solutions/ofs-vue-layout/dist/index.css';
import Icon from 'vue-awesome/components/Icon';
import BootstrapVue from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import Toaster from 'v-toaster';
import OfsMenu, { OfsForm, OfsFeatureToggle, OfsConfirmationModal, ConfirmationModal } from '@workflow-solutions/ofs-vue-layout';
import VueCodemirror from 'vue-codemirror';
import VeeValidate from 'vee-validate';
import * as Sentry from '@sentry/browser';
import VueGtag from 'vue-gtag';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/rubyblue.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'v-toaster/dist/v-toaster.css';
import 'ionicons/dist/css/ionicons.css';
import packageJson from '../package.json';
import store, { i18n } from './vuex';
import './directives';
import router, { menuStructure } from './router';
import App from './App';
import './styles/components.scss';
import './styles/helpers.scss';
import registerServiceWorker from './registerServiceWorker';

registerServiceWorker(process.env.BASE_URL, store);

// Sentry Error Logging
const sentryKey = window.$config.sentryKey;
if (sentryKey && window.location.hostname !== 'localhost') {
	Vue.config.errorHandler = error => console.error(error);

	Sentry.init({
		dsn: sentryKey,
		release: packageJson.version,
		environment: window.$config.environment,
		integrations: [new Sentry.Integrations.Vue({ Vue })],
		ignoreErrors: [
			/^(N|n)etwork\s?(E|e)rror/,
			/^Request failed with status code 40(0|1|3)$/,
			/^Failed to fetch$/,
			/^(C|c)ancelled$/,
			/^ResizeObserver loop/,
			/^Loading chunk .+ failed.$/,
			/^NavigationDuplicated/
		]
	});
}

if (window.$config.analyticsId) {
	Vue.use(VueGtag, {
		config: { id: window.$config.analyticsId }
	});
}

library.add(fas); // add the the whole icon pack
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VeeValidate, {
	fieldsBagName: 'inputs',
	inject: false
});
Vue.use(BootstrapVue);
Vue.use(Toaster);
Vue.use(VueCodemirror);
Vue.use(OfsMenu, { router, store, menu: menuStructure });
Vue.use(OfsForm, { store });
Vue.use(OfsFeatureToggle, { store });
Vue.use(OfsConfirmationModal, { store });

Vue.component('icon', Icon);
Vue.component('confirmationModal', ConfirmationModal);

/* eslint-disable no-new */
Vue.prototype.$HP_MODE = process.env.VUE_APP_HP_MODE; // eslint-disable-line

new Vue({
	i18n,
	el: '#app',
	router,
	store,
	template: '<App/>',
	components: { App }
});
