import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'controlCentreDataReadyOrder',
		basePath: 'order/dataready',
		pluralName: 'order/dataready',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'total' // -> `total` in the response payload
				}
			}
		},
		transport: 'controlCentreApi',
		actions: {
			async refetch({ dispatch }, { orderIds }) {
				return dispatch('request', {
					method: 'POST',
					path: `order/dataready/refetch`,
					payload: { orderIds }
				});
			}
		}
	});
}
