<template></template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			aclGroups: 'controlCentre/aclGroups'
		})
	},
	mounted() {
		if (this.aclGroups['fulfilment-admin']) {
			this.$router.push({
				name: 'admin.fulfilments',
				query: { filters: { status: ['cancelled', 'error', 'submissionError'] } }
			});
		} else if (this.aclGroups['cs-team-member']) {
			this.$router.push({
				name: 'admin.features.migration.overview'
			});
		} else {
			this.$router.push('/no-access');
		}
	}
};
</script>

<style></style>
