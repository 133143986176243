<template>
	<b-container class="Error">
		<b-alert show variant="danger" class="mb-0">
			<h1 class="alert-heading" v-text="title" />
			<p v-text="message" />
			<p class="mb-0">
				<b-button variant="danger" @click="handleLogoutClick">{{ $t('Sign Out') }}</b-button>
			</p>
		</b-alert>
	</b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
	props: {
		title: String,
		message: String
	},
	computed() {
		return {
			...mapGetters('auth', ['user', 'isHpIdLogin'])
		};
	},
	methods: {
		...mapActions('account', ['changeAccount']),
		...mapActions('auth', ['logout']),
		async handleLogoutClick() {
			await this.logout();
			await this.changeAccount(null);
			if (this.isHpIdLogin) {
				window.location.href = window.$config.hpIdLogout;
			} else {
				this.$router.push('/login');
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.Error {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	height: 100vh;
}
</style>
