import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'device-type',
		pluralName: 'device-types',
		transport: 'connect',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'count' // -> `count` in the response payload
				}
			}
		}
	});
}
