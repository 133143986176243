<template>
	<list-table
		v-if="fulfilment.status == 'shipped'"
		:items="shipments"
		:fields="shipmentFields"
		:per-page="perPage"
		:current-page="currentPage"
		:is-busy="isBusy"
		:page-position-prefix="$t('Showing')"
		:page-position-join="$t('of')"
		@row-clicked="itemClicked"
		@table-change="handleTableChange"
		hover
	>
		<template slot="prev-text" slot-scope="{}">
			{{ $t('Prev') }}
		</template>
		<template slot="next-text" slot-scope="{}">
			{{ $t('Next') }}
		</template>
		<template slot="empty">
			<span>
				<i>{{ $t('No Data') }}</i>
			</span>
		</template>
		<template #cell(onShippedAt)="{ value }">
			{{ formatDate(value) }}
		</template>
		<template #cell(trackingNumber)="{ item }">
			<a v-if="item.trackingUrl" target="_blank" data-test-id="package-tracking" :href="item.trackingUrl">
				{{ item.trackingNumber }}
			</a>
			<span v-else-if="item.trackingNumber">{{ item.trackingNumber }}</span>
			<span v-else>-</span>
		</template>
	</list-table>
	<div v-else class="FulfilmentShipping_empty">
		<icon name="paper-plane" scale="2" />
		<p v-t="'The Site Flow order has not shipped yet.'" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ListTable, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import { dateFormatMixin } from '../../../mixins/dateFormatMixin';
import tableMixin from '../../../mixins/tableMixin';

const FulfilmentShippingList = {
	components: {
		ListTable
	},
	mixins: [tableMixin, dateFormatMixin()],
	props: {
		id: {
			type: String,
			default: ''
		},
		accountId: {
			type: String,
			default: ''
		}
	},
	data() {
		const emptyFormatter = value => value || '-';

		return {
			isBusy: false,
			shipmentFields: [
				{ key: 'id', label: this.$t('Site Flow Shipment ID') },
				{ key: 'carrier.code', label: this.$t('Carrier'), formatter: emptyFormatter },
				{ key: 'carrier.service', label: this.$t('Service'), formatter: emptyFormatter },
				{ key: 'trackingNumber', label: this.$t('Tracking No.'), formatter: emptyFormatter },
				{ key: 'onShippedAt', label: this.$t('Shipped At'), formatter: emptyFormatter }
			]
		};
	},
	computed: {
		...mapGetters({
			fulfilment: 'fulfilment/fulfilment'
		}),
		shipments() {
			return _.get(this.fulfilment, 'consignments', []);
		}
	},
	methods: {
		...mapActions({
			findFulfilment: 'fulfilment/findById'
		}),
		async fetchData() {
			if (!this.fulfilment || this.fulfilment._id !== this.id) {
				await this.findFulfilment({ id: this.id, query: { query: { $populate: { path: 'consignments' } } } });
			}
		},
		itemClicked(item) {
			this.$router.push({ name: 'admin.fulfilments.shipment.view', params: { shipmentId: item.id } });
		}
	},
	watch: {
		id: 'fetchData'
	},
	mounted() {
		this.fetchData();
	}
};

export default FulfilmentShippingList;
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.FulfilmentShipping {
	&_empty {
		display: flex;
		flex-direction: column;
		gap: 14px;
		justify-content: center;
		align-items: center;

		height: 600px;
	}
}
</style>
