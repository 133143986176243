import _ from 'lodash';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/browser';

const $config = window.$config || {};

// Account
export const getAccount = async ({ dispatch }) => {
	const path = `${window.$config.apiBase}/api/account`;
	const results = await dispatch('api/request', { method: 'GET', path }, { root: true });
	return results;
};

export const getUserAccounts = async ({ dispatch, commit }, params) => {
	const { data: accounts } = await dispatch(
		'api/request',
		{ method: 'GET', path: `${window.$config.apiBase}/api/account/user`, params },
		{ root: true }
	);
	const userAccounts = {
		accounts: accounts.success ? accounts.data : accounts,
		count: accounts.success ? accounts.count : _.size(accounts)
	};
	commit('SET_USER_ACCOUNTS', userAccounts);

	return userAccounts;
};

export const getAccounts = async ({ dispatch, commit }) => {
	const path = `${window.$config.apiBase}/api/account/user`;
	const { data } = await dispatch('api/request', { method: 'GET', path }, { root: true });
	const accounts = {};

	// Filter disabled and inactive accounts
	// Also only fetch "pro" accounts
	_.remove(data, account => {
		return account.disabled !== false || account.status !== 'active' || account.plan !== 'pro';
	});

	_.each(data, account => {
		accounts[account._id] = {
			_id: account._id,
			key: account.name,
			name: account.companyName,
			psp: account.psp,
			disabled: account.disabled,
			status: account.status,
			plan: account.plan
		};
	});

	commit('SET_CUSTOMER_ACCOUNTS', accounts);
};

export const setCurrentAccount = ({ commit }, account) => {
	commit('SET_ACCOUNT', account);
};

export const getVars = async ({ dispatch, commit }) => {
	const path = `${window.$config.apiBase}/api/vars`;
	const { data: vars } = await dispatch('request', {
		method: 'GET',
		path,
		transport: 'connect'
	});

	// Setup moment defaults
	moment.tz.setDefault(vars.oneflowAccountSettings.timezone);
	moment.fn.accountFormat = function accountDateFormat(timeFormat = '') {
		return this.format(`${vars.oneflowAccountSettings.dateFormat.toUpperCase()}${timeFormat}`);
	};

	vars.userAccountLookup = {};
	_.each(vars.userAccountHash, account => {
		vars.userAccountLookup[account._id] = account.name;
	});

	commit('SET_VARS', vars);
	commit('SET_ACCOUNTS', vars.userAccountHash);

	return vars;
};

export const getPspAccounts = async ({ dispatch, commit }) => {
	const path = `${window.$config.apiBase}/api/vars/pspAccounts`;
	const { data } = await dispatch('request', {
		method: 'GET',
		path,
		transport: 'connect'
	});

	commit('SET_PSP_ACCOUNTS', data);
};

export const inviteUser = ({ dispatch }, { email, role, system }) => {
	const payload = { email, role, system };
	const productApi = $config.productApi;

	return dispatch('request', {
		method: 'POST',
		path: `${productApi}/api/users/invite`,
		payload
	});
};

export const removeMembership = ({ dispatch }, userId) =>
	dispatch('request', {
		method: 'DELETE',
		path: `account/memberships/${userId}`
	});

export const updateMembership = ({ dispatch }, { userId, payload }) =>
	dispatch('request', {
		method: 'PUT',
		path: `account/memberships/${userId}`,
		payload
	});

export const changeAccount = async ({ dispatch }, account) => dispatch('setCurrentAccount', account);

export const saveAccountSettings = async ({ dispatch, state, commit }, accountSettings) => {
	const { _id: accountId } = state.currentAccount || {};

	const { settings } = await dispatch('request', {
		method: 'PUT',
		path: 'accountSettings/save',
		payload: {
			accountId,
			...accountSettings
		},
		transport: 'connect'
	}).then(r => r.data);

	commit('UPDATE_VARS_ACCOUNT_SETTINGS', settings);
	return settings;
};

export const getMemberships = async ({ state, commit, dispatch }, params) => {
	const selectedAccountId = _.get(state, 'currentAccount._id');
	const { data } = await dispatch('request', {
		method: 'GET',
		path: `account/${selectedAccountId}/memberships`,
		query: params
	});

	const userId = _.get(params, 'query.userId');

	if (userId) {
		commit('SET_USER_MEMBERSHIPS', data.data);
	} else {
		commit('SET_MEMBERSHIPS', data.data);
		commit('SET_MEMBERSHIPS_COUNT', data.total);
	}
	return data;
};

export const getMember = async ({ commit, dispatch }, { userId, skipMutations }) => {
	const { data } = await dispatch('request', {
		method: 'GET',
		path: `account/memberships/${userId}`
	});

	if (!skipMutations) {
		commit('SET_MEMBER', data);
	}
	return data;
};

const findOneflowAccount = accounts => {
	const enviromentSuffix = $config.environment === 'stage' ? `-${$config.environment}` : '';
	const accountName = `oneflow${enviromentSuffix}`;

	return _.find(accounts, { name: accountName });
};

export const fetchBaseAccountData = async ({ dispatch, getters, rootGetters }) => {
	const isAuthenticated = rootGetters['auth/isAuthenticated'];

	if (isAuthenticated) {
		const user = await dispatch('auth/getUser', null, { root: true });
		Sentry.configureScope(scope => {
			scope.setUser({
				id: user._id,
				email: user.email,
				username: user.username
			});
		});

		// Fetch Auth Accounts
		await dispatch('auth/getAccounts', null, { root: true });

		// Default account to currentAccount (previously selected)
		let account = getters['currentAccount'];

		// Fetch Brand Centre/Piazza Memberships
		const memberships = await dispatch('user/getMemberships', user._id, { root: true });
		await dispatch('setCurrentUserMemberships', memberships);
		const accounts = getters['accountList'] || [];

		// If previous selected account
		let accountMembership;
		if (account) {
			// Check if current account has a valid membership
			accountMembership = _.find(accounts, {
				name: account.name
			});
		}

		if (!accountMembership) {
			account = findOneflowAccount(accounts) || accounts[0];
		}

		if (!account) {
			const redirectHpIdLogout = process.env.VUE_APP_HP_MODE && rootGetters['auth/isHpIdLogin'];
			await dispatch('auth/logout', null, { root: true });
			await dispatch('changeAccount', null);
			if (redirectHpIdLogout) {
				window.location.href = `${window.$config.hpIdLogout}&auth_error=1`;
			}
		} else {
			await dispatch('setCurrentAccount', account);

			Sentry.configureScope(scope => {
				scope.setExtra('accountId', account._id);
			});
			const vars = await dispatch('getVars');

			//After user information is obtained, get set of feature flags specific to user
			await dispatch('featureFlags/checkAndStoreFlags', { root: true });
			return vars;
		}
	}

	return null;
};

export const getUsersForAccount = async ({ dispatch }, { id, query }) => {
	const path = `account/${id}/memberships`;
	return dispatch('request', { method: 'GET', path, params: { query } }).then(r => r.data);
};

export const setCurrentUserMemberships = ({ commit }, memberships) => {
	commit('SET_CURRENT_USER_MEMBERSHIPS', memberships);
};

export const setPsp = ({ commit }, psp) => {
	commit('SET_PSP', psp);
};

export const addPsp = ({ commit, getters }, psp) => {
	const existingPsp = _.find(getters.psps, {
		value: psp
	});

	if (!existingPsp) {
		commit('ADD_PSP', psp);
	}
};

export const clearPsps = ({ commit }) => {
	commit('CLEAR_PSPS');
};
