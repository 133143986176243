<template>
	<DefaultLayout class="RoleAddEdit">
		<b-form novalidate class="RoleAddEdit-form" @submit.prevent="handleSubmit">
			<OfsPanel>
				<ContentHeader :title="id ? $t('Edit Role') : $t('Add Role')" no-padding class="mb-3" />
				<b-row>
					<b-col>
						<of-form-input
							name="name"
							data-test-id="name"
							type="text"
							:label="$t('Name')"
							:placeholder="$t('Role name')"
							required
						/>
						<h3 v-if="roleHasResources" class="mt-3">{{ $t('Resources') }}</h3>
						<b-row v-for="(resources, key) in roleResources" :key="key" class="RoleAddEdit-resource">
							<span>{{ key }}</span>
							<div>
								<b-button
									:variant="getButtonVariant(resources, 'read')"
									@click="handleChangePremission(key, 'read')"
								>
									{{ $t('Read') }}
								</b-button>
								<b-button
									:variant="getButtonVariant(resources, 'write')"
									class="ml-2"
									@click="handleChangePremission(key, 'write')"
								>
									{{ $t('Write') }}
								</b-button>
							</div>
						</b-row>
					</b-col>
				</b-row>
				<h3 class="mt-3">{{ $t('System Resources') }}</h3>
				<b-row class="m-0 justify-content-between align-items-end">
					<of-multi-select
						class="m-0 mr-1 flex-grow-1"
						name="_resourceOptions"
						data-test-id="resource"
						label-by="name"
						track-by="_id"
						:options="resourceOptions"
						:filter-inactive="false"
						:label="$t('Resource')"
					/>
					<b-button
						variant="secondary"
						class="mt-2"
						:disabled="!selectedResource"
						@click="addResouceToRole"
					>
						{{ $t('Add To Role') }}
					</b-button>
				</b-row>
				<div slot="actions">
					<of-submit-button v-t="'Save'" />
				</div>
			</OfsPanel>
		</b-form>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { ContentHeader, OfsPanel, OfSubmitButton, OfFormInput, OfMultiSelect, withForm } from '@workflow-solutions/ofs-vue-layout';
import { displayError } from '../../lib/helpers';
import DefaultLayout from '../../components/DefaultLayout';
import navigationMixin from '../../mixins/navigationMixin';
import { $t } from '../../vuex';

export default {
	name: 'RoleAddEdit',
	components: {
		ContentHeader,
		OfsPanel,
		DefaultLayout,
		OfSubmitButton,
		OfFormInput,
		OfMultiSelect
	},
	mixins: [withForm('RoleForm'), navigationMixin],
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			resources: 'role/resources',
			role: 'role/role'
		}),
		id() {
			return this.$route.params.id;
		},
		roleResources() {
			const resources = _.get(this.formData, 'resources');
			return _.groupBy(resources, 'path');
		},
		filteredResources() {
			return _.filter(this.resources, path => !_.find(this.roleResources, { path }));
		},
		resourceOptions() {
			return _.map(this.filteredResources, path => ({ _id: path, name: path }));
		},
		selectedResource() {
			return _.get(this.formData, '_resourceOptions');
		},
		roleHasResources() {
			return _.get(this.formData, 'resources', []).length;
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			updateFormField: 'form/updateFormField',
			getResources: 'role/getResources',
			getRole: 'role/get',
			createRole: 'role/create',
			updateRole: 'role/update'
		}),
		async fetchData() {
			try {
				if (this.id) {
					await this.getRole({ id: this.id });
					this.setFormData(this.role);
				} else {
					this.setFormData({ resources: [] });
				}

				await this.getResources();
			} catch (err) {
				const message = $t(displayError(err));
				this.$notify({ type: 'error ', text: message });
			}
		},
		async handleSubmit() {
			try {
				const data = _.omit(this.formData, '_resourceOptions');

				const resources = _.get(data, 'resources', []);
				const validResources = _.filter(resources, resource => resource.method);
				data.resources = validResources;

				if (this.id) {
					await this.updateRole({ id: this.id, data });
				} else {
					await this.createRole(data);
				}

				this.$notify({
					type: 'success',
					text: this.id ? $t('Role has been updated') : $t('Role has been created')
				});
				this.goBack({ name: 'role.list' });
			} catch (err) {
				const message = $t(displayError(err));
				this.$notify({ type: 'error ', text: message });
			}
		},
		addResouceToRole() {
			const selectedRole = { path: this.selectedResource, method: null };
			this.updateFormData({ resources: [...this.formData.resources, selectedRole], _resourceOptions: null });
		},
		getButtonVariant(resources, method) {
			const permission = _.find(resources, { method });

			if (permission) {
				return 'success';
			}

			return 'danger';
		},
		updateFormPath(fieldPath, value) {
			return this.updateFormField({ formName: this.formName, fieldPath, value });
		},
		handleChangePremission(path, method) {
			let currentResourceIndex = _.findIndex(this.formData.resources, { path, method });

			if (currentResourceIndex > -1) {
				return this.updateFormPath(`resources[${currentResourceIndex}].method`, null);
			}

			currentResourceIndex = _.findIndex(this.formData.resources, { path, method: null });

			if (currentResourceIndex > -1) {
				return this.updateFormPath(`resources[${currentResourceIndex}].method`, method);
			}

			this.updateFormData({ resources: [...this.formData.resources, { path, method }] });
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.RoleAddEdit {
	&-form {
		max-width: 800px;
	}

	&-resource {
		margin: 0;
		padding-bottom: 0.25 * $spacer;
		padding-top: 0.25 * $spacer;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $of-color-light;

		&:hover {
			background-color: $of-color-grey-4;
		}
	}
}
</style>
