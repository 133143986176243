<template>
	<DefaultLayout class="TransformerAddEdit">
		<b-form novalidate @submit.prevent="handleSubmit">
			<OfsPanel>
				<ContentHeader
					:title="id ? $t('Edit Transformer') : $t('Add Transformer')"
					no-padding
					class="mb-3"
				/>
				<b-row>
					<b-col lg="6">
						<of-form-input
							name="name"
							data-test-id="name"
							type="text"
							:label="$t('Name')"
							:placeholder="$t('Transformer name')"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col lg="6">
						<of-multi-select
							name="type"
							data-test-id="type"
							track-by="name"
							label-by="name"
							:options="types"
							:filter-inactive="false"
							:placeholder="$t('Type')"
							:label="$t('Type')"
						/>
					</b-col>
					<b-col v-if="id" lg="6">
						<of-multi-select
							name="linkedAccountIds"
							:options="sourceAccountValues"
							:filter-inactive="false"
							label-by="name"
							track-by="_id"
							:multiple="true"
							:placeholder="$t('Linked Account')"
							:label="$t('Linked Account')"
						/>
					</b-col>
				</b-row>
				<b-row v-if="!id">
					<b-col lg="6">
						<of-multi-select
							name="linkedAccountIds"
							data-test-id="linkedAccountIds"
							:options="sourceAccountValues"
							:filter-inactive="false"
							label-by="name"
							track-by="_id"
							:multiple="true"
							:placeholder="$t('Linked Account')"
							:label="$t('Linked Account')"
						/>
					</b-col>
				</b-row>
				<b-row class="mb-2">
					<b-col lg="6" class="d-flex flex-column" data-test-id="sourceCode">
						<h2>
							{{ $t('Source Code') }}
						</h2>
						<CodeEditor v-model="code" class="TransformerAddEdit-code" />
					</b-col>
					<b-col v-if="id" lg="6" class="d-flex flex-column justify-content-between">
						<div>
							<h2>
								{{ $t('Test Data') }}
							</h2>
							<CodeEditor v-model="testData" />
						</div>
						<div class="TemplateAddEdit-preview mt-3">
							<h2 class="mb-2">
								{{ $t('Result') }}
							</h2>
							<CodeEditor v-model="result" :options="{ readOnly: true }" />
						</div>
					</b-col>
				</b-row>
				<div slot="actions">
					<b-button
						v-if="id"
						v-t="'Execute'"
						variant="light"
						class="optional-primary-btn mr-1"
						@click="handleExecute"
					/>
					<of-submit-button v-t="'Save'" />
				</div>
			</OfsPanel>
		</b-form>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { ContentHeader, OfsPanel, OfSubmitButton, OfFormInput, withForm, OfMultiSelect } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import navigationMixin from '../../mixins/navigationMixin';
import CodeEditor from '../../components/CodeEditor';
import { $t } from '../../vuex';

export default {
	name: 'TransformerAddEdit',
	components: {
		ContentHeader,
		OfsPanel,
		DefaultLayout,
		OfSubmitButton,
		OfFormInput,
		OfMultiSelect,
		CodeEditor
	},
	mixins: [withForm('TransformerForm'), navigationMixin],
	data() {
		return {
			testData: '',
			result: '',
			types: [{ name: 'order.submitted' }, { name: 'shipment.shipped' }]
		};
	},
	computed: {
		...mapGetters({
			vars: 'account/vars',
			transformers: 'transformer/transformers',
			transformer: 'transformer/transformer',
			accountlinks: 'accountlink/accountlinks',
			featureIsActive: 'featureToggle/isActive'
		}),
		code: {
			get() {
				return _.get(this.formData, 'code');
			},
			set(newVal) {
				this.updateFormPath('code', newVal);
			}
		},
		id() {
			return this.$route.params.id;
		},
		sourceAccountValues() {
			if (this.featureIsActive('siteflow-setup-accountlinks') && false) {
				let accounts = [{ _id: this.vars.accountId, name: this.vars.currentAccount }];
				let disabledLinks = [];
				_.each(this.accountlinks, link => {
					if (link.active) {
						link.accountIdA === this.vars.accountId
							? accounts.push({ _id: link.accountIdB, name: link.accountNameB })
							: accounts.push({ _id: link.accountIdA, name: link.accountNameA });
					} else {
						link.accountIdA === this.vars.accountId
							? disabledLinks.push({ _id: link.accountIdB, name: link.accountNameB })
							: disabledLinks.push({ _id: link.accountIdA, name: link.accountNameA });
					}
				});
				this.addMissingAccountOptions(accounts, disabledLinks);
				return accounts;
			} else {
				const accounts = _.get(this.vars, 'userAccountHash', {});
				return _.map(accounts, ({ _id }, key) => ({ _id, name: key }));
			}
		},
		validationRules() {
			return { formData: { name: { required }, type: { required } } };
		}
	},
	watch: {
		$route() {
			this.clearData();
		}
	},
	created() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			updateFormField: 'form/updateFormField',
			executeTransformer: 'transformer/execute',
			getTransformer: 'transformer/get',
			createTransformer: 'transformer/create',
			updateTransformer: 'transformer/update',
			getAccountLinks: 'accountlink/findAll'
		}),
		async fetchData() {
			try {
				this.getAccountLinks();
				if (this.id) {
					await this.getTransformer({ id: this.id });
					this.setFormData(this.transformer);
				} else {
					this.setFormData({});
				}
			} catch (err) {
				this.goBack({ name: 'transformer.list' });
				const message = $t(this.$errorUtils.displayError(err));
				this.$notify({ type: 'error ', text: message });
			}
		},
		async handleSubmit() {
			try {
				if (this.id) {
					await this.updateTransformer({ id: this.id, data: this.formData });
				} else {
					await this.createTransformer(this.formData);
				}

				this.$notify({
					type: 'success',
					text: this.id
						? $t('Transformer Schema has been updated')
						: $t('Transformer Schema has been created')
				});
				this.goBack({ name: 'transformer.list' });
			} catch (err) {
				const message = $t(this.$errorUtils.displayError(err));
				this.$notify({ type: 'error ', text: message });
			}
		},
		updateFormPath(fieldPath, value) {
			return this.updateFormField({ formName: this.formName, fieldPath, value });
		},
		async handleExecute() {
			let data;

			try {
				data = JSON.parse(this.testData);
			} catch (err) {
				return this.$notify({ type: 'error ', text: $t('Test data is incorrect') });
			}

			try {
				await this.$confirm({
					title: $t('Execute transformer'),
					body: $t('Are you sure you want to execute this transformer?')
				});
				const result = await this.executeTransformer({ id: this.id, data });
				this.result = JSON.stringify(result, null, 4);
				this.$notify({
					type: 'success',
					text: $t('Transformer has been executed')
				});
			} catch (err) {
				const message = $t(this.$errorUtils.displayError(err));
				this.$notify({ type: 'error ', text: message });
			}
		},
		clearData() {
			this.testData = '';
			this.result = '';
			this.resetFormData();
		},
		// Fills in gaps and adds custom formatting in case any account related to the transformer
		// is not account's linked account
		addMissingAccountOptions(accounts, disabledLinks) {
			if (this.formData?.linkedAccountIds) {
				this.formData.linkedAccountIds.forEach(id => {
					let accountExists = _.find(accounts, acc => acc._id === id);
					if (!accountExists) {
						let userAccount = _.find(disabledLinks, acc => acc._id === id);
						if (userAccount) {
							userAccount.class = 'linkInactive';
							userAccount.tooltip = $t('Account link disabled');
						} else {
							userAccount = { _id: id, name: id };
							userAccount.class = 'notLinked';
							userAccount.tooltip = $t('Account not linked');
						}
						accounts.push(userAccount);
					}
				});
			}
			return accounts;
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.TransformerAddEdit {
	&-code {
		height: 600px;
		flex: 1;

		.CodeMirror {
			height: 100%;
		}
	}

	&-preview {
		margin-bottom: 0;
		display: flex;
		flex-direction: column;
	}

	.notLinked {
		background-color: $of-color-red;
		color: $of-color-white;
		border-radius: 0.25rem;
	}

	.linkInactive {
		background-color: $of-color-grey-3;
		color: $of-color-grey-1;
		border-radius: 0.25rem;
	}
}
</style>
