<template>
	<DefaultLayout class="DeviceTypeEdit">
		<form @submit.prevent="handleFormSubmit">
			<OfsPanel>
				<ContentHeader class="mb-2" :title="title" no-padding />

				<b-row>
					<b-col md="6">
						<of-form-input name="model" data-test-id="model" :label="$t('Model')" required />

						<of-form-input name="family" data-test-id="family" :label="$t('Family')" required />

						<of-form-input
							name="manufacturer"
							data-test-id="manufacturer"
							:label="$t('Manufacturer')"
							required
						/>

						<of-form-input name="category" data-test-id="category" :label="$t('Category')" />

						<of-form-input name="hpType" data-test-id="hpType" :label="$t('HP Type')" />
					</b-col>

					<b-col md="6">
						<of-form-input name="hpModelName" :label="$t('HP Model Name')" />

						<of-form-input
							:label="$t('Setup Time (minutes)')"
							type="number"
							step="1"
							name="setupTime"
							data-test-id="setupTime"
							min="0"
						/>

						<of-multi-select
							name="runSpeedType"
							data-test-id="runSpeedType"
							:label="$t('Run Speed Type')"
							:options="runSpeedTypeOptions"
							:placeholder="$t('Select option')"
						/>

						<of-form-input
							:label="
								`${$t('Run Speed')} ${
									formData.runSpeedType ? `(${formData.runSpeedType} ${$t('per hour')})` : ''
								}`
							"
							type="number"
							step="0.001"
							min="0.001"
							name="runSpeed"
							data-test-id="runSpeed"
						/>

						<b-row>
							<b-col>
								<of-form-input
									:label="$t('Width')"
									type="number"
									step="0.001"
									name="sheet.width"
									data-test-id="sheetWidth"
									min="0.001"
								/>
							</b-col>
							<b-col>
								<of-form-input
									:label="$t('Height')"
									type="number"
									step="0.001"
									name="sheet.height"
									data-test-id="sheetHeight"
									min="0.001"
								/>
							</b-col>
						</b-row>
					</b-col>
				</b-row>

				<div slot="actions">
					<of-submit-button v-t="'Save'" />
				</div>
			</OfsPanel>
		</form>
	</DefaultLayout>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import { ContentHeader, OfSubmitButton, withForm, OfFormInput, OfsPanel, OfMultiSelect } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import { displayError } from '../../lib/helpers';
import navigationMixin from '../../mixins/navigationMixin';
import { $t } from '../../vuex';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		OfsPanel,
		OfSubmitButton,
		OfFormInput,
		OfMultiSelect
	},
	mixins: [withForm('deviceTypeForm'), navigationMixin],
	computed: {
		...mapGetters({
			deviceType: 'device-type/device-type'
		}),
		id() {
			return this.$route.params.id;
		},
		isEdit() {
			return !!this.id;
		},
		isAdd() {
			return !this.isEdit;
		},
		title() {
			return this.isEdit ? this.$t('Edit Device Type') : this.$t('Add Device Type');
		},
		runSpeedTypeOptions() {
			return [
				{
					value: 'impressions',
					text: this.$t('Impressions')
				},
				{
					value: 'sheets',
					text: this.$t('Sheets')
				},
				{
					value: 'items',
					text: this.$t('Items')
				},
				{
					value: 'builds',
					text: this.$t('Builds')
				}
			];
		}
	},
	watch: {
		deviceType: {
			immediate: true,
			handler(newValue) {
				if (newValue && newValue._id) {
					this.setFormData(newValue);
				}
			}
		},
		id: 'fetchData'
	},
	created() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			get: 'device-type/get',
			update: 'device-type/update',
			create: 'device-type/create'
		}),
		async fetchData() {
			const id = this.id;
			try {
				if (id) {
					await this.get({ id });
				} else {
					await this.setFormData({});
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				throw err;
			}
		},
		async handleFormSubmit() {
			const data = this.formData || {};
			const id = data._id;

			try {
				let notificationText;
				if (this.isEdit) {
					await this.dispatchSubmit(this.update({ id, data }));
					notificationText = $t('Saved successfully');
				} else {
					await this.dispatchSubmit(this.create(data));
					notificationText = $t('Created successfully');
				}

				this.goBack({ name: 'deviceType.list' });
				this.$notify({ type: 'success', text: notificationText });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				throw err;
			}
		}
	}
};
</script>
