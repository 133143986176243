import adminRoutes from '../containers/Admin';
import homeRoutes from '../containers/Home';
import featureRoutes from '../containers/Feature';
import accountRoutes from '../containers/Account';
import appRoutes from '../containers/App';

import deviceTypeRoutes from '../containers/DeviceType';
import productTypeRoutes from '../containers/ProductType';
import roleRoutes from '../containers/Role';
import accessControllerRoutes from '../containers/Access-Controler';
import transformerRoutes from '../containers/Transformer';
import supportToolsRoutes from '../containers/SupportTools';

const routes = [
	...adminRoutes,
	...homeRoutes,
	...featureRoutes,
	...accountRoutes,
	...deviceTypeRoutes,
	...productTypeRoutes,
	...roleRoutes,
	...accessControllerRoutes,
	...transformerRoutes,
	...appRoutes,
	...supportToolsRoutes
];

export default routes;
