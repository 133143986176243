<template>
	<AppLayout>
		<template #top-bar>
			<TopBar>
				<template #logo>
					<router-link tag="span" class="u-cursorPointer" to="/">
						<img src="img/control-centre-white.svg" />
					</router-link>
				</template>
				x
				<template #account>
					<AccountSwitcher
						:current-account="currentAccount"
						:accounts="accountList"
						:on-account-selection="hlop"
					>
						<template slot="current-account-title" slot-scope="{}">
							{{ $t('Account') }}
						</template>
					</AccountSwitcher>
					<b-dropdown
						ref="userDropdown"
						size="sm"
						variant="link"
						right
						class="user-dropdown"
						@hide="onDropdownHide"
					>
						<template v-slot:button-content>
							<icon name="user" width="12"></icon>
						</template>

						<button
							role="menuitem"
							class="dropdown-item MenuControls-userDropdown-languageMenuItem"
							@click="onLanguagesClick"
						>
							{{ $t('Languages') }}

							<div
								role="menu"
								class="dropdown-menu"
								:x-placement="languagesPlacement"
								:class="{ show: showLanguages }"
							>
								<button
									v-for="(languageOption, i) in languages"
									:key="i"
									role="menuitem"
									type="button"
									class="dropdown-item"
									:class="{ active: languageOption.code === lang }"
									@click="onLanguagesSelection(languageOption)"
									v-text="languageOption.name"
								/>
							</div>
						</button>

						<b-dropdown-divider />

						<b-dropdown-item :href="ossAttributionLink" target="_blank">
							{{ $t('Open Source Software') }}
						</b-dropdown-item>
						<b-dropdown-item @click="handleLogoutClick">
							{{ $t('Sign Out') }}
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</TopBar>
		</template>
		<template #content>
			<div class="AppContent">
				<slot></slot>
			</div>
			<VersionNotification />
		</template>
	</AppLayout>
</template>

<script type="text/javascript">
import { sortBy } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { AppLayout, TopBar, AccountSwitcher } from '@workflow-solutions/ofs-vue-layout';
import 'vue-awesome/icons/user';
import './DefaultLayout.scss';
import VersionNotification from './VersionNotification';

export default {
	components: {
		AppLayout,
		TopBar,
		AccountSwitcher,
		VersionNotification
	},
	props: {
		languagesPlacement: String
	},
	data() {
		return {
			showLanguages: false
		};
	},
	computed: {
		...mapGetters('auth', ['user', 'isHpIdLogin']),
		...mapGetters('account', ['currentAccount', 'currentUserMemberships', 'accountList']),
		...mapGetters('lang', ['lang', 'languages']),
		ossAttributionLink() {
			// eslint-disable-next-line
			return 'https://hpsiteflow.com/docs/siteflow/siteflow-open-source-software.html';
		}
	},
	methods: {
		...mapActions('account', ['changeAccount']),
		...mapActions('auth', ['logout']),
		...mapActions('lang', ['setLanguage']),
		hlop(account) {
			this.changeAccount(account);
			this.$nextTick(() => window.location.reload());
		},
		async handleLogoutClick() {
			await this.logout();
			await this.changeAccount(null);
			if (this.isHpIdLogin) {
				window.location.href = window.$config.hpIdLogout;
			} else {
				this.$router.push('/login');
			}
		},
		onLanguagesClick(e) {
			e.stopPropagation();
			this.showLanguages = !this.showLanguages;
		},
		async onLanguagesSelection(lang) {
			this.$refs.userDropdown.hide();
			await this.setLanguage({ lang: lang.code });
		},
		onDropdownHide() {
			this.showLanguages = false;
		}
	}
};
</script>
<style lang="scss">
.AppContent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: auto;
}

.hpLogo {
	opacity: 0.15;
	width: 35px;
}

.MenuControls {
	&-userDropdown {
		border-left: 0.5px solid #525869;
		padding-right: 0;
		padding-left: 15px;
		height: 100%;

		&-languageMenuItem {
			position: relative;

			& > .dropdown-menu {
				left: -160px;
				top: -11px;
			}
		}
	}
}

.OneflowAppLayout .OneflowTopBar .dropdown-item,
.OneflowAppLayout .OneflowTopBar .OneflowTopBar-Account a {
	color: #212529;
	cursor: pointer;
}

.OneflowAppLayout {
	.OneflowTopBar {
		.OneflowTopBar-Account {
			a {
				color: #212529;
				cursor: pointer;
			}
		}

		.user-dropdown {
			padding-left: 1rem;
		}

		.dropdown-toggle {
			color: #b0b2b9;
			font-size: 15px;
		}
	}
}
</style>
