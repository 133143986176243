import Vue from 'vue';
import axios from 'axios';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'tariff',
		pluralName: 'tariffs',
		transport: 'fulfilmentApi',
		options: {
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'total'
				}
			}
		},
		state: {
			statesJson: []
		},
		getters: {
			statesJson: state => state.statesJson
		},
		actions: {
			async getStatesJson({ commit }) {
				const path = '/data/states.json';
				const { data } = await axios.get(path);
				commit('SET_STATES_JSON', data);
			}
		},
		mutations: {
			SET_STATES_JSON(state, data) {
				state.statesJson = data;
			}
		}
	});
}
