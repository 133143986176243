<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3" />
			<b-form>
				<b-row>
					<b-col lg="8">
						<b-row>
							<b-col lg="6">
								<of-form-input
									name="name"
									data-test-id="name"
									:show-errors="true"
									:label="$t('Name')"
								/>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<template v-if="id">
					<hr class="form-divider" />
					<h3 class="form-header">
						{{ $t('Set the discount amounts per fee.') }}
					</h3>
					<b-row>
						<b-col lg="8">
							<b-table
								:fields="discountProfileEntry"
								:items="filledEntries"
								class="mb-3"
								hover
								@row-clicked="editEntry"
							>
								<template #cell(fee)="{ item }">
									{{ item.feeId && item.feeId.displayName | dashIfEmpty }}
								</template>
								<template #cell(actions)="{ item }">
									<a
										class="d-flex justify-content-end align-items-center"
										data-test-id="removeEntry"
										@click="removeEntry(item._id)"
									>
										<icon name="times-circle" />
									</a>
								</template>
							</b-table>
						</b-col>
					</b-row>
					<b-row>
						<b-col lg="8">
							<ofs-feature-button
								type="add"
								:label="
									filledEntries && filledEntries.length ? $t('Add Another Entry') : $t('Add an Entry')
								"
								@click="addEntry"
							/>
						</b-col>
					</b-row>
				</template>
			</b-form>
			<template slot="actions">
				<of-submit-button @click.prevent="save">{{ $t('Save') }}</of-submit-button>
			</template>
		</ofs-panel>
		<DiscountProfileEntryModal
			v-if="discountProfile"
			:entry="entry"
			:discount-profile="discountProfile"
			:entries="filledEntries"
			:show="shouldShowEntryModal"
			:on-close="closeEntryModal"
			@save-entry="saveEntry"
		/>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	ContentHeader,
	OfsPanel,
	OfsFeatureButton,
	OfFormInput,
	OfMultiSelect,
	OfSubmitButton,
	withForm,
	validateWithMessage
} from '@workflow-solutions/ofs-vue-layout';
import { required, maxLength } from 'vuelidate/lib/validators';
import DefaultLayout from '../../../components/DefaultLayout';
import DiscountProfileEntryModal from './DiscountProfileEntryModal.vue';
import Loader from '../../../components/Loader';
import { displayError } from '../../../lib/helpers';
import { dashIfEmpty } from '../../../lib/filters';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';
import { mapActions, mapGetters } from 'vuex';

const formName = 'editDiscountProfile';

export default {
	components: {
		ContentHeader,
		Loader,
		OfsPanel,
		OfsFeatureButton,
		DefaultLayout,
		OfFormInput,
		OfSubmitButton,
		DiscountProfileEntryModal
	},
	filters: {
		dashIfEmpty
	},
	mixins: [isFulfilmentAdminMixin, withForm(formName)],
	data() {
		return {
			isLoading: false,
			entry: null,
			shouldShowEntryModal: false,
			discountProfileEntry: [
				{ key: 'fee', label: this.$t('Fee') },
				{ key: 'entryType', label: this.$t('Type') },
				{
					key: 'rate',
					label: this.$t('Rate'),
					formatter: value => (_.isNumber(value) ? value : '-')
				},
				{ key: 'actions', label: ' ' }
			]
		};
	},
	computed: {
		...mapGetters({
			discountProfile: 'discountProfile/discountProfile'
		}),
		id() {
			return this.$route.params.id;
		},
		pageTitle() {
			return this.id ? this.$t(this.discountProfile?.name) : this.$t('New Discount Profile');
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Discount Profiles'),
					to: {
						name: 'admin.discountProfiles'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		validationRules() {
			let rules = {
				formData: {
					name: {
						required,
						maxLength: validateWithMessage(
							this.$t('Please enter a name with less than 255 characters'),
							maxLength(255)
						)
					}
				}
			};

			return rules;
		},
		filledEntries() {
			return _.filter(this.formData.entries, entry => _.isNumber(entry.rate));
		}
	},
	watch: {
		$route: 'fetchData',
		id: 'fetchData',
		discountProfile: {
			immediate: true,
			handler(value) {
				// If the discount profile updates, update the form data
				this.setFormData(value);
			}
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			findDiscountProfile: 'discountProfile/findById',
			updateDiscountProfile: 'discountProfile/update',
			createDiscountProfile: 'discountProfile/create',
			updateFormSummary: 'form/updateFormSummary'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				if (this.id) {
					await this.findDiscountProfile({
						id: this.id,
						query: {
							query: {
								$populate: {
									path: 'entries',
									$limit: 1000,
									$populate: {
										path: 'feeId'
									}
								}
							}
						}
					});
				}
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t('Unable to load discount profile') });
				this.$router.push({ name: 'admin.discountProfiles' });
			} finally {
				this.isLoading = false;
			}
		},
		async save() {
			try {
				const data = {
					...this.formData,
					entries: this.formData.entries?.map(entry => ({ ...entry, feeId: entry.feeId?._id ?? entry.feeId }))
				};
				if (this.id) {
					await this.updateDiscountProfile({
						id: this.id,
						data
					});
					this.$notify({ type: 'success', text: this.$t('Discount Profile updated successfully') });
					this.$router.push({ name: 'admin.discountProfiles' });
				} else {
					const { _id } = await this.createDiscountProfile(data);
					this.$notify({ type: 'success', text: this.$t('Discount Profile created successfully') });
					this.$router.push({ name: 'admin.discountProfiles.view', params: { id: _id } });
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		},
		editEntry(entry) {
			this.entry = entry;
			this.shouldShowEntryModal = true;
		},
		async removeEntry(id) {
			const confirm = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to delete this entry?'), {
				title: this.$t('Delete Entry?'),
				size: 'md',
				okTitle: this.$t('Yes'),
				cancelTitle: this.$t('No')
			});

			if (confirm) {
				this.updateFormData({
					entries: this.formData.entries.map(entry => {
						return entry._id === id ? { ...entry, rate: null } : entry;
					})
				});
			}
		},
		async addEntry() {
			this.entry = null;
			this.shouldShowEntryModal = true;
		},
		saveEntry(entry) {
			const newEntries = this.formData.entries.map(e => {
				if (e.entryType !== entry.entryType) {
					return e;
				}
				if (e.entryType === 'fee' && e.feeId?._id !== entry.feeId) {
					return e;
				}
				return { ...e, rate: entry.rate };
			});
			this.updateFormData({
				entries: newEntries
			});
		},
		closeEntryModal(reload) {
			this.shouldShowEntryModal = false;
			this.entry = null;

			if (reload) {
				this.fetchData();
			}
		}
	}
};
</script>
