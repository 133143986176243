<template>
	<default-layout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<content-header
				:title="$t('Site Flow Shipment Details')"
				:breadcrumbs="breadcrumbs"
				no-padding
				class="mb-3"
			/>
			<div v-if="status" :class="`FulfilmentView_Status FulfilmentView_Status--${status}`" class="mb-3">
				<ofs-badge :text="getFulfilmentBadgeStatusText(status)" :status="status"></ofs-badge>
				<div class="FulfilmentView_Status_UpdatedAt">
					<span class="FulfilmentView_Status_UpdatedAt_Label">{{ $t('Updated at: ') }}</span>
					<span class="FulfilmentView_Status_UpdatedAt_Value">{{ formatDate(updatedAt) }}</span>
					<span class="FulfilmentView_Status_UpdatedAt_Ago">- {{ updatedAt | fromDate }}</span>
				</div>
				<b-button class="FulfilmentView_Status_RefreshButton" variant="secondary" @click.prevent="fetchData">
					{{ $t('Refresh') }}
				</b-button>
			</div>
			<b-row>
				<b-col>
					<b-row>
						<b-col>
							<p class="FulfilmentItemDetails_DataTableTitle mt-0">{{ $t('Shipment Details') }}</p>
							<b-table
								id="SourceOrderDetailsTable"
								class="FulfilmentView_DataTable"
								stacked
								:fields="shipmentFields"
								:items="[shipment]"
							>
								<template #cell(hpSpecificationId)="{ value }">
									<router-link
										:to="{
											name: 'admin.hp-spec-templates.view',
											params: { id: value }
										}"
									>
										{{ specTemplate.name }}
									</router-link>
								</template>
								<template #cell(trackingNumber)="data">
									<a v-if="data.item.trackingUrl" target="_blank" data-test-id="package-tracking" :href="data.item.trackingUrl">
										{{ data.item.trackingNumber }}
									</a>
									<span v-else-if="data.item.trackingNumber">{{ data.item.trackingNumber }}</span>
									<span v-else>-</span>
								</template>
								<template #cell(shippingDoc)="data">
									<router-link v-if="data.item.shippingDoc" :href="data.item.shippingDoc.downloadUrl">
										{{ $t('View') }}
									</router-link>
									<span v-else>-</span>
								</template>
							</b-table>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<p class="FulfilmentItemDetails_DataTableTitle">{{ $t('Destination Address') }}</p>
							<ol v-if="shipment && shipment.shipTo" class="FulfilmentAddress">
								<li
									v-if="shipment.shipTo.name"
									class="FulfilmentAddress__line"
									v-text="shipment.shipTo.name"
								/>
								<li
									v-if="shipment.shipTo.companyName"
									class="FulfilmentAddress__line"
									v-text="shipment.shipTo.companyName"
								/>
								<li
									v-if="shipment.shipTo.address1"
									class="FulfilmentAddress__line"
									v-text="shipment.shipTo.address1"
								/>
								<li
									v-if="shipment.shipTo.address2"
									class="FulfilmentAddress__line"
									v-text="shipment.shipTo.address2"
								/>
								<li
									v-if="shipment.shipTo.address3"
									class="FulfilmentAddress__line"
									v-text="shipment.shipTo.address3"
								/>
								<li
									v-if="shipment.shipTo.town"
									class="FulfilmentAddress__line"
									v-text="shipment.shipTo.town"
								/>
								<li
									v-if="shipment.shipTo.postcode"
									class="FulfilmentAddress__line"
									v-text="shipment.shipTo.postcode"
								/>
								<li
									v-if="shipment.shipTo.country"
									class="FulfilmentAddress__line"
									v-text="shipment.shipTo.country"
								/>
								<li
									v-if="shipment.shipTo.email"
									class="FulfilmentAddress__line"
									v-text="shipment.shipTo.email"
								/>
								<li
									v-if="shipment.shipTo.phone"
									class="FulfilmentAddress__line"
									v-text="shipment.shipTo.phone"
								/>
							</ol>
							<span v-else>-</span>
						</b-col>
						<b-col>
							<p class="FulfilmentItemDetails_DataTableTitle">{{ $t('Return Address') }}</p>
							<ol v-if="shipment && shipment.returnAddress" class="FulfilmentAddress">
								<li
									v-if="shipment.returnAddress.name"
									class="FulfilmentAddress__line"
									v-text="shipment.returnAddress.name"
								/>
								<li
									v-if="shipment.returnAddress.companyName"
									class="FulfilmentAddress__line"
									v-text="shipment.returnAddress.companyName"
								/>
								<li
									v-if="shipment.returnAddress.address1"
									class="FulfilmentAddress__line"
									v-text="shipment.returnAddress.address1"
								/>
								<li
									v-if="shipment.returnAddress.address2"
									class="FulfilmentAddress__line"
									v-text="shipment.returnAddress.address2"
								/>
								<li
									v-if="shipment.returnAddress.address3"
									class="FulfilmentAddress__line"
									v-text="shipment.returnAddress.address3"
								/>
								<li
									v-if="shipment.returnAddress.town"
									class="FulfilmentAddress__line"
									v-text="shipment.returnAddress.town"
								/>
								<li
									v-if="shipment.returnAddress.postcode"
									class="FulfilmentAddress__line"
									v-text="shipment.returnAddress.postcode"
								/>
								<li
									v-if="shipment.returnAddress.country"
									class="FulfilmentAddress__line"
									v-text="shipment.returnAddress.country"
								/>
								<li
									v-if="shipment.returnAddress.email"
									class="FulfilmentAddress__line"
									v-text="shipment.returnAddress.email"
								/>
								<li
									v-if="shipment.returnAddress.phone"
									class="FulfilmentAddress__line"
									v-text="shipment.returnAddress.phone"
								/>
							</ol>
							<span v-else>-</span>
						</b-col>
					</b-row>
				</b-col>
				<b-col>
					<b-tabs>
						<b-tab :title="$t('Packages')" class="pt-2">
							<template v-if="showPackagesDetails">
								<fulfilment-package
									v-for="(p, i) in shipment.packages"
									:key="p.packageId"
									class="mt-2"
									:shipment-package="p"
									:expanded="i === 0"
								/>
							</template>
							<p v-else class="text-center p-3">
								{{ $t('There are no Packages associated with this Shipment') }}
							</p>
						</b-tab>
						<b-tab :title="$t('Items')">
							<b-table
								v-if="showItemsDetails"
								class="mt-3"
								:fields="itemFields"
								:items="shipment.items || []"
							/>
							<p v-else class="text-center p-3">
								{{ $t('There are no Items associated with this Shipment') }}
							</p>
						</b-tab>
					</b-tabs>
				</b-col>
			</b-row>
		</ofs-panel>
	</default-layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfsPanel, OfsBadge, ContentHeader } from '@workflow-solutions/ofs-vue-layout';
import { dateFormatMixin } from '../../../mixins/dateFormatMixin';
import { getFulfilmentBadgeStatusText } from '../../../lib/helpers';
import DefaultLayout from '../../../components/DefaultLayout';
import FulfilmentPackage from './FulfilmentPackage.vue';
import Loader from '../../../components/Loader.vue';

export default {
	components: {
		DefaultLayout,
		Loader,
		OfsBadge,
		OfsPanel,
		ContentHeader,
		FulfilmentPackage
	},
	mixins: [dateFormatMixin()],
	data() {
		return {
			isLoading: true,
			itemFields: [
				{
					label: this.$t('Source Item ID'),
					key: 'sourceItemId'
				},
				{
					label: this.$t('Quantity'),
					key: 'quantity'
				}
			],
			shipmentFields: [
				{
					label: this.$t('Site Flow Shipping ID'),
					key: '_id'
				},
				{
					label: this.$t('Tracking No.'),
					key: 'trackingNumber'
				},
				{
					label: this.$t('Carrier'),
					key: 'carrier.code'
				},
				{
					label: this.$t('Carrier Service'),
					key: 'carrier.service'
				},
				{
					label: this.$t('Shipping Doc URL'),
					key: 'shippingDoc'
				}
			]
		};
	},
	computed: {
		...mapGetters({
			fulfilment: 'fulfilment/fulfilment',
			shipment: 'shipment/shipment'
		}),
		id() {
			return this.$route.params.id;
		},
		shipmentId() {
			return this.$route.params.shipmentId;
		},
		status() {
			return this?.shipment?.status;
		},
		updatedAt() {
			return this?.shipment?.updatedAt;
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Fulfilments'),
					to: {
						name: 'admin.fulfilments'
					}
				},
				{
					text: `${this.$t('Fulfilment')} ${_.get(this.fulfilment, 'siteFlowSourceOrderId')}`,
					to: {
						name: 'admin.fulfilments.view',
						params: { id: this.id }
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		showPackagesDetails() {
			return this.shipment?.packages?.length;
		},
		showItemsDetails() {
			return this.shipment?.items?.length;
		}
	},
	watch: {
		shipmentId: {
			immediate: true,
			async handler() {
				if (this.shipmentId) {
					await this.fetchData();
				}
			}
		}
	},
	methods: {
		...mapActions({
			findFulfilment: 'fulfilment/findById',
			findShipment: 'shipment/findByIdInAccount'
		}),
		getFulfilmentBadgeStatusText,
		async fetchData() {
			this.isLoading = true;
			try {
				await this.findFulfilment({ id: this.id });
				await this.findShipment({
					id: this.shipmentId,
					accountId: this.fulfilment.siteFlowOrderDestination.id
				});
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				this.$router.push({ name: 'admin.fulfilments' });
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>

<style lang="scss">
.FulfilmentAddress {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;

	&__line {
		margin: 0;
		padding: 0;
	}
}
</style>
