<template>
	<DefaultLayout>
		<ofs-panel>
			<section v-if="isLoading">
				<Loader />
			</section>
			<list-table
				v-else
				:table-title="pageTitle"
				:items="tariffs"
				:fields="fields"
				:total-items="tariffsCount"
				:per-page="perPage"
				:current-page="currentPage"
				:is-busy="isBusy"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="itemClicked"
				@table-change="handleTableChange"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<b-button variant="primary" :to="{ name: 'admin.tariffs.create' }">
						{{ $t('New HS Code') }}
					</b-button>
				</template>
				<template v-slot:cell(updatedAt)="{ item }">
					{{ item.updatedAt | formatDate }}
				</template>
				<template v-slot:cell(actions)="{ item }">
					<b-dropdown no-caret size="sm" data-test-id="tariffActions">
						<template slot="button-content">
							<font-awesome-icon icon="ellipsis-h" />
						</template>
						<b-dropdown-item @click="duplicateItem(item)">
							{{ $t('Duplicate') }}
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</list-table>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { OfsPanel, ListTable } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import { displayError } from '../../lib/helpers';
import isFulfilmentAdminMixin from '../../mixins/isFulfilmentAdminMixin';
import tableMixin from '../../mixins/tableMixin';

export default {
	filters: {
		formatDate(date) {
			return moment(date).format('ddd, Do MMM, YYYY');
		}
	},
	components: {
		DefaultLayout,
		OfsPanel,
		Loader,
		ListTable
	},
	mixins: [isFulfilmentAdminMixin, tableMixin],
	data() {
		return {
			pageTitle: this.$t('HS Codes'),
			sort: {
				name: 1
			},
			isLoading: false,
			isBusy: false,
			fields: [
				{ key: 'name', label: this.$t('Name') },
				{ key: 'hsCode', label: this.$t('HS Code') },
				{ key: 'updatedAt', label: this.$t('Last Modified') },
				{ key: 'actions', label: this.$t(''), tdClass: 'text-right' }
			]
		};
	},
	computed: {
		...mapGetters({
			tariffs: 'tariff/tariffs',
			tariffsCount: 'tariff/count'
		})
	},
	async mounted() {
		this.isLoading = true;
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			findTariffs: 'tariff/find'
		}),
		async fetchData() {
			try {
				const query = {
					query: {
						$limit: this.perPage,
						$skip: this.perPage * (this.currentPage - 1),
						$sort: this.sort
					}
				};

				this.isBusy = true;
				await this.findTariffs({
					query
				});
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		itemClicked(item) {
			this.$router.push({ name: 'admin.tariffs.view', params: { id: item._id } });
		},
		async duplicateItem(item) {
			const { name, taxes } = item;
			const duplicateTariff = {
				name: `${name} duplicate`,
				hsCode: '',
				taxes
			};

			this.$router.push({ name: 'admin.tariffs.create', params: { duplicateTariff } });
		}
	}
};
</script>

<style></style>
