import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'controlCentreAccount',
		basePath: 'accounts',
		pluralName: 'accounts',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'total' // -> `total` in the response payload
				}
			}
		},
		transport: 'controlCentreApi',
		actions: {
			async updateAccount({ dispatch }, { id, data }) {
				return dispatch('request', {
					method: 'PUT',
					path: `accounts/${id}`,
					payload: data
				});
			}
		}
	});
}
