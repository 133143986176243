import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'currencyConversionRate',
		pluralName: 'currencyConversionRates',
		transport: 'fulfilmentApi',
		options: {
			actions: {
				find: {
					dataProp: 'data',
					countProp: 'count'
				}
			}
		},
		state: {},
		getters: {},
		actions: {},
		mutations: {}
	});
}
