var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',[(_vm.isLoading)?_c('ofs-panel',[_c('Loader')],1):_c('ofs-panel',{staticClass:"FulfilmentItemDetails"},[_c('content-header',{staticClass:"mb-3",attrs:{"title":_vm.pageTitle,"breadcrumbs":_vm.breadcrumbs,"no-padding":""}},[(_vm.itemStatus === 'error')?_c('b-btn',{attrs:{"variant":"primary"},on:{"click":_vm.refetch}},[_vm._v(_vm._s(_vm.$t('Refetch')))]):_vm._e()],1),_c('section',{staticClass:"FulfilmentItemDetails_container"},[_c('div',{class:("FulfilmentItemDetails_Status FulfilmentItemDetails_Status--" + _vm.itemStatus)},[_c('ofs-badge',{attrs:{"text":_vm.getFulfilmentBadgeStatusText(_vm.itemStatus),"status":("" + _vm.itemStatus)}}),_c('div',{staticClass:"FulfilmentItemDetails_Status_UpdatedAt"},[_c('span',{staticClass:"FulfilmentItemDetails_Status_UpdatedAt_Label"},[_vm._v(_vm._s(_vm.$t('Updated at: ')))]),_c('span',{staticClass:"FulfilmentItemDetails_Status_UpdatedAt_Value"},[_vm._v(_vm._s(_vm.formatDate(_vm.updatedAt)))]),_c('span',{staticClass:"FulfilmentItemDetails_Status_UpdatedAt_Ago"},[_vm._v("- "+_vm._s(_vm._f("fromDate")(_vm.updatedAt)))])]),_c('b-button',{staticClass:"FulfilmentItemDetails_Status_RefreshButton",attrs:{"variant":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.fetchData($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Refresh'))+" ")])],1),_c('b-row',[_c('b-col',[_c('div',[_c('p',{staticClass:"FulfilmentItemDetails_DataTableTitle"},[_vm._v(_vm._s(_vm.$t('Source order details')))]),_c('b-table',{staticClass:"FulfilmentItemDetails_DataTable",attrs:{"id":"SourceOrderDetailsTable","stacked":"","fields":_vm.sourceOrderDetailsTableFields,"items":[_vm.item]},scopedSlots:_vm._u([{key:"cell(hpSpecificationId)",fn:function(ref){
var value = ref.value;
return [_c('router-link',{attrs:{"to":{
										name: 'admin.hp-spec-templates.view',
										params: { id: value }
									}}},[_vm._v(" "+_vm._s(_vm.specTemplate.name)+" ")])]}},{key:"cell(siteflowSKU)",fn:function(){return [_vm._v(" "+_vm._s(_vm.siteflowSKU)+" ")]},proxy:true},{key:"cell(status)",fn:function(){return [_c('ofs-badge',{attrs:{"text":_vm.getFulfilmentBadgeStatusText(_vm.sourceOrder.status),"status":_vm.sourceOrder.status}})]},proxy:true}])})],1),_c('div',[_c('p',{staticClass:"FulfilmentItemDetails_DataTableTitle"},[_vm._v(_vm._s(_vm.$t('Fulfilment Details')))]),_c('b-table',{staticClass:"FulfilmentItemDetails_DataTable",attrs:{"id":"FulfilmentDetailsTable","stacked":"","fields":_vm.fulfilmentDetailsTableFields,"items":[_vm.fulfilmentDetails]},scopedSlots:_vm._u([{key:"cell(siteFlowSourceItemId)",fn:function(){return [_vm._v(" "+_vm._s(_vm.fulfilmentItem.siteFlowSourceItemId)+" ")]},proxy:true},{key:"cell(status)",fn:function(ref){
									var value = ref.value;
return [_c('ofs-badge',{attrs:{"text":_vm.getFulfilmentBadgeStatusText(value),"status":value}})]}}])})],1)]),_c('b-col',[_c('div',[_c('p',{staticClass:"FulfilmentItemDetails_DataTableTitle"},[_vm._v(_vm._s(_vm.$t('Component Details')))]),_vm._l((_vm.item.components),function(component){return _c('b-table',{key:component.id,staticClass:"FulfilmentItemDetails_DataTable",attrs:{"id":"ComponentDetailsTable","stacked":"","fields":_vm.componentDetailsTableFields,"items":[component]},scopedSlots:_vm._u([{key:"table-caption",fn:function(){return [_vm._v(_vm._s(_vm._f("formatComponentCode")(component.code)))]},proxy:true},{key:"cell(url)",fn:function(ref){
									var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.$t('View')))])]}},{key:"cell(specCheck)",fn:function(ref){
									var item = ref.item;
return [_c('ofs-badge',{attrs:{"text":_vm.getBadgeStatusText(_vm.specCheckStatus(item)),"status":_vm.specCheckStatus(item)}}),(_vm.specCheckStatus(item) === 'error')?_c('span',{staticClass:"FulfilmentItemDetails_DataTable_ErrorLink",on:{"click":function($event){return _vm.openErrorReportModal(item)}}},[_vm._v(" "+_vm._s(_vm.$t('Error report'))+" ")]):_vm._e()]}}],null,true)})})],2)])],1),_c('b-modal',{attrs:{"id":"ErrorReportModal","title":_vm.$t('Error Report'),"size":"lg"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div')]},proxy:true}])},[_c('div',[_c('b-table',{attrs:{"id":"FulfilmentItemDetails_ErrorModalTable","fields":_vm.errorModalTableFields,"items":_vm.errorModalData.errorList},scopedSlots:_vm._u([{key:"cell(description)",fn:function(ref){
									var value = ref.value;
return [_c('div',{staticClass:"FulfilmentItemDetails_ErrorModalTable_ErrorDescription"},[_vm._v(_vm._s(value))]),(_vm.errorModalData.reportUrl)?_c('div',{staticClass:"FulfilmentItemDetails_ErrorModalTable_ErrorLink"},[_c('a',{attrs:{"href":_vm.errorModalData.reportUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.errorModalData.reportUrl)+" ")])]):_vm._e()]}}])})],1)])],1)],1),_c('refetch-modal',{attrs:{"components":_vm.item.components,"account-id":_vm.fulfilment.accountId,"item-id":_vm.item.id,"show":_vm.shouldShowRefetchModal,"on-close":_vm.closeRefetchModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }