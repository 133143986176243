<template>
	<DefaultLayout>
		<ofs-panel>
			<section v-if="isLoading">
				<Loader />
			</section>
			<list-table
				v-else
				:table-title="$t('Accounts')"
				:items="accounts"
				:fields="fields"
				:total-items="accountCount"
				:per-page="perPage"
				:current-page="currentPage"
				:is-busy="isBusy"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="itemClicked"
				@table-change="handleTableChange"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template slot="TableHeader" slot-scope="{}">
					<OfFilterBar
						:filters="filters"
						:reset-label="$t('Reset Filters')"
						:values="queryFilters"
						@change="filtersChanged"
					/>
				</template>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<div class="TableSearch" :class="{ 'TableSearch--show-clear': search }">
						<font-awesome-icon class="TableSearch-icon" icon="search" />
						<b-form-input
							v-model="search"
							v-focus
							:placeholder="$t('Search by Account')"
							data-test-identifier="account-search-input"
							class="TableSearch-input"
							@input="doSearch()"
						/>
						<div class="TableSearch-clear">
							<font-awesome-icon icon="times-circle" @click="clearSearch()" />
						</div>
					</div>
					<OfInlineFilter
						:filters="filters"
						:label="$t('Filter')"
						:reset-label="$t('Reset Filters')"
						:values="queryFilters"
						class="ml-1"
						@change="filtersChanged"
					/>
				</template>
				<template v-slot:cell(settings.isoCountry)="data">
					{{ getCountryLabel(data.item.settings.isoCountry) }}
				</template>
				<template v-slot:cell(status)="data">
					{{ ucFirst(data.item.status) }}
				</template>
				<template v-slot:cell(settings.companyName)="data">
					{{ data.item.settings.companyName || '-' }}
				</template>
				<template v-slot:cell(settings.psp)="data">
					{{ data.item.settings.psp === true ? $t('PSP') : $t('Brand') }}
				</template>
			</list-table>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import { OfsPanel, ListTable, OfFilterBar, OfInlineFilter } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import { displayError } from '../../lib/helpers';
import isCustomerServiceTeamMixin from '../../mixins/isCustomerServiceTeamMixin';
import tableMixin from '../../mixins/tableMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		OfFilterBar,
		OfInlineFilter,
		Loader,
		ListTable
	},
	mixins: [isCustomerServiceTeamMixin, tableMixin],
	data() {
		return {
			sort: {
				name: 1
			},
			search: null,
			countries: [],
			isLoading: false,
			isBusy: false,
			fields: [
				{ key: '_id', label: this.$t('Account ID') },
				{ key: 'name', label: this.$t('Account') },
				{ key: 'settings.companyName', label: this.$t('Company Name') },
				{ key: 'settings.psp', label: this.$t('Type') },
				{ key: 'settings.isoCountry', label: this.$t('Country') },
				{ key: 'status', label: this.$t('Status') }
			]
		};
	},
	computed: {
		...mapGetters({
			accounts: 'controlCentreAccount/accounts',
			accountCount: 'controlCentreAccount/count'
		}),
		filters() {
			return [
				{
					header: this.$t('Status'),
					key: 'status',
					type: 'checkbox',
					items: [
						{ title: this.$t('Active'), value: 'active' },
						{ title: this.$t('Inactive'), value: 'inactive' },
						{ title: this.$t('Demo'), value: 'demo' },
						{ title: this.$t('Test'), value: 'test' }
					]
				},
				{
					header: this.$t('Type'),
					key: 'settings.psp',
					type: 'radio',
					items: [
						{ title: this.$t('PSP'), value: true },
						{ title: this.$t('Brand'), value: false }
					]
				},
				{
					header: this.$t('Countries'),
					key: 'settings.isoCountry',
					type: 'checkbox',
					items: _.map(this.countryOptions, ({ text, value }) => ({ title: text, value }))
				}
			];
		},
		countryOptions() {
			return _.map(this.countries, country => ({
				text: country.name,
				value: country['alpha-2']
			}));
		}
	},
	async mounted() {
		this.countries = require('../../../public/data/iso.countries.json');

		this.isLoading = true;
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			findAccounts: 'controlCentreAccount/find'
		}),
		async fetchData() {
			try {
				const query = {
					query: {
						$limit: this.perPage,
						$skip: this.perPage * (this.currentPage - 1),
						$sort: this.sort
					}
				};

				this.setQueryWhereFromQueryFilters(query);

				if (this.search) {
					_.set(query, 'query.$where.search', this.search);
				}

				this.isBusy = true;
				this.updateUrl({ filter: this.filter, queryFilters: this.queryFilters });
				await this.findAccounts({ query });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		getCountryLabel(code) {
			return _.get(_.find(this.countries, { 'alpha-2': code }), 'name');
		},
		itemClicked(item) {
			this.$router.push({ name: 'accounts.edit', params: { id: item._id } });
		},
		filtersChanged(queryFilters) {
			this.updateUrl({
				currentPage: this.firstPage,
				perPage: this.minPerPage,
				queryFilters,
				filter: this.filter
			});
		},
		doSearch: _.debounce(function doSearch() {
			this.updateUrl({
				currentPage: this.firstPage
			});
			this.fetchData();
		}, 1000),
		clearSearch() {
			this.search = null;
			this.doSearch();
		},
		ucFirst(str) {
			return _.capitalize(str);
		}
	}
};
</script>

<style></style>
