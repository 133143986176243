<template>
	<DefaultLayout>
		<OfsPanel>
			<ListTable
				:table-title="$t('Product Types')"
				:config="config"
				bordered
				:items="productTypes"
				:current-page="currentPage"
				:fields="fields"
				:total-items="productTypesCount"
				:per-page="perPage"
				:is-busy="isBusy"
				:fetch-data="getData"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="handleRowClick"
				@table-change="handleTableChange"
			>
				<template #cell(componentCodes)="row">
					<span v-for="component in row.item.componentCodes" :key="`${row.item.name}-${component}`">
						<span class="component">{{ component }}</span>
					</span>
				</template>
				<template slot="empty" slot-scope="{}">
					<span>
						<i>{{ $t('No data') }}</i>
					</span>
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
			</ListTable>
		</OfsPanel>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ListTable, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import tableMixin from '../../mixins/tableMixin';
import navigationMixin from '../../mixins/navigationMixin';

export default {
	name: 'ProductTypeList',
	components: {
		ListTable,
		OfsPanel,
		DefaultLayout
	},
	mixins: [tableMixin, navigationMixin],
	data() {
		return {
			isLoading: false,
			isBusy: false,
			fields: [
				{ key: 'name', label: this.$t('Name') },
				{ key: 'componentCodes', label: this.$t('Components') }
			],
			config: {
				search: { visible: false },
				breadcrumbs: { visible: false },
				refresh: { visible: true, title: this.$t('Refresh') },
				add: {
					visible: true,
					href: { name: 'productType.add' },
					title: this.$t('Add New')
				}
			}
		};
	},
	computed: {
		...mapGetters({
			productTypesCount: 'productCategory/count',
			productTypes: 'productCategory/productCategories'
		})
	},
	mounted() {
		this.updateUrl({
			currentPage: this.firstPage
		});
	},
	methods: {
		...mapActions({
			getProductTypes: 'productCategory/find'
		}),
		async getData() {
			await this.throttledFetchData();
		},
		async fetchData() {
			try {
				const query = {
					query: {
						$limit: this.perPage,
						$skip: this.perPage * (this.currentPage - 1),
						$sort: this.sort
					}
				};
				this.isBusy = true;
				await this.getProductTypes({
					query
				});
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		handleRowClick(item) {
			this.goTo({ name: 'productType.edit', params: { id: item._id } });
		}
	}
};
</script>

<style lang="scss" scoped>
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.component {
	background: $of-color-highlights;
	color: $of-color-grey-1;
	font-size: $of-font-size-small;
	line-height: $of-font-size-x-small;
	padding: 4px 7px;
	margin: 2px;
	border-radius: $of-border-radius;
	display: inline-flex;
}
</style>
