<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3" />
			<b-form>
				<b-row>
					<b-col lg="8">
						<b-row>
							<b-col lg="6">
								<of-form-input name="displayName" data-test-id="displayName" :label="$t('Name')" />
							</b-col>
							<b-col lg="6">
								<of-multi-select
									name="pricePer"
									class="mb-0"
									:label="$t('Type')"
									:horizontal="false"
									:allow-clear="false"
									data-test-id="pricePer"
									track-by="value"
									:options="typeOptions"
									:searchable="false"
									:placeholder="$t('Fee Type')"
								/>
							</b-col>
						</b-row>
						<b-row>
							<b-col lg="6">
								<of-form-input name="code" data-test-id="code" :label="$t('Code')" />
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<template v-if="id">
					<hr class="form-divider" />
					<h3 class="form-header">
						{{ $t('Create a new fee bloc and select the countries and currency it will cover.') }}
					</h3>
					<b-row>
						<b-col lg="8">
							<b-table
								:fields="feePriceFields"
								:items="feePrices"
								class="mb-3"
								hover
								@row-clicked="editBloc"
							>
								<template #cell(countryIsoCodes)="{ item }">
									{{ formatCountries(item.countryIsoCodes) || '-' }}
								</template>
								<template #cell(actions)="{ item }">
									<a
										class="d-flex justify-content-end align-items-center"
										data-test-id="removeBloc"
										@click="removeBloc(item._id)"
									>
										<icon name="times-circle" />
									</a>
								</template>
							</b-table>
						</b-col>
					</b-row>
					<b-row>
						<b-col lg="8">
							<ofs-feature-button
								type="add"
								:label="
									formData.grades && formData.grades.length
										? $t('Add another bloc')
										: $t('Add a bloc')
								"
								@click="addBloc"
							/>
						</b-col>
					</b-row>
				</template>
			</b-form>
			<template slot="actions">
				<of-submit-button @click.prevent="save">{{ $t('Save') }}</of-submit-button>
			</template>
		</ofs-panel>
		<fee-price-modal
			v-if="fee"
			:fee-price="feePrice"
			:fee="fee"
			:show="shouldShowFeePriceModal"
			:on-close="closeFeePriceModal"
		/>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	ContentHeader,
	OfsPanel,
	OfsFeatureButton,
	OfFormInput,
	OfMultiSelect,
	OfSubmitButton,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { required, maxLength } from 'vuelidate/lib/validators';
import DefaultLayout from '../../../components/DefaultLayout';
import FeePriceModal from './FeePriceModal';
import Loader from '../../../components/Loader';
import { displayError, valueToInt, valueToFloat } from '../../../lib/helpers';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';
import { mapActions, mapGetters } from 'vuex';

const formName = 'editFee';

export default {
	components: {
		ContentHeader,
		Loader,
		OfsPanel,
		OfsFeatureButton,
		DefaultLayout,
		OfFormInput,
		OfMultiSelect,
		OfSubmitButton,
		FeePriceModal
	},
	mixins: [isFulfilmentAdminMixin, withForm(formName)],
	data() {
		return {
			isLoading: false,
			feePrice: null,
			shouldShowFeePriceModal: false,
			feePriceFields: [
				{ key: 'name', label: this.$t('Bloc Name') },
				{ key: 'currencyIsoCode', label: this.$t('Currency') },
				{ key: 'netAmount', label: this.$t('Amount') },
				{ key: 'countryIsoCodes', label: this.$t('Countries Included') },
				{ key: 'actions', label: ' ' }
			]
		};
	},
	computed: {
		...mapGetters({
			fee: 'fee/fee',
			feePrices: 'feePrice/feePrices',
			countries: 'country/countries',
			currencies: 'currency/currencies'
		}),
		id() {
			return this.$route.params.id;
		},
		pageTitle() {
			return this.id ? this.$t('Edit Fee') : this.$t('New Fee');
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Fees'),
					to: {
						name: 'admin.fees'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		typeOptions() {
			return [
				{
					text: this.$t('Per Order'),
					value: 'order'
				},
				// @todo - Per Page is currently out of scope
				// {
				// 	text: this.$t('Per Page'),
				// 	value: 'page'
				// },
				{
					text: this.$t('Per Item'),
					value: 'item'
				},
				{
					text: this.$t('Per Copy'),
					value: 'copy'
				}
			];
		},
		validationRules() {
			const pricePerOptions = ['copy', 'page', 'item', 'order'];
			const validatePricePer = value => {
				return pricePerOptions.includes(value);
			};

			let rules = {
				formData: {
					code: {
						required,
						maxLength: maxLength(255)
					},
					displayName: {
						required,
						maxLength: maxLength(255)
					},
					pricePer: {
						required,
						validatePricePer
					}
				}
			};

			return rules;
		}
	},
	watch: {
		$route: 'fetchData',
		id: 'fetchData',
		fee: {
			immediate: true,
			handler(value) {
				// If the fee updates, update the form data
				this.setFormData(value);
			}
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			findFee: 'fee/findById',
			findFeePrices: 'feePrice/findAll',
			deleteFeePrice: 'feePrice/deleteById',
			findCountries: 'country/findAll',
			findCurrencies: 'currency/findAll',
			updateFee: 'fee/update',
			createFee: 'fee/create',
			updateFormSummary: 'form/updateFormSummary'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				if (this.id) {
					await this.findFee({ id: this.id });
					await this.findFeePrices({
						query: {
							query: {
								$where: {
									feeId: this.id
								}
							}
						}
					});
					await this.findCountries();
					await this.findCurrencies();
				} else {
					// If a new fee, initialise the form
					this.initFormData({
						pricePer: 'copy'
					});
				}
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t('Unable to load fee') });
				this.$router.push({ name: 'admin.fees' });
			} finally {
				this.isLoading = false;
			}
		},
		async save() {
			try {
				if (this.id) {
					await this.updateFee({
						id: this.id,
						data: this.formData
					});
					this.$notify({ type: 'success', text: this.$t('Fee updated successfully') });
					this.$router.push({ name: 'admin.fees' });
				} else {
					const { _id } = await this.createFee(this.formData);
					this.$notify({ type: 'success', text: this.$t('Fee created successfully') });
					this.$router.push({ name: 'admin.fees.view', params: { id: _id } });
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		},
		editBloc(feePrice) {
			this.feePrice = feePrice;
			this.shouldShowFeePriceModal = true;
		},
		async removeBloc(id) {
			const confirm = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to delete this Bloc?'), {
				title: this.$t('Delete Bloc?'),
				size: 'md',
				okTitle: this.$t('Yes'),
				cancelTitle: this.$t('No')
			});

			if (confirm) {
				await this.deleteFeePrice({ id });
				await this.fetchData();
			}
		},
		async addBloc() {
			this.feePrice = null;
			this.shouldShowFeePriceModal = true;
		},
		closeFeePriceModal(reload) {
			this.shouldShowFeePriceModal = false;
			this.feePrice = null;

			if (reload) {
				this.fetchData();
			}
		},
		formatCountries(countryCodes) {
			return _.filter(this.countries, c => _.includes(countryCodes, c.id))
				.map(c => c.name)
				.join(', ');
		}
	}
};
</script>
