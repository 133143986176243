import TransformerList from './TransformerList';
import TransformerAddEdit from './TransformerAddEdit';

const routes = [
	{
		path: '/transformer',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'transformer.list',
				path: 'list',
				component: TransformerList
			},
			{
				name: 'transformer.edit',
				path: 'edit/:id',
				component: TransformerAddEdit
			},
			{
				name: 'transformer.add',
				path: 'add',
				component: TransformerAddEdit
			}
		]
	}
];

export default routes;
