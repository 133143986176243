<template>
	<codemirror v-model="code" :options="editorOptions" />
</template>

<script>
import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/theme/rubyblue.css';

export default {
	components: {
		codemirror
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		options: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			defaultOptions: {
				autoRefresh: true,
				autoCloseBrackets: true,
				mode: { name: 'javascript', json: true },
				lineWrapping: true,
				tabSize: 4,
				lineNumbers: true,
				theme: 'rubyblue'
			}
		};
	},
	computed: {
		code: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},
		editorOptions() {
			return { ...this.defaultOptions, ...this.options };
		}
	}
};
</script>
