import MigrationOverview from './Migration/Overview';

const routes = [
	{
		path: '/features',
		name: 'admin.features',
		component: { template: '<router-view />' },
		children: [
			{
				path: 'migration',
				name: 'admin.features.migration.overview',
				component: MigrationOverview
			}
		]
	}
];

export default routes;
