<template>
	<DefaultLayout>
		<ofs-panel class="OverviewPanel">
			<section v-if="isLoading">
				<Loader />
			</section>
			<template v-else>
				<content-header :title="pageTitle" no-padding class="mb-3" />
				<p>This page shows an overview of accounts migrated to use the latest Site Flow section UI's</p>
				<b-table :fields="fields" :items="sectionValues">
					<template v-slot:cell(feature)="row">
						<a href="#" @click.prevent="row.toggleDetails">{{ row.item.feature }}</a>
					</template>
					<template v-slot:cell(accountsEnabled)="row">
						{{ `${row.item.accountsEnabled} of ${Object.keys(accounts).length}` }}
					</template>
					<template v-slot:cell(percentage)="row">
						{{ `${row.item.percentage}%` }}
					</template>
					<template v-slot:cell(defaultValue)="row">
						<ofs-badge :status="`${row.item.defaultValue}`" :text="`${row.item.defaultValue}`" />
					</template>
					<template #row-details="row">
						<b-card class="mb-3" body-class="p-2">
							<h3 class="m-1">Accounts Enabled</h3>
							<router-link
								class="AccountLink"
								:to="{ name: 'accounts.edit', params: { id: account } }"
								v-for="account in row.item.accounts"
								:key="account"
							>
								<ofs-badge v-if="accounts[account]" class="m-1" :text="accounts[account].key" />
							</router-link>
						</b-card>
					</template>
				</b-table>
			</template>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { OfsPanel, ContentHeader, OfsBadge } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import { displayError } from '../../../lib/helpers';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import isCustomerServiceTeamMixin from '../../../mixins/isCustomerServiceTeamMixin';

export default {
	components: {
		DefaultLayout,
		ContentHeader,
		OfsPanel,
		OfsBadge,
		Loader
	},
	mixins: [isCustomerServiceTeamMixin],
	data() {
		return {
			isLoading: false,
			pageTitle: this.$t('Migration Overview'),
			fields: [
				{
					label: 'Feature',
					key: 'feature'
				},
				{
					label: 'Accounts Enabled',
					key: 'accountsEnabled'
				},
				{
					label: 'Default Value',
					key: 'defaultValue'
				},
				{
					label: 'Migration %',
					key: 'percentage'
				}
			],
			sections: [
				{
					label: 'Analytics',
					name: 'siteflow-section-analytics-ui'
				},
				{
					label: 'Order',
					name: 'siteflow-section-orders-ui'
				},
				{
					label: 'Production',
					name: 'siteflow-section-production-ui'
				},
				{
					label: 'Shipping',
					name: 'siteflow-section-shipping-ui'
				},
				{
					label: 'Products',
					name: 'siteflow-section-products-ui'
				},
				{
					label: 'Setup',
					name: 'siteflow-section-setup-ui'
				}
			]
		};
	},
	computed: {
		...mapGetters({
			accounts: 'account/customerAccounts',
			siteflowSections: 'controlCentreFeature/siteflowSections',
			features: 'controlCentreFeature/features',
			feature: 'controlCentreFeature/controlCentreFeature'
		}),
		totalAccounts() {
			return Object.keys(this.accounts || {}).length;
		},
		sectionValues() {
			const sectionValues = [];

			_.each(this.sections, section => {
				const flag = this.siteflowSections[section.name];
				let percentage = 0;
				let accountsEnabled = 0;

				if (flag) {
					if (flag.defaultValue) {
						accountsEnabled = this.totalAccounts - flag.accountsDisabled.length;
						percentage = (accountsEnabled / this.totalAccounts) * 100;
					} else {
						accountsEnabled = flag.accountsEnabled.length - flag.accountsDisabled.length;
						percentage = (accountsEnabled / this.totalAccounts) * 100;
					}

					sectionValues.push({
						feature: section.label,
						defaultValue: flag.defaultValue,
						accountsEnabled: accountsEnabled,
						accounts: flag.accountsEnabled,
						percentage: _.round(percentage, 2).toFixed(2)
					});
				}
			});

			return sectionValues;
		}
	},
	mounted() {
		this.generateOverview();
	},
	methods: {
		...mapActions({
			loadSiteFlowSectionFeatures: 'controlCentreFeature/loadSiteFlowSectionFeatures',
			getAccounts: 'account/getAccounts'
		}),
		async generateOverview() {
			try {
				this.isLoading = true;
				await this.loadSiteFlowSectionFeatures();
				await this.getAccounts();
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.OverviewPanel {
	max-width: 1200px;
}
.AccountLink {
	&:hover {
		text-decoration: none;

		.OfsBadge {
			background-color: $of-color-aqua;
		}
	}
}
</style>
