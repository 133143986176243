<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else>
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3" />
			<b-form>
				<b-row>
					<b-col>
						<of-multi-select
							name="name"
							:label="$t('Name')"
							:horizontal="false"
							:allow-clear="false"
							data-test-id="CountryName"
							label-by="name"
							track-by="name"
							:options="countriesJson"
							:searchable="true"
							:placeholder="$t('Name')"
							:disabled="!!id"
							@input="onNameChange"
						/>
					</b-col>
					<b-col>
						<of-form-input name="isoCode" data-test-id="CountryIsoCode" :label="$t('ISO Code')" disabled />
					</b-col>
					<b-col>
						<of-multi-select
							name="defaultCurrencyIsoCode"
							:label="$t('Default Currency')"
							:horizontal="false"
							:allow-clear="false"
							data-test-id="CountryDefaultCurrency"
							track-by="value"
							:options="currencyOptions"
							:searchable="true"
							:placeholder="$t('Default Currency')"
						/>
					</b-col>
				</b-row>
				<b-row class="ml-0">
					<of-toggle
						name="requireState"
						data-test-id="RequireStateToggle"
						:right-side-label="$t('State required')"
					/>
					<font-awesome-icon
						v-b-tooltip.hover
						class="toggle-info-icon"
						icon="info-circle"
						:title="descriptions.requireState"
					/>
				</b-row>
				<template>
					<hr class="form-divider" />
					<b-row>
						<b-col lg="4">
							<of-multi-select
								name="stateLabel"
								:label="$t('State/Region Label')"
								:horizontal="false"
								:allow-clear="false"
								data-test-id="StateLabel"
								track-by="value"
								:options="stateOptions"
								:placeholder="$t('State/Region')"
								@input="onStateLabelChange"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<h3 class="form-header mt-3">{{ statesTitle }}</h3>
						</b-col>
						<TestExpressions v-if="formData.states && formData.states.length" :states="formData.states" />
					</b-row>
					<b-table :items="formData.states" :fields="stateFields" class="StatesTable mb-3">
						<template #head(displayName)="">
							<span>{{ statesLabelText }}</span>
						</template>
						<template #head(normalisedState)="data">
							<span>{{ data.label }}</span>
							<div class="header-info-tooltip">
								<icon id="normalisedName-description" class="header-info-icon" name="info-circle" />
								<b-tooltip target="normalisedName-description" triggers="hover">
									{{ descriptions.normalisedName }}
								</b-tooltip>
							</div>
						</template>
						<template #head(regularExpressions)="data">
							<span>{{ data.label }}</span>
							<div class="header-info-tooltip">
								<icon id="regularExpressions-description" class="header-info-icon" name="info-circle" />
								<b-tooltip target="regularExpressions-description" triggers="hover">
									<span v-html="descriptions.regularExpressions" />
								</b-tooltip>
							</div>
						</template>
						<template #cell(displayName)="data">
							<of-form-input
								:name="`states[${data.index}].displayName`"
								data-test-id="StatesTableName"
								no-label
							/>
						</template>
						<template #cell(normalisedState)="data">
							<of-form-input
								:name="`states[${data.index}].normalisedState`"
								data-test-id="StatesTableNormalisedState"
								no-label
							/>
						</template>
						<template #cell(regularExpressions)="{ index }">
							<MultiInput
								:name="`states[${index}].regularExpressions`"
								data-test-id="StatesTableRegularExpressions"
								:placeholder="$t('Add expression and press enter')"
								:limit="50"
								:limit-message="$t('You can add up to 50 items')"
								:extra-options="getExtraOptions(index)"
							/>
						</template>
						<template #cell(actions)="{ index }">
							<div
								class="StatesTable-remove"
								data-test-id="StatesTableRemove"
								@click="removeState(index)"
							>
								<icon name="times-circle" />
							</div>
						</template>
					</b-table>
					<ofs-feature-button
						type="add"
						data-test-id="StatesTableAdd"
						:label="featureButtonLabel"
						@click="addState"
					/>
				</template>
			</b-form>
			<template slot="actions">
				<of-submit-button @click.prevent="save">{{ $t('Save') }}</of-submit-button>
			</template>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	ContentHeader,
	OfsPanel,
	OfFormInput,
	OfMultiSelect,
	OfSubmitButton,
	OfToggle,
	OfsFeatureButton,
	withForm
} from '@workflow-solutions/ofs-vue-layout';
import { required, requiredIf } from 'vuelidate/lib/validators';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import { displayError } from '../../../lib/helpers';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';
import { mapActions, mapGetters } from 'vuex';
import MultiInput from './MultiInput';
import TestExpressions from './TestExpressions';

const formName = 'editCountry';

export default {
	components: {
		ContentHeader,
		Loader,
		OfsPanel,
		DefaultLayout,
		OfFormInput,
		OfMultiSelect,
		OfSubmitButton,
		OfToggle,
		OfsFeatureButton,
		MultiInput,
		TestExpressions
	},
	mixins: [isFulfilmentAdminMixin, withForm(formName)],
	data() {
		return {
			isLoading: false,
			defaultStatesValue: 'state',
			statesLabelText: '',
			stateOptions: [
				{ text: this.$t('State'), value: 'state' },
				{ text: this.$t('Province'), value: 'province' },
				{ text: this.$t('Region'), value: 'region' },
				{ text: this.$t('District'), value: 'district' }
			],
			stateFields: [
				{ label: this.$t('State'), key: 'displayName', class: 'col-3' },
				{ label: this.$t('Normalised name'), key: 'normalisedState', class: 'col-3' },
				{ label: this.$t('Regular Expressions'), key: 'regularExpressions', class: 'col-5' },
				{ label: this.$t(''), key: 'actions', class: 'text-right col-1' }
			],
			statesTitle: this.$t('Add states and their related regular expressions for order validation'),
			descriptions: {
				requireState: this.$t('When active, a states list is required to enable order shipTo.state validation'),
				normalisedName: this.$t('Normalised state value will be used as Site Flow order shipTo.state'),
				// eslint-disable-next-line max-len, prettier/prettier
				regularExpressions: this.$t('Click <a href=https://regex101.com/>here</a> for more information on regular expressions syntax')
			}
		};
	},
	computed: {
		...mapGetters({
			country: 'country/country',
			currencies: 'currency/currencies',
			countriesJson: 'country/countriesJson',
			currenciesJson: 'currency/currenciesJson'
		}),
		id() {
			return this.$route.params.id;
		},
		pageTitle() {
			return this.id ? this.$t('Edit Country') : this.$t('New Country');
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Countries'),
					to: {
						name: 'admin.countries'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		currencyOptions() {
			return this.currencies.map(c => ({
				text: c.name,
				value: c._id
			}));
		},
		validationRules() {
			let rules = {
				formData: {
					name: {
						required
					},
					isoCode: {
						required
					},
					defaultCurrencyIsoCode: {
						required
					},
					requireState: {
						required
					},
					states: {
						required: requiredIf(function(formData) {
							return formData.requireState;
						}),
						$each: {
							displayName: {
								required,
								isUnique(value, state) {
									const otherStates = _.filter(this.formData.states, t => t !== state);
									return !_.some(otherStates, { displayName: state.displayName });
								}
							},
							normalisedState: {
								required,
								isUnique(value, state) {
									const otherStates = _.filter(this.formData.states, t => t !== state);
									return !_.some(otherStates, { normalisedState: state.normalisedState });
								}
							},
							regularExpressions: {
								isValid(values = []) {
									return _.every(values, ({ valid }) => valid);
								}
							}
						}
					}
				}
			};

			return rules;
		},
		featureButtonLabel() {
			return this.formData.states && this.formData.states.length
				? this.$t('Add another')
				: this.$t(`Add a ${this.statesLabelText}`);
		}
	},
	watch: {
		$route: 'fetchData',
		id: 'fetchData',
		country: {
			immediate: true,
			handler(value) {
				// If the country updates, update the form data
				this.setFormData(value);
			}
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			findCountry: 'country/findById',
			updateCountry: 'country/update',
			createCountry: 'country/create',
			findCurrencies: 'currency/findAll',
			updateFormSummary: 'form/updateFormSummary',
			getCountriesJson: 'country/getCountriesJson',
			getCurrenciesJson: 'currency/getCurrenciesJson'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				if (!this.countriesJson.length) {
					await this.getCountriesJson();
				}
				if (!this.currenciesJson?.length) {
					await this.getCurrenciesJson();
				}

				const query = {
					query: {
						$sort: { name: 1 }
					}
				};
				await this.findCurrencies({ query });
				if (this.id) {
					await this.findCountry({ id: this.id });
					// Reset regularExpressions as an array of objects
					const states = _.map(this.formData.states, state => {
						const regularExpressions = _.map(state.regularExpressions, value => ({ value, valid: true }));
						return { ...state, regularExpressions };
					});
					this.updateFormData({ states });
				} else {
					// If a new country, initialise the form
					this.initFormData({ requireState: true, stateLabel: this.defaultStatesValue, states: [] });
				}
				this.onStateLabelChange(this.formData.stateLabel);
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t('Unable to load country') });
				this.$router.push({ name: 'admin.countries' });
			} finally {
				this.isLoading = false;
			}
		},
		async save() {
			try {
				// Retrieve regularExpressions values
				const states = _.map(this.formData.states, state => {
					const regularExpressions = _.map(state.regularExpressions, ({ value }) => value);
					return { ...state, regularExpressions };
				});
				const data = { ...this.formData, states };

				if (this.id) {
					await this.updateCountry({
						id: this.id,
						data
					});
					this.$notify({ type: 'success', text: this.$t('Country updated successfully') });
				} else {
					await this.createCountry({
						_id: this.formData.isoCode,
						...data
					});
					this.$notify({ type: 'success', text: this.$t('Country created successfully') });
				}
				this.$router.push({ name: 'admin.countries' });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		},
		onNameChange(name) {
			if (!name || !name.length) {
				return this.setFormData({ name: null, defaultCurrencyIsoCode: null, isoCode: null });
			}
			const country = this.countriesJson.find(c => c.name === name);
			const [currencyCode] = (country?.currencyCodes ?? '').split(',');

			// lookup the currency in the json
			const { code } = currencyCode ? this.currenciesJson.find(c => c.numeric === currencyCode) : {};

			// check if we already have a config for the selected countries' currency
			const currency = this.currencies.find(c => c.isoCode === code);
			const defaultCurrencyIsoCode = _.get(currency, 'isoCode');

			const newData = { ...this.formData, isoCode: country['alpha-2'], defaultCurrencyIsoCode };

			this.setFormData(newData);
		},
		onStateLabelChange(name) {
			const option = this.stateOptions.find(s => s.value === name);
			this.statesLabelText = option.text;
		},
		addState() {
			const state = {
				displayName: '',
				normalisedState: '',
				regularExpressions: []
			};
			this.formData.states.push(state);
			this.focusLastStateRow();
		},
		async removeState(stateIndex) {
			const stateToRemove = this.formData.states.find((_state, index) => stateIndex === index);

			const confirm = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure you want to delete this State?'), {
				title: `${this.$t('Delete State')}: ${stateToRemove.displayName}`,
				size: 'md',
				okTitle: this.$t('Yes'),
				cancelTitle: this.$t('No')
			});

			if (confirm) {
				const newStates = this.formData.states.filter((_state, index) => {
					return stateIndex !== index;
				});

				this.setFormData({ ...this.formData, states: newStates });
			}
		},
		async focusLastStateRow() {
			await this.$nextTick(() => {
				const inputs = this.$el.querySelectorAll('[data-test-id="StatesTableName"] input');
				const nameInput = inputs[inputs.length - 1];
				nameInput.focus();
			});
		},
		getExtraOptions(stateIndex) {
			let otherStates = [...this.formData.states];
			otherStates.splice(stateIndex, 1);
			return otherStates.map(state => state.regularExpressions).flat();
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.toggle-info-icon {
	color: $of-color-grey-2;
	position: relative;
	top: 4px;
	left: 12px;
	height: 13px;
	width: 13px;
	z-index: 5;
}

.StatesTable {
	tr {
		display: flex;
	}

	&-remove {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 25px;
		margin-left: auto;
		cursor: pointer;
	}
}

.header-info-tooltip {
	display: inline-block;
	position: relative;
	.header-info-icon {
		color: $of-color-grey-2;
		position: relative;
		left: 12px;
		height: 13px;
		width: 13px;
		z-index: 5;
	}
}
</style>
