<template>
	<b-modal
		v-model="isVisible"
		:title="modalTitle"
		:ok-title="$t('Done')"
		:ok-disabled="!isValidForm"
		ok-variant="primary"
		@ok="submit"
	>
		<template>
			<of-multi-select
				name="entryType"
				:allow-clear="false"
				track-by="value"
				:label="$t('Type')"
				:options="typeOptions"
				:searchable="true"
				:placeholder="$t('Type')"
				:disabled="isEdit"
			/>
			<of-multi-select
				v-if="feeRequired"
				name="feeId"
				:allow-clear="false"
				track-by="value"
				:label="$t('Fee')"
				:options="feeOptions"
				:searchable="true"
				:placeholder="$t('Fee')"
				:disabled="isEdit"
			/>
			<of-form-input name="rate" :normalize="valueToFloat" type="number" min="0" max="1" :label="$t('Rate')" />
		</template>
	</b-modal>
</template>

<script>
import _ from 'lodash';
import { OfFormInput, OfMultiSelect, withForm } from '@workflow-solutions/ofs-vue-layout';
import { required, requiredIf, between } from 'vuelidate/lib/validators';
import { valueToFloat, maxDecimals } from '../../../lib/helpers';

const formName = 'discountProfileEntryForm';

export default {
	components: {
		OfFormInput,
		OfMultiSelect
	},
	mixins: [withForm(formName)],
	props: {
		discountProfile: {
			type: Object,
			required: true
		},
		entry: {
			type: [Object, null],
			default: null
		},
		entries: {
			type: [Array, null],
			default: () => []
		},
		show: Boolean,
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	computed: {
		isEdit() {
			return !!this.entry;
		},
		feeRequired() {
			return this.formData.entryType === 'fee';
		},
		modalTitle() {
			return this.entry ? this.$t('Edit Entry') : this.$t('Add Entry');
		},
		isVisible: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) {
					this.onClose();
				}
			}
		},
		isValidForm() {
			return !this.$v.$invalid;
		},
		validationRules() {
			const between01 = between(0, 1);
			const maxFourDecimals = maxDecimals();
			const optionalWithMinValue = value => {
				if (value === null) {
					return true;
				}
				return _.isNumber(value) && between01(value) && maxFourDecimals(value);
			};
			let rules = {
				formData: {
					entryType: {
						required
					},
					rate: {
						optionalWithMinValue
					},
					feeId: {
						requiredIf: requiredIf(function() {
							return this.feeRequired;
						})
					}
				}
			};

			return rules;
		},
		feeOptions() {
			const filledEntryIds = _.map(this.entries, '_id');

			const filteredEntries = _.filter(
				this.discountProfile.entries,
				entry => entry.entryType === 'fee' && !filledEntryIds.includes(entry._id)
			);

			const mappedEntries = _.map(filteredEntries, entry => {
				return (
					entry.feeId && {
						text: entry.feeId.displayName,
						value: entry.feeId._id
					}
				);
			});

			return _.sortBy(_.compact(mappedEntries), [
				e => {
					return (e?.text || '').toLowerCase();
				}
			]);
		},
		typeOptions() {
			const options = [
				{
					text: this.$t('Fee'),
					value: 'fee'
				},
				{
					text: this.$t('Item'),
					value: 'item'
				},
				{
					text: this.$t('Shipping'),
					value: 'shipping'
				}
			];

			const usedTypes = _.filter(_.uniq(_.map(this.entries, entry => entry.entryType)), this.formData.entryType);

			return options.filter(option => {
				return option.value === 'fee' || !_.includes(usedTypes, option.value);
			});
		}
	},
	watch: {
		entry: {
			immediate: true,
			handler(entry) {
				if (entry) {
					this.initFormData({
						...entry,
						feeId: entry.feeId?._id,
						rate: _.isNumber(entry.rate) ? entry.rate : null
					});
				} else {
					this.resetForm();
				}
			}
		},
		isVisible: function(val) {
			if (!val) {
				this.resetForm();
			}
		}
	},
	methods: {
		async submit(e) {
			// Disable modal close
			e.preventDefault();
			const entry = {
				...this.formData,
				rate: _.isNumber(this.formData.rate) ? this.formData.rate : null
			};
			this.$emit('save-entry', entry);
			this.resetForm();
			this.onClose();
		},
		resetForm() {
			this.resetFormData();
			this.initFormData({
				entryType: null,
				rate: null,
				feeId: null
			});
		},
		valueToFloat
	}
};
</script>
