<template>
	<div id="app" v-if="ready" :data-version="version">
		<router-view />
		<Notification />
	</div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import { Notification } from '@workflow-solutions/ofs-vue-layout';
import _get from 'lodash/get';

export default {
	name: 'BrandCentre',
	components: {
		Notification
	},
	data() {
		return {
			ready: false,
			isConsentInitialized: false,
			version: process.env.VERSION
		};
	},
	computed: {
		...mapGetters('auth', ['isAuthenticated', 'user', 'availableAccounts']),
		...mapGetters('account', ['currentAccount', 'accountList']),
		...mapGetters('featureToggle', ['features']),
		...mapGetters('lang', ['lang']),
		...mapGetters('controlCentre', ['aclGroups'])
	},
	async beforeCreate() {
		this.tempToken = _get(this, '$router.history.pending.query.token');
	},
	async created() {
		// Reset ACL Groups
		this.setAclGroups([]);

		if (!this.$store.state.auth.isAuthenticated && this.tempToken) {
			try {
				const token = await this.$store.dispatch('auth/authenticateWithTempToken', this.tempToken);
				await this.$store.dispatch('auth/loginHpId', token);
			} catch (err) {
				this.$toaster.error(this.$t('An error occurred while checking the temporary token'), 3000);
			}
		}
		this.$router.afterEach(this.redirectIfNotAuth);
		try {
			await this.fetchBaseAccountData();
			await this.getAclGroups();
			await this.aclPermissionsCheck();
		} catch (err) {
			console.error(err);
		}

		await this.loadLanguage({ lang: this.lang });
		this.ready = true;

		this.switchAccount();
		this.$router.afterEach(this.switchAccount);

		// Set User ID
		if (this.$ga && this.user) {
			this.$ga.set('userId', this.user._id);
		}
	},
	watch: {
		isAuthenticated: {
			immediate: true,
			handler() {
				this.redirectIfNotAuth();
			}
		},
		features: {
			immediate: true,
			handler: 'checkFeatures'
		},
		$route: {
			immediate: true,
			handler: 'checkFeatures'
		}
	},
	methods: {
		...mapActions('controlCentre', ['getAclGroups', 'setAclGroups']),
		...mapActions('account', ['getVars', 'changeAccount', 'fetchBaseAccountData']),
		...mapActions('user', ['getMemberships']),
		...mapActions('menu', ['updateMenu']),
		...mapActions('lang', ['loadLanguage']),
		...mapActions('auth', ['logout']),
		redirectIfNotAuth() {
			if (!this.isAuthenticated && this.$route.name !== 'login') this.$router.push('/login');
		},
		// Check to see if the route requires a feature flag
		// Redirect to the homepage if do not have permission
		// If HP Mode (Piazza) - Disable feature for now
		checkFeatures() {
			if (!this.features) {
				return;
			}

			if (this.$route.meta.feature) {
				if (this.features[this.$route.meta.feature] === false) {
					this.$router.replace('/').catch(() => {});
				}
			}
		},
		switchAccount() {
			if (!this.$route.query.$switchAccount) {
				return;
			}
			const account = this.accountList.find(acc => acc.name === this.$route.query.$switchAccount);
			if (account) {
				this.changeAccount(account);
			}
		},
		async aclPermissionsCheck() {
			// Check ACL permissions
			if (this.isAuthenticated && this.$route.name !== 'login') {
				if (!this.aclGroups['fulfilment-admin'] && !this.aclGroups['cs-team-member']) {
					await this.logout();
					this.$toaster.error(this.$t('You do not have permission to access Control Centre'), 3000);
				}
			}
		}
	}
};
</script>

<style lang="scss">
:focus {
	outline: none;
}

#onetrust-consent-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-policy-text,
#onetrust-banner-sdk .ot-sdk-button,
#onetrust-banner-sdk button,
#onetrust-pc-sdk h2,
#onetrust-pc-sdk h3,
#onetrust-pc-sdk p,
#onetrust-pc-sdk button,
#onetrust-pc-sdk a,
#onetrust-pc-sdk div {
	font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

.modal.show {
	display: block;
}

.ContentHeader {
	z-index: 1;
}

.AccountSwitcher {
	display: flex;
	align-items: center;
}

.v-toaster {
	top: 80px;
}
</style>
