<template>
	<li class="FeatureList__item FeatureListItem">
		<label class="FeatureListItem__title" :for="`feature__${segmentSummary.key}`">{{ segmentSummary.title }}</label>
		<p class="FeatureListItem__description">{{ segmentSummary.description }}</p>
		<b-form-checkbox
			v-if="segment"
			:id="`feature__${segmentSummary.key}`"
			v-model="enabled"
			class="FeatureListItem__checkbox"
			@change="segmentUpdated"
		/>
		<loader v-else class="FeatureListItem__loader" />
	</li>
</template>

<script>
import { mapActions } from 'vuex';
import Loader from '../../components/Loader';

export default {
	components: {
		Loader
	},
	props: {
		segmentSummary: {
			type: Object,
			required: true
		},
		updateSegment: {
			type: Function,
			required: true
		},
		accountId: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			segment: null,
			enabled: false
		};
	},
	watch: {
		segmentSummary: {
			immediate: true,
			handler: async function() {
				this.segment = await this.findSegment({ id: this.segmentSummary.key });

				// Update enabled state
				this.reset();

				// Send the initial value to the parent
				this.updateSegment(this.segment, this.enabled, true);
			}
		}
	},
	mounted() {},
	methods: {
		...mapActions({
			findSegment: 'controlCentreSegment/findById'
		}),
		reset() {
			this.enabled = this.segment.accountsEnabled.includes(this.accountId);
		},
		segmentUpdated(value) {
			this.updateSegment(this.segment, value);
		}
	}
};
</script>

<style></style>
