<template>
	<DefaultLayout>
		<OfsPanel>
			<ListTable
				:table-title="$t('Device Types')"
				:config="config"
				bordered
				:items="deviceTypes"
				:current-page="currentPage"
				:fields="fields"
				:total-items="count"
				:per-page="perPage"
				:selected="selected"
				:fetch-data="fetchData"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="handleRowClick"
				@table-change="handleTableChange"
			>
				<template slot="empty" slot-scope="{}">
					<span>
						<i>{{ $t('No data') }}</i>
					</span>
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
			</ListTable>
		</OfsPanel>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ListTable, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import tableMixin from '../../mixins/tableMixin';
import navigationMixin from '../../mixins/navigationMixin';
import _map from 'lodash/map';
import _throttle from 'lodash/throttle';

export default {
	name: 'DeviceList',
	components: {
		ListTable,
		OfsPanel,
		DefaultLayout
	},
	mixins: [tableMixin, navigationMixin],
	data() {
		return {
			actions: [{ title: this.$t('Duplicate'), callback: ({ _id }) => this.handleDuplicateDevice(_id) }],
			fields: [
				{ key: 'model', label: this.$t('Model') },
				{ key: 'family', label: this.$t('Family') },
				{ key: 'manufacturer', label: this.$t('Manufacturer') }
			],
			config: {
				search: { visible: false },
				breadcrumbs: { visible: false },
				refresh: { visible: true, title: this.$t('Refresh') },
				columns: { visible: true, title: this.$t('Toggle columns') },
				add: {
					visible: true,
					href: { name: 'deviceType.add' },
					title: this.$t('Add New')
				}
			},
			selected: []
		};
	},
	computed: {
		...mapGetters({
			count: 'device-type/count',
			deviceTypes: 'device-type/device-types'
		})
	},
	created() {
		this.selected = _map(this.fields, 'key');
		this.fetchData();
	},
	methods: {
		...mapActions({
			getDeviceTypes: 'device-type/find'
		}),
		fetchData: _throttle(
			function() {
				this.updateUrl();
				return this.getDeviceTypes({
					query: {
						direction: 1,
						sort: 'model',
						page: this.currentPage,
						pagesize: this.perPage
					}
				});
			},
			500,
			{ trailing: false }
		),
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') {
				return;
			}

			this.goTo({ name: 'deviceType.edit', params: { id: item._id } });
		}
	}
};
</script>
