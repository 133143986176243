import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as actions from './actions';

const createStore = () => {
	Vue.use(Vuex);
	return new Vuex.Store({
		config: window.$config,
		plugins: [
			createPersistedState({
				key: 'control-centre',
				paths: [
					'ui.pageConfigs',
					'auth.token',
					'auth.isAuthenticated',
					'auth.isHpIdLogin',
					'account.currentAccount',
					'account.psps',
					'account.psp',
					'lang.lang'
				]
			})
		],
		actions
	});
};

export default createStore;
