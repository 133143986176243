<template>
	<DefaultLayout>
		<ofs-panel>
			<section v-if="isLoading">
				<Loader />
			</section>
			<list-table
				v-else
				:table-title="$t('Countries')"
				:items="countries"
				:fields="fields"
				:total-items="countryCount"
				:per-page="perPage"
				:current-page="currentPage"
				:is-busy="isBusy"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="itemClicked"
				@table-change="handleTableChange"
			>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="empty">
					<span>
						<i>{{ $t('No Data') }}</i>
					</span>
				</template>
				<template slot="TableButtons-Slot-left" slot-scope="{}">
					<b-button variant="primary" :to="{ name: 'admin.countries.create' }">
						{{ $t('New country') }}
					</b-button>
				</template>
				<template v-slot:cell(type)="data">
					{{ data.item.type }}
				</template>
				<template v-slot:cell(grades)="data">
					{{ data.item.grades.length }}
				</template>
				<template v-slot:cell(requireState)="data">
					{{ data.item.requireState === true ? $t('Yes') : $t('No') }}
				</template>
				<template v-slot:cell(actions)="data">
					<b-dropdown no-caret size="sm" data-test-id="countryActions">
						<template slot="button-content">
							<font-awesome-icon icon="ellipsis-h" />
						</template>
						<b-dropdown-item @click="deleteItem(data.item)">
							{{ $t('Delete') }}
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</list-table>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import { OfsPanel, ListTable } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import Loader from '../../components/Loader';
import { displayError } from '../../lib/helpers';
import isFulfilmentAdminMixin from '../../mixins/isFulfilmentAdminMixin';
import tableMixin from '../../mixins/tableMixin';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		DefaultLayout,
		OfsPanel,
		Loader,
		ListTable
	},
	mixins: [isFulfilmentAdminMixin, tableMixin],
	data() {
		return {
			sort: {
				name: 1
			},
			isLoading: false,
			isBusy: false,
			fields: [
				{ key: 'name', label: this.$t('Name') },
				{ key: 'isoCode', label: this.$t('ISO Code') },
				{ key: 'defaultCurrencyIsoCode', label: this.$t('Default Currency') },
				{ key: 'requireState', label: this.$t('State Required') },
				{ key: 'actions', label: ' ', tdClass: 'text-right' }
			]
		};
	},
	computed: {
		...mapGetters({
			countries: 'country/countries',
			countryCount: 'country/count'
		})
	},
	async mounted() {
		this.isLoading = true;
		await this.fetchData();
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			findCountries: 'country/find',
			deleteCountry: 'country/deleteById'
		}),
		async fetchData() {
			try {
				const query = {
					query: {
						$limit: this.perPage,
						$skip: this.perPage * (this.currentPage - 1),
						$sort: this.sort
					}
				};

				this.isBusy = true;
				await this.findCountries({
					query
				});
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			} finally {
				this.isBusy = false;
			}
		},
		itemClicked(item) {
			this.$router.push({ name: 'admin.countries.view', params: { id: item._id } });
		},
		async deleteItem(item) {
			const confirm = await this.$bvModal.msgBoxConfirm(
				this.$t('Are you sure you want to delete this Country?'),
				{
					title: this.$t('Delete Country'),
					size: 'sm',
					okTitle: this.$t('Yes'),
					cancelTitle: this.$t('No')
				}
			);

			if (confirm) {
				try {
					this.isBusy = true;
					await this.deleteCountry({
						id: item._id
					});
					this.$notify({
						type: 'success',
						text: this.$t('Country successfully deleted'),
						title: this.$t('Country Deleted')
					});
					await this.fetchData();
				} catch (err) {
					this.$notify({ type: 'error', text: displayError(err) });
				} finally {
					this.isBusy = false;
				}
			}
		}
	}
};
</script>

<style lang="scss"></style>
