import { i18n } from '../../vuex';
import SpecTemplates from './SpecTemplates';
import SpecTemplateEdit from './SpecTemplates/Edit';
import Materials from './Materials';
import MaterialEdit from './Materials/Edit';
import Countries from './Countries';
import CountryEdit from './Countries/Edit';
import AdditionalFees from './AdditionalFees';
import AdditionalFeesEdit from './AdditionalFees/Edit';
import PriceConfigurations from './PriceConfiguration';
import PriceConfigurationEdit from './PriceConfiguration/Edit';
import Currencies from './Currencies';
import CurrenciesEdit from './Currencies/Edit';
import Tariffs from './Tariff';
import TariffsEdit from './Tariffs/Edit';
import ShipmentPriceSheets from './ShipmentPriceSheets';
import ShipmentPriceSheetEdit from './ShipmentPriceSheets/Edit';
import FulfilmentEdit from './Fulfilments/Edit';
import FulfilmentItemDetails from './Fulfilments/ItemDetails';
import FulfilmentShipmentDetails from './Fulfilments/ShipmentDetails';
import Fulfilments from './Fulfilments';
import DiscountProfiles from './DiscountProfiles';
import DiscountProfilesEdit from './DiscountProfiles/Edit.vue';

const $t = str => i18n.t(str);

const routes = [
	{
		path: '/admin',
		name: 'admin.fulfilments',
		component: { template: '<router-view />' },
		children: [
			{
				path: 'spec-templates',
				name: 'admin.hp-spec-templates',
				component: SpecTemplates
			},
			{
				path: 'spec-templates/add',
				name: 'admin.hp-spec-templates.create',
				component: SpecTemplateEdit
			},
			{
				path: 'spec-templates/:id',
				name: 'admin.hp-spec-templates.view',
				component: SpecTemplateEdit
			},
			{
				path: 'materials',
				name: 'admin.materials',
				component: Materials
			},
			{
				path: 'materials/add',
				name: 'admin.materials.create',
				component: MaterialEdit
			},
			{
				path: 'materials/:id',
				name: 'admin.materials.view',
				component: MaterialEdit
			},
			{
				path: 'additional-fees',
				name: 'admin.fees',
				component: AdditionalFees
			},
			{
				path: 'additional-fees/add',
				name: 'admin.fees.create',
				component: AdditionalFeesEdit
			},
			{
				path: 'additional-fees/:id',
				name: 'admin.fees.view',
				component: AdditionalFeesEdit
			},
			{
				path: 'discount-profiles',
				name: 'admin.discountProfiles',
				component: DiscountProfiles
			},
			{
				path: 'discount-profiles/add',
				name: 'admin.discountProfiles.create',
				component: DiscountProfilesEdit
			},
			{
				path: 'discount-profiles/:id',
				name: 'admin.discountProfiles.view',
				component: DiscountProfilesEdit
			},
			{
				path: 'countries',
				name: 'admin.countries',
				component: Countries
			},
			{
				path: 'countries/add',
				name: 'admin.countries.create',
				component: CountryEdit
			},
			{
				path: 'countries/:id',
				name: 'admin.countries.view',
				component: CountryEdit
			},
			{
				path: 'price-configurations',
				name: 'admin.priceConfigurations',
				component: PriceConfigurations
			},
			{
				path: 'price-configurations/add',
				name: 'admin.priceConfigurations.create',
				component: PriceConfigurationEdit
			},
			{
				path: 'price-configurations/:id',
				name: 'admin.priceConfigurations.view',
				component: PriceConfigurationEdit
			},
			{
				path: 'currencies',
				name: 'admin.currencies',
				component: Currencies
			},
			{
				path: 'currencies/add',
				name: 'admin.currencies.create',
				component: CurrenciesEdit
			},
			{
				path: 'currencies/:id',
				name: 'admin.currencies.view',
				component: CurrenciesEdit
			},
			{
				path: 'tariffs',
				name: 'admin.tariffs',
				component: Tariffs
			},
			{
				path: 'tariffs/add',
				name: 'admin.tariffs.create',
				component: TariffsEdit,
				props: true
			},
			{
				path: 'tariffs/:id',
				name: 'admin.tariffs.view',
				component: TariffsEdit
			},
			{
				path: 'shipping',
				name: 'admin.shipping',
				component: ShipmentPriceSheets
			},
			{
				path: 'shipping/add',
				name: 'admin.shipping.create',
				component: ShipmentPriceSheetEdit
			},
			{
				path: 'shipping/:id',
				name: 'admin.shipping.view',
				component: ShipmentPriceSheetEdit
			},
			{
				path: 'fulfilments/:id/items/:itemId',
				name: 'admin.fulfilments.item.view',
				component: FulfilmentItemDetails
			},
			{
				path: 'fulfilments/:id/shipments/:shipmentId',
				name: 'admin.fulfilments.shipment.view',
				component: FulfilmentShipmentDetails
			},
			{
				path: 'fulfilments/:id',
				name: 'admin.fulfilments.view',
				component: FulfilmentEdit
			},
			{
				path: 'fulfilments',
				name: 'admin.fulfilments',
				component: Fulfilments
			}
		]
	}
];

export default routes;
