import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'controlCentre',
		options: {
			namespaced: true
		},
		transport: 'controlCentreApi',
		state: {
			aclGroups: {}
		},
		actions: {
			setAclGroups({ commit }, aclGroups) {
				commit('SET_ACL_GROUPS', aclGroups);
			},
			async getAclGroups({ commit, dispatch }) {
				const response = await dispatch('request', {
					method: 'GET',
					path: 'user/acl'
				}).then(r => r.data);
				const aclGroups = {};
				response.forEach(key => (aclGroups[key] = true));
				commit('SET_ACL_GROUPS', aclGroups);
			}
		},
		mutations: {
			SET_ACL_GROUPS(state, aclGroups) {
				state.aclGroups = aclGroups;
			}
		},
		getters: {
			aclGroups: state => state.aclGroups
		}
	});
}
