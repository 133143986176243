export const confirmModalMixin = () => ({
	methods: {
		async confirm({
			type,
			title,
			message,
			okTitle,
			okVariant,
			cancelTitle,
			onOk = () => {},
			onCancel = () => {},
			onDismiss = () => {},
			centered = true
		}) {
			const iconMapping = {
				info: 'info-circle',
				warning: 'exclamation-circle',
				success: 'check-circle'
			};
			const result = await this.$bvModal.msgBoxConfirm(
				<div
					class={[
						'ConfirmModal_Content',
						centered && 'ConfirmModal_Content__centered'
					]}
				>
					{type && (
						<icon
							class={[
								'ConfirmModal_Content_Icon',
								`ConfirmModal_Content_Icon__${type}`
							]}
							name={iconMapping[type]}
						/>
					)}
					{message}
				</div>,
				{
					title,
					okVariant,
					okTitle: okTitle || this.$t('Confirm'),
					cancelTitle: cancelTitle || this.$t('Cancel'),
					centered: true
				}
			);
			switch (result) {
				case true:
					await onOk();
					break;
				case false:
					await onCancel();
					break;
				case null:
					await onDismiss();
					break;
			}
		}
	}
});
