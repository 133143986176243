var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',[_c('ofs-panel',[(_vm.isLoading)?_c('section',[_c('Loader')],1):_c('list-table',{attrs:{"table-title":_vm.$t('Fulfilments'),"items":_vm.fulfilments,"fields":_vm.fields,"total-items":_vm.fulfilmentCount,"per-page":_vm.perPage,"current-page":_vm.currentPage,"is-busy":_vm.isBusy,"page-position-prefix":_vm.$t('Showing'),"page-position-join":_vm.$t('of'),"hover":""},on:{"row-clicked":_vm.itemClicked,"table-change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t('Prev'))+" ")]}},{key:"next-text",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$t('Next'))+" ")]}},{key:"cell(accountId)",fn:function(ref){
var accountId = ref.item.accountId;
return [_c('span',[_vm._v(_vm._s(_vm.getAccountName(accountId)))])]}},{key:"cell(status)",fn:function(ref){
var status = ref.item.status;
return [_c('ofs-badge',{class:("FulfilmentStatus-" + (String(status).toLowerCase())),attrs:{"status":String(status).toLowerCase(),"text":_vm.getFulfilmentBadgeStatusText(status)}})]}},{key:"cell(createdAt)",fn:function(ref){
var createdAt = ref.item.createdAt;
return [_vm._v(" "+_vm._s(_vm.formatDate(createdAt))+" ")]}},{key:"cell(siteFlowOrderDestination.name)",fn:function(ref){
var siteFlowOrderDestination = ref.item.siteFlowOrderDestination;
return [_vm._v(" "+_vm._s((siteFlowOrderDestination && siteFlowOrderDestination.name) || '-')+" ")]}},{key:"TableHeader",fn:function(ref){return [_c('OfFilterBar',{attrs:{"filters":_vm.filters,"reset-label":_vm.$t('Reset Filters'),"values":_vm.queryFilters},on:{"change":_vm.filtersChanged}})]}},{key:"TableButtons-Slot-left",fn:function(ref){return [_c('div',{staticClass:"Fulfilments-search"},[_c('b-form-input',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"Fulfilments-search-input",attrs:{"placeholder":_vm.$t('Search Fulfilments')},on:{"input":function($event){return _vm.doSearch()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('font-awesome-icon',{staticClass:"Fulfilments-search-icon",attrs:{"icon":"search"}})],1),_c('OfInlineFilter',{staticClass:"ml-1",attrs:{"filters":_vm.filters,"label":_vm.$t('Filter'),"reset-label":_vm.$t('Reset Filters'),"values":_vm.queryFilters},on:{"change":_vm.filtersChanged}})]}}])},[_c('template',{slot:"empty"},[_c('span',[_c('i',[_vm._v(_vm._s(_vm.$t('No Data')))])])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }