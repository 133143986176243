<template>
	<b-modal :visible="visible" :title="$t('New Link')" modal-class="NewLinkAdminModal" @hide="handleClose">
		<div>
			<img src="../../../../public/img/linked.svg" />
			<div class="link-info">
				<p>{{ $t('Select accounts you would like to create a link between.') }}</p>
				<p>{{ $t('Once you click "save", orders will be able to be sent between the two accounts.') }}</p>
			</div>
			<div>
				<OfMultiSelect
					name="accountIdA"
					:options="accountOptions.a"
					:placeholder="$t('Type to search..')"
					:label="$t('Account A')"
				/>
				<OfMultiSelect
					name="accountIdB"
					:options="accountOptions.b"
					:placeholder="$t('Type to search..')"
					:label="$t('Account B')"
				/>
			</div>
		</div>
		<section slot="modal-footer">
			<b-btn v-t="'Cancel'" class="mr-1" @click="$emit('hide')" />
			<of-submit-button v-t="'Save'" class="mr-1" @click="handleSave" />
		</section>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { OfSubmitButton, withForm, OfMultiSelect } from '@workflow-solutions/ofs-vue-layout';
import { displayError } from '../../../lib/helpers';

export default {
	components: {
		OfMultiSelect,
		OfSubmitButton
	},
	mixins: [withForm('NewLinkAdmin')],
	props: {
		visible: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			selectOptions: {
				accounts: {
					results: [],
					isLoading: false
				}
			}
		};
	},
	computed: {
		...mapGetters({
			accounts: 'account/userAccounts'
		}),
		accountOptions() {
			const { accountIdA, accountIdB } = this.formData || {};
			return {
				a: this.accounts
					?.filter(account => account._id !== accountIdB)
					?.map(account => ({ text: account.name, value: account._id, active: !account.disabled })),
				b: this.accounts
					?.filter(account => account._id !== accountIdA)
					?.map(account => ({ text: account.name, value: account._id, active: !account.disabled }))
			};
		}
	},
	created() {
		this.fetchData();
	},
	methods: {
		...mapActions({
			getUserAccounts: 'account/getUserAccounts',
			createLink: 'accountlink/create'
		}),
		async fetchData() {
			await this.getUserAccounts({ query: { active: true } });
		},
		async handleSave() {
			try {
				await this.createLink({ accountIdA: this.formData.accountIdA, accountIdB: this.formData.accountIdB });
				this.$notify({ type: 'success', text: this.$t('Account link created') });
				this.handleClose(true);
			} catch (err) {
				this.$notify({ type: 'error', text: this.$t(displayError(err)) });
			}
		},
		handleClose(refetch = false) {
			this.resetFormData();
			this.$emit('hide', refetch);
		}
	}
};
</script>

<style lang="scss">
.NewLinkAdminModal {
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.link-info {
		text-align: center;
		padding: 20px 40px 10px;
	}
}
</style>
