<template>
	<section class="FulfilmentPackage">
		<header class="FulfilmentPackage__header" @click="togglePackage">
			<icon class="FulfilmentPackage__icon" :name="arrow" />
			<div class="FulfilmentPackage__info">
				<h1 class="FulfilmentPackage__title" v-text="name" />
				<p class="FulfilmentPackage__description" data-test-id="package-dimensions">
					{{ $t('Dimensions: ') }}{{ dimensions }}
				</p>
				<p class="FulfilmentPackage__description" data-test-id="package-weight">
					{{ $t('Weight: ') }}{{ weight }}
				</p>
				<p class="FulfilmentPackage__description" data-test-id="package-tracking">
					{{ $t('Tracking: ') }}
					<a v-if="shipmentPackage.trackingUrl" class="FulfilmentPackage__description" target="_blank" data-test-id="package-tracking" :href="shipmentPackage.trackingUrl">
						{{ shipmentPackage.trackingNumber }}
					</a>
					<span v-else-if="shipmentPackage.trackingNumber">{{ shipmentPackage.trackingNumber }}</span>
					<span v-else>-</span>
				</p>
			</div>
			<span class="FulfilmentPackage__quantity" v-text="quantity" />
		</header>
		<div class="FulfilmentPackage__inner" v-if="isExpanded">
			<b-table :fields="itemFields" :items="shipmentPackage.items" class="FulfilmentPackage__table border-bottom-0" />
		</div>
	</section>
</template>

<script>
import _ from 'lodash';

export default {
	props: {
		shipmentPackage: {
			type: Object,
			required: true
		},
		expanded: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isExpanded: this.expanded,
			itemFields: [
				{
					label: this.$t('Item'),
					key: 'sourceItemId'
				},
				{
					label: this.$t('Quantity'),
					key: 'count'
				}
			]
		};
	},
	computed: {
		name() {
			return _.get(this.shipmentPackage, 'name');
		},
		arrow() {
			return this.isExpanded ? 'caret-up' : 'caret-down';
		},
		dimensions() {
			return `${this.shipmentPackage.height} x ${this.shipmentPackage.width} x ${this.shipmentPackage.depth} (H x W x D in mm)`;
		},
		weight() {
			return `${this.shipmentPackage.totalWeight}g`;
		},
		quantity() {
			let quantity = 0;

			_.forEach(this.shipmentPackage.items, i => {
				quantity += i.count;
			});

			return quantity;
		}
	},
	methods: {
		togglePackage() {
			this.isExpanded = !this.isExpanded;
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
@import '~@workflow-solutions/ofs-vue-layout/dist/style/mixins';

.FulfilmentPackage {
	border: 1px solid $of-color-grey-3;
	border-radius: $of-border-radius;

	&__header {
		display: flex;
		flex-direction: row;
		padding: 15px;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		user-select: none;
	}

	&__icon {
		margin-right: 15px;
		flex-grow: 0;
	}

	&__info {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__title {
		@include ofTextStyleHeading();
		margin: 0;
		padding: 0;
	}

	&__description {
		line-height: 1.2;
		font-weight: $of-font-weight-regular;
		color: $of-color-grey-2;
		margin: 5px 0 0;
		padding: 0;
	}

	&__quantity {
		@include ofTextStyleHeading();
		display: flex;
		align-items: center;
		margin: 0;
	}

	&__inner {
		border-top: 1px solid $of-color-grey-3;
	}

	&__table {
		border-bottom: 0;
	}
}
</style>
