<template>
	<div v-if="isLoading">
		<Loader />
	</div>
	<div v-else class="Rates">
		<hr class="form-divider" />
		<h3 class="form-header">{{ pageTitle }}</h3>
		<b-form>
			<b-row>
				<b-col cols="4">
					<of-form-input
						class="Rates__input"
						name="conversionRate"
						type="number"
						min="0"
						data-test-id="conversionRateUSD"
						:label="$t('USD $ Rate')"
						:disabled="!currency"
						:description="$t('Maximum of 4 decimal places allowed')"
						:normalize="valueToFloat"
					/>
				</b-col>
				<b-col cols="4">
					<of-form-input
						class="Rates__input"
						name="periodStart"
						type="date"
						data-test-id="startDateUTC"
						:label="$t('Start Date (UTC)')"
						:disabled="!currency"
					/>
				</b-col>
				<b-col cols="4" class="Rates__col">
					<b-button class="Rates__save mb-2" :disabled="!canSubmit" @click.prevent="save">
						{{ $t('Add') }}
					</b-button>
				</b-col>
			</b-row>
			<b-row>
				<b-col v-if="!currency">
					<small class="form-text text-muted">
						{{ $t('You must save the currency first in order to add new rates') }}
					</small>
				</b-col>
			</b-row>

			<b-row v-if="rates.length">
				<b-col cols="8">
					<b-table :items="rates" :fields="ratesFields" responsive class="RatesTable">
						<template v-slot:cell(periodStart)="{ item }">
							{{ formatDate(moment(item.periodStart).utc(), null, false) }}
						</template>
					</b-table>
				</b-col>
			</b-row>
		</b-form>
	</div>
</template>

<script>
import { OfFormInput, withForm } from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import { required, minValue } from 'vuelidate/lib/validators';
import moment from 'moment';

import Loader from '../../../components/Loader';
import { displayError, valueToFloat } from '../../../lib/helpers';
import { dateFormatMixin } from '../../../mixins/dateFormatMixin';

const formName = 'ratesForm';
export default {
	components: { Loader, OfFormInput },
	mixins: [withForm(formName), dateFormatMixin()],
	props: {
		currency: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			isLoading: false,
			pageTitle: this.$t('Conversion Rates'),
			ratesFields: [
				{ key: 'conversionRate', label: this.$t('USD $ Rate') },
				{ key: 'periodStart', label: this.$t('Start Date (UTC)') }
			],
			ratesCount: 10
		};
	},
	computed: {
		...mapGetters({
			rates: 'currencyConversionRate/currencyConversionRates'
		}),
		showLoadMore() {
			const showMore = this.rates.length < this.ratesCount;
			return this.rates.length && showMore;
		},
		canSubmit() {
			return this.currency && !this.isInvalid;
		},
		defaultDate() {
			return moment
				.utc()
				.startOf('date')
				.add(1, 'day')
				.format('YYYY-MM-DD');
		},
		validationRules() {
			const mustLaterThanToday = value => {
				const today = moment.utc().startOf('date');
				const startDate = moment.utc(value).startOf('date');

				return startDate.isAfter(today);
			};

			const notDuplicate = value => {
				if (!value) return false;
				const startDateIso = moment.utc(value).startOf('date');
				return !(this.rates || []).find(r => r.periodStart === startDateIso.toISOString());
			};

			const maxDecimalCheck = amount => {
				if (amount) {
					return /^\d*(\.\d{0,4})?$/.test(amount);
				}
				return true;
			};

			const validDate = date => {
				return moment(date, 'YYYY-MM-DD', true).isValid();
			};

			return {
				formData: {
					conversionRate: {
						required,
						minValue: minValue(0),
						maxDecimalCheck
					},
					periodStart: {
						required,
						mustLaterThanToday,
						validDate,
						notDuplicate
					}
				}
			};
		}
	},
	watch: {
		currency: {
			async handler() {
				await this.fetchData();
			},
			immediate: true
		}
	},
	methods: {
		...mapActions({
			findConversionRates: 'currencyConversionRate/find',
			saveRate: 'currencyConversionRate/create'
		}),
		moment,
		valueToFloat,
		async fetchData() {
			this.isLoading = true;
			try {
				await this.findConversionRates({
					query: {
						query: {
							$where: { currencyIsoCode: this.currency },
							$limit: 10, // load the last 10 results
							$sort: { periodStart: -1 }
						}
					}
				});

				this.initFormData({
					periodStart: this.defaultDate
				});
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				this.$router.push({ name: 'admin.currencies' });
				this.initFormData({});
			} finally {
				this.isLoading = false;
			}
		},
		async save() {
			try {
				const { conversionRate, periodStart } = this.formData;
				const utcStartDate = moment.utc(periodStart).startOf('date');
				const newRate = {
					conversionRate,
					currencyIsoCode: this.currency,
					periodStart: utcStartDate.toISOString()
				};
				await this.saveRate(newRate);
				await this.findConversionRates({
					query: {
						query: {
							$where: { currencyIsoCode: this.currency },
							$limit: 10,
							$sort: { periodStart: -1 }
						}
					}
				});
				this.resetFormData();
				this.setFormData({
					periodStart: this.defaultDate
				});
				this.$notify({ type: 'success', text: this.$t('Rate saved') });
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
			}
		}
	}
};
</script>

<style lang="scss">
.Rates {
	&__col {
		display: flex;
		align-items: center;
	}

	&__save {
		margin-bottom: 1rem;
	}
}
</style>
