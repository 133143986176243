import Vue from 'vue';

export default function() {
	Vue.$ofsCrud.addResource({
		name: 'transformer',
		pluralName: 'transformers',
		transport: 'connect',
		options: {
			namespaced: true,
			actions: {
				find: {
					dataProp: 'data', // -> `data` in the response payload
					countProp: 'count' // -> `count` in the response payload
				}
			}
		},
		actions: {
			execute({ dispatch }, { id, data }) {
				const path = `transformer/${id}/execute`;
				return dispatch('request', { method: 'POST', path, payload: data }).then(r => r.data);
			}
		}
	});
}
