<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else class="FulfilmentView">
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3">
				<b-button
					v-if="canReject"
					v-t="'Reject Fulfilment'"
					variant="danger"
					class="ml-2"
					@click="onRejectFulfilment"
				/>
				<b-button
					v-if="canCancel"
					v-t="'Cancel SiteFlow Order'"
					variant="danger"
					class="ml-2"
					@click="cancelSiteFlowOrder"
				/>
				<b-button
					v-if="canResubmit"
					v-t="'Resubmit'"
					variant="primary"
					class="ml-2"
					@click="onResubmitFulfilment"
				/>
				<b-button
					v-if="canRetry"
					v-t="'Retry Submission'"
					variant="primary"
					class="ml-2"
					@click="onRetrySubmission"
				/>
			</content-header>
			<section class="FulfilmentView_container">
				<b-tabs @activate-tab="tabIndexChanged">
					<b-tab :title="$t('Details')">
						<div :class="`FulfilmentView_Status FulfilmentView_Status--${status}`">
							<ofs-badge :text="getFulfilmentBadgeStatusText(status)" :status="status"></ofs-badge>
							<div class="FulfilmentView_Status_UpdatedAt">
								<span class="FulfilmentView_Status_UpdatedAt_Label">{{ $t('Updated at: ') }}</span>
								<span class="FulfilmentView_Status_UpdatedAt_Value">{{ formatDate(updatedAt) }}</span>
								<span class="FulfilmentView_Status_UpdatedAt_Ago">- {{ updatedAt | fromDate }}</span>
							</div>
							<b-button
								class="FulfilmentView_Status_RefreshButton"
								variant="secondary"
								@click.prevent="fetchData"
							>
								{{ $t('Refresh') }}
							</b-button>
						</div>
						<b-row>
							<b-col>
								<div>
									<p class="FulfilmentView_DataTableTitle">{{ $t('Source Order Details') }}</p>
									<b-table
										id="SourceOrderDetailsTable"
										class="FulfilmentView_DataTable"
										stacked
										:fields="sourceOrderTableFields"
										:items="[sourceOrder]"
									>
										<template #cell(fulfilmentId)>
											{{ fulfilment._id }}
										</template>
										<template #cell(shipmentId)>
											{{ fulfilment.shipmentId }}
										</template>
										<template #cell(accountId)="{ item }">
											{{ getAccountName(item.accountId) }}
										</template>
										<template #cell(status)="{ value }">
											<ofs-badge :text="getFulfilmentBadgeStatusText(value)" :status="value" />
										</template>
									</b-table>
								</div>
								<div>
									<p class="FulfilmentView_DataTableTitle">{{ $t('Exception Details') }}</p>
									<b-table
										id="ExceptionDetailsTable"
										class="FulfilmentView_DataTable"
										stacked
										:fields="exceptionDetailsTableFields"
										:items="[fulfilment]"
									>
										<template #cell(siteFlowOrderSubmissionErrorIds)="{ value }">
											<template v-if="value.length">
												<a
													v-for="error in value"
													:key="error"
													:href="`${ piazzaUrl }#/order-submission-errors/view/${ error }?$switchAccount=${ fulfilmentAccount.name }`"
												>
													{{ error }}
												</a>
											</template>
											<span v-else>-</span>
										</template>
									</b-table>
								</div>
							</b-col>
							<b-col>
								<div>
									<p class="FulfilmentView_DataTableTitle">{{ $t('Site Flow Order Details') }}</p>
									<b-table
										id="SiteFlowOrderDetailsTable"
										class="FulfilmentView_DataTable"
										stacked
										:fields="siteflowOrderDetailsTableFields"
										:items="[fulfilment]"
									>
									</b-table>
								</div>
							</b-col>
						</b-row>
					</b-tab>
					<b-tab :title="$t('Items')">
						<ItemsTable :account-id="fulfilment.accountId" />
					</b-tab>
					<b-tab :title="$t('Shipping')">
						<FulfilmentShippingList :id="id" :account-id="fulfilment.accountId" />
					</b-tab>
				</b-tabs>
			</section>
		</ofs-panel>
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	OfsPanel,
	OfsBadge,
	OfsFeatureButton,
	OfFormInput,
	OfFormSelect,
	OfMultiSelect,
	withForm,
	ContentHeader
} from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import { displayError, getFulfilmentBadgeStatusText } from '../../../lib/helpers';
import { dateFormatMixin } from '../../../mixins/dateFormatMixin';
import { confirmModalMixin } from '../../../mixins/confirmModalMixin';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';
import ItemsTable from './Items';
import FulfilmentShippingList from './FulfilmentShippingList';

const formName = 'editMaterial';

export default {
	components: {
		ContentHeader,
		OfsBadge,
		Loader,
		OfsPanel,
		DefaultLayout,
		ItemsTable,
		FulfilmentShippingList
	},
	mixins: [isFulfilmentAdminMixin, dateFormatMixin(), withForm(formName), confirmModalMixin()],
	data() {
		const emptyFormatter = value => value || '-';
		const emptyDateFormatter = value => (value ? this.formatDate(value) : '-');

		const sourceOrderTableFields = [
			{ key: 'fulfilmentId', label: this.$t('Fulfilment ID'), formatter: emptyFormatter },
			{ key: 'accountId', label: this.$t('Account ID'), formatter: emptyFormatter },
			{ key: 'sourceOrderId', label: this.$t('Source Order ID'), formatter: emptyFormatter },
			{ key: '_id', label: this.$t('Order ID'), formatter: emptyFormatter },
			{ key: 'status', label: this.$t('Order Status'), formatter: emptyFormatter },
			{ key: 'shipmentId', label: this.$t('Shipment ID'), formatter: emptyFormatter },
			{ key: 'createdAt', label: this.$t('Created at'), formatter: emptyDateFormatter }
		];

		const exceptionDetailsTableFields = [
			{
				key: 'siteFlowOrderSubmissionErrorIds',
				label: this.$t('SiteFlow Order Submission Error Ids'),
				formatter: emptyFormatter
			},
			{ key: 'onSubmissionErrorAt', label: this.$t('Submission Error At'), formatter: emptyDateFormatter },
			{ key: 'onErrorAt', label: this.$t('Error At'), formatter: emptyDateFormatter },
			{ key: 'onCancelledAt', label: this.$t('Cancelled At'), formatter: emptyDateFormatter },
			{ key: 'onRejectedAt', label: this.$t('Rejected At'), formatter: emptyDateFormatter }
		];

		const siteflowOrderDetailsTableFields = [
			{ key: 'siteFlowSourceOrderId', label: this.$t('Source Order ID'), formatter: emptyFormatter },
			{ key: 'siteFlowOrderId', label: this.$t('Order ID'), formatter: emptyFormatter },
			{ key: 'siteFlowOrderDestination.name', label: this.$t('Account Name'), formatter: emptyFormatter },
			{ key: 'siteFlowOrderDestination.id', label: this.$t('Account ID'), formatter: emptyFormatter },
			{ key: 'sequenceNumber', label: this.$t('Sequence Number'), formatter: emptyFormatter },
			{ key: 'onSubmittedAt', label: this.$t('Submitted At'), formatter: emptyDateFormatter },
			{ key: 'onDatareadyAt', label: this.$t('Data Ready At'), formatter: emptyDateFormatter },
			{ key: 'onPrintreadyAt', label: this.$t('Print Ready At'), formatter: emptyDateFormatter },
			{ key: 'onShippedAt', label: this.$t('Shipped At'), formatter: emptyDateFormatter }
		];

		const fulfilmentItemsTableFields = [
			{ key: 'status', label: this.$t('Status') },
			{ key: 'sourceItemId', label: this.$t('Order Source Item ID') },
			{ key: 'siteFlowSourceItemId', label: this.$t('Site Flow Source Item ID') },
			{ key: 'sourceSpecId', label: this.$t('Source Spec ID') },
			{ key: 'sourceProductId', label: this.$t('Source Product ID') }
		];

		return {
			isLoading: true,
			isLoadingShipments: false,
			shipments: [],
			sourceOrderTableFields,
			exceptionDetailsTableFields,
			siteflowOrderDetailsTableFields,
			fulfilmentItemsTableFields,
			sourceOrder: null,
			fulfilmentItems: [],
			destinationAccountName: ''
		};
	},
	computed: {
		...mapGetters({
			fulfilment: 'fulfilment/fulfilment',
			accountList: 'account/accountList',
			lang: 'lang/lang',
			availableAccounts: 'auth/availableAccounts',
			vars: 'account/vars'
		}),
		id() {
			return this.$route.params.id;
		},
		pageTitle() {
			return `Fulfilment ${_.get(this.fulfilment, 'siteFlowSourceOrderId')}`;
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Fulfilments /'),
					to: {
						name: 'admin.fulfilments'
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		piazzaUrl() {
			const environment = _.get(window.$config, 'environment');
			if (environment === 'dev') {
				return 'https://piazza-ui.dev-a.hpgsb.net/';
			} else if (environment === 'stage') {
				return 'https://piazza-ui.stage-a.hpgsb.net/';
			} else {
				return 'https://app.hppiazza.com/';
			}
		},
		status() {
			return `${_.get(this.fulfilment, 'status', 'null')}`;
		},
		updatedAt() {
			return `${_.get(this.fulfilment, `on${_.capitalize(this.status)}At`, _.get(this.fulfilment, 'updatedAt'))}`;
		},
		fulfilmentAccount() {
			let accountName = 'hp-fulfilment';
			if ($config.environment === 'dev') {
				accountName = 'hp-fulfilment-dev';
			} else if ($config.environment === 'stage') {
				accountName = 'hp-fulfilment-stage';
			}
			return this.accountList.find(account => account.name === accountName) || {};
		},
		canCancel() {
			return ['dataready', 'printready', 'error'].includes(this.status);
		},
		canReject() {
			return ['null', 'submissionError', 'cancelled'].includes(this.status);
		},
		canResubmit() {
			return ['cancelled'].includes(this.status);
		},
		canRetry() {
			return ['submissionError'].includes(this.status);
		},
		destinationAccountOptions() {
			return _.map(
				_.filter(this.availableAccounts, a => a && (a.type === 'psp' || _.includes(a.types, 'psp'))),
				({ name }) => ({ value: name, text: name })
			);
		}
	},
	watch: {
		id: {
			handler: 'fetchData',
			immediate: true
		}
	},
	methods: {
		...mapActions({
			findFulfilmentOrder: 'fulfilmentOrder/findByIdWithAccount',
			findFulfilment: 'fulfilment/findById',
			rejectFulfilment: 'fulfilment/rejectFulfilment',
			resubmitFulfilment: 'fulfilment/resubmitFulfilment',
			setAccount: 'account/setCurrentAccount',
			cancelOrder: 'order/cancelOrder',
			produceFulfilmentOrder: 'fulfilmentOrder/produceOrder'
		}),
		tabIndexChanged(newIndex, prevIndex) {
			// On tab change, reset query params. This prevents issues with table pagination
			// accross tabs
			const location = {
				name: this.$route.name,
				query: {},
				params: {}
			};

			this.$router.replace(location);
		},
		async fetchData() {
			this.isLoading = true;
			try {
				if (this.id) {
					await this.findFulfilment({ id: this.id, query: { query: { $populate: { path: 'consignments' } } } });
					this.sourceOrder = await this.findFulfilmentOrder({
						id: this.fulfilment.orderId,
						accountId: this.fulfilment.accountId
					});
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				this.$router.push({ name: 'admin.fulfilments' });
			} finally {
				this.isLoading = false;
			}
		},
		getFulfilmentBadgeStatusText,

		async cancelSiteFlowOrder() {
			await this.confirm({
				title: this.$t('Cancel SiteFlow Order'),
				message: (
					<div>
						<div v-t="Are you sure you want to cancel this SiteFlow Order?" />
						<div v-t="The SiteFlow order associated with the fulfilment will be cancelled." />
					</div>
				),
				type: 'warning',
				okVariant: 'danger',
				onOk: async () => {
					await this.cancelOrder(this.fulfilment.siteFlowOrderId);
					await this.fetchData();
				}
			});
		},
		async onRejectFulfilment() {
			const result = await this.confirm({
				title: this.$t('Reject Fulfilment'),
				message: (
					<div>
						<div v-t="Are you sure you want to reject this fulfilment?" />
						<div v-t="Fulfilment and all associated items will be set to rejected." />
					</div>
				),
				type: 'warning',
				okTitle: this.$t('Reject'),
				okVariant: 'danger',
				onOk: async () => {
					await this.rejectFulfilment({ id: this.fulfilment._id, accountId: this.fulfilment.accountId });
					await this.fetchData();
				}
			});
		},
		async onResubmitFulfilment() {
			try {
				this.destinationAccountName = _.get(this.fulfilment, 'siteFlowOrderDestination.name');
				const result = await this.confirm({
					title: this.$t('Resubmit Fulfilment'),
					message: (
						<div>
							<p v-t="Please enter the PSP destination account name to copy and resubmit the cancelled fulfilment." />
							<OfFormSelect
								v-model={this.destinationAccountName}
								label={this.$t('Account name')}
								options={this.destinationAccountOptions}
							/>
						</div>
					),
					okTitle: this.$t('Submit'),
					okVariant: 'primary',
					centered: false,
					onOk: async () => {
						this.resubmitFulfilment({
							id: this.fulfilment._id,
							payload: {
								siteFlowOrderDestination: {
									name: this.destinationAccountName,
									id: null
								}
							}
						})
							.then(async newFulfilment => {
								this.fetchData();

								await this.confirm({
									title: this.$t('Fulfilment Submitted'),
									message: (
										<div id="FulfilmentSubmittedModal">
											<p v-t="The new fulfilment has been submitted with siteFlowSourceOrderId:" />
											<OfFormInput
												vuex={false}
												value={newFulfilment.siteFlowSourceOrderId}
												disabled
											>
												<b-button
													slot="append"
													v-clipboard:copy={newFulfilment.siteFlowSourceOrderId}
												>
													<icon name="copy" class="ConfirmModal_Content_copy" />
												</b-button>
											</OfFormInput>
										</div>
									),
									type: 'success',
									okTitle: this.$t('Done'),
									okVariant: 'primary',
									cancelTitle: this.$t('View Fulfilment'),
									onCancel: () => {
										this.$router.push({
											name: 'admin.fulfilments.view',
											params: { id: newFulfilment._id }
										});
									}
								});
							})
							.catch(error => {
								const message = displayError(error);
								this.$notify({ type: 'error', text: `Resubmission error: ${message}` });
							});
					}
				});
			} finally {
				this.destinationAccountName = '';
			}
		},
		async onRetrySubmission() {
			await this.confirm({
				title: this.$t('Retry Submission'),
				message: (
					<div>
						<div v-t="Fulfilment and all associated items will be submitted again to SiteFlow account." />
					</div>
				),
				type: 'info',
				okTitle: this.$t('Retry'),
				okVariant: 'primary',
				onOk: async () => {
					await this.produceFulfilmentOrder({
						id: this.fulfilment.orderId,
						accountId: this.fulfilment.accountId,
						fulfilmentIds: [this.fulfilment._id]
					});
					await this.fetchData();
				}
			});
		},
		getAccountName(id) {
			return this.vars.userAccountLookup[id] || id;
		}
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

@mixin status-block($color) {
	background-color: rgba($color, 0.1);
	border-color: $color;
}

.FulfilmentView {
	&_Status {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 30px 0 0;
		padding: 15px 20px 16px 20px;
		border: 1px solid $of-color-grey-2;
		border-radius: 3px;

		&_RefreshButton {
			width: 72px;
			height: 29px;
			border: 1px solid $of-color-grey-2;
			border-radius: 3px;
			padding: 7px 12px;
		}

		&_UpdatedAt {
			display: flex;
			flex: 1;
			margin-left: 30px;
			flex-direction: row;
			justify-content: flex-start;

			&_Label {
				color: $of-color-grey-2;
			}

			&_Value {
				margin: 0 5px;
			}

			&_Ago {
				font-style: italic;
			}
		}
	}

	&_Status--null {
		@include status-block($of-color-status-null);
	}

	&_Status--submitted {
		@include status-block($of-color-status-live);
	}

	&_Status--printready {
		@include status-block($of-color-status-ready);
	}

	&_Status--dataready {
		@include status-block($of-color-status-dataready);
	}

	&_Status--error,
	&_Status--rejected,
	&_Status--submissionError {
		@include status-block($of-color-status-error);
	}

	&_Status--cancelled {
		@include status-block($of-color-status-cancelled);
	}

	&_Status--resubmitted {
		@include status-block($of-color-status-resubmitted);
	}

	&_DataTableTitle {
		font-weight: $of-font-weight-bold;
		color: $of-color-dark;
		margin: 30px 0 15px;
	}
}

.FulfilmentView_DataTable {
	width: 90%;
	border-top: none;

	> tbody {
		tr td {
			border-top-width: 1px;
		}

		tr td {
			&::before {
				background-color: $of-color-base;
				font-size: $of-font-size-normal;
				text-transform: none;
				font-weight: $of-font-weight-regular;
				justify-content: flex-start;
				text-align: left;
			}
		}
	}
}

div#FulfilmentSubmittedModal {
	p {
		margin: 10px 30px 0;
	}
}
</style>
