<template>
	<DefaultLayout class="TransformerList">
		<OfsPanel class="mx-3 mb-3">
			<ListTable
				:table-title="$t('Transformer Schemas')"
				:config="config"
				bordered
				:items="transformers"
				:current-page="currentPage"
				:fields="fields"
				:total-items="count"
				:per-page="perPage"
				:selected="selected"
				:fetch-data="fetchData"
				:is-busy="isLoading"
				:page-position-prefix="$t('Showing')"
				:page-position-join="$t('of')"
				hover
				@row-clicked="handleRowClick"
				@table-change="handleTableChange"
			>
				<template slot="empty" slot-scope="{}">
					<span>
						<i>{{ $t('No data') }}</i>
					</span>
				</template>
				<template slot="next-text" slot-scope="{}">
					{{ $t('Next') }}
				</template>
				<template slot="prev-text" slot-scope="{}">
					{{ $t('Prev') }}
				</template>
			</ListTable>
		</OfsPanel>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ListTable, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import tableMixin from '../../mixins/tableMixin';
import navigationMixin from '../../mixins/navigationMixin';
import _map from 'lodash/map';
import _throttle from 'lodash/throttle';
import { $t } from '../../vuex';

export default {
	name: 'TransformerList',
	components: {
		ListTable,
		OfsPanel,
		DefaultLayout
	},
	mixins: [navigationMixin, tableMixin],
	data() {
		return {
			isLoading: false,
			fields: [
				{ key: 'name', label: this.$t('Name') },
				{ key: 'type', label: this.$t('Type') }
			],
			config: {
				filter: { visible: false },
				search: { visible: false },
				breadcrumbs: { visible: false },
				refresh: { visible: true, title: this.$t('Refresh') },
				columns: { visible: true, title: this.$t('Toggle columns') },
				add: {
					visible: true,
					href: { name: 'transformer.add' },
					title: this.$t('Add New')
				}
			},
			selected: []
		};
	},
	computed: {
		...mapGetters({
			count: 'transformer/count',
			transformers: 'transformer/transformers'
		})
	},
	created() {
		this.fetchData();
		this.selected = _map(this.fields, 'key');
	},
	methods: {
		...mapActions({
			getTransformers: 'transformer/find'
		}),
		fetchData: _throttle(
			async function() {
				try {
					this.isLoading = true;
					const query = { direction: 1, sort: 'name', pagesize: this.perPage, page: this.currentPage };
					this.updateUrl();
					await this.getTransformers({ query });
				} catch (err) {
					const message = $t(this.$errorUtils.displayError(err));
					this.$notify({ type: 'error ', text: message });
				} finally {
					this.isLoading = false;
				}
			},
			500,
			{ trailing: false }
		),
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') {
				return;
			}

			this.goTo({ name: 'transformer.edit', params: { id: item._id } });
		}
	}
};
</script>
