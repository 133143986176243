<template>
	<DefaultLayout>
		<ofs-panel v-if="isLoading">
			<Loader />
		</ofs-panel>
		<ofs-panel v-else class="FulfilmentItemDetails">
			<content-header :title="pageTitle" :breadcrumbs="breadcrumbs" no-padding class="mb-3">
				<b-btn v-if="itemStatus === 'error'" variant="primary" @click="refetch">{{ $t('Refetch') }}</b-btn>
			</content-header>
			<section class="FulfilmentItemDetails_container">
				<div :class="`FulfilmentItemDetails_Status FulfilmentItemDetails_Status--${itemStatus}`">
					<ofs-badge :text="getFulfilmentBadgeStatusText(itemStatus)" :status="`${itemStatus}`"></ofs-badge>
					<div class="FulfilmentItemDetails_Status_UpdatedAt">
						<span class="FulfilmentItemDetails_Status_UpdatedAt_Label">{{ $t('Updated at: ') }}</span>
						<span class="FulfilmentItemDetails_Status_UpdatedAt_Value">{{ formatDate(updatedAt) }}</span>
						<span class="FulfilmentItemDetails_Status_UpdatedAt_Ago">- {{ updatedAt | fromDate }}</span>
					</div>
					<b-button
						class="FulfilmentItemDetails_Status_RefreshButton"
						variant="secondary"
						@click.prevent="fetchData"
					>
						{{ $t('Refresh') }}
					</b-button>
				</div>
				<b-row>
					<b-col>
						<div>
							<p class="FulfilmentItemDetails_DataTableTitle">{{ $t('Source order details') }}</p>
							<b-table
								id="SourceOrderDetailsTable"
								class="FulfilmentItemDetails_DataTable"
								stacked
								:fields="sourceOrderDetailsTableFields"
								:items="[item]"
							>
								<template #cell(hpSpecificationId)="{ value }">
									<router-link
										:to="{
											name: 'admin.hp-spec-templates.view',
											params: { id: value }
										}"
									>
										{{ specTemplate.name }}
									</router-link>
								</template>
								<template #cell(siteflowSKU)>
									{{ siteflowSKU }}
								</template>
								<template #cell(status)>
									<ofs-badge
										:text="getFulfilmentBadgeStatusText(sourceOrder.status)"
										:status="sourceOrder.status"
									/>
								</template>
							</b-table>
						</div>
						<div>
							<p class="FulfilmentItemDetails_DataTableTitle">{{ $t('Fulfilment Details') }}</p>
							<b-table
								id="FulfilmentDetailsTable"
								class="FulfilmentItemDetails_DataTable"
								stacked
								:fields="fulfilmentDetailsTableFields"
								:items="[fulfilmentDetails]"
							>
								<template #cell(siteFlowSourceItemId)>
									{{ fulfilmentItem.siteFlowSourceItemId }}
								</template>
								<template #cell(status)="{ value }">
									<ofs-badge :text="getFulfilmentBadgeStatusText(value)" :status="value" />
								</template>
							</b-table>
						</div>
					</b-col>
					<b-col>
						<div>
							<p class="FulfilmentItemDetails_DataTableTitle">{{ $t('Component Details') }}</p>
							<b-table
								v-for="component in item.components"
								id="ComponentDetailsTable"
								:key="component.id"
								class="FulfilmentItemDetails_DataTable"
								stacked
								:fields="componentDetailsTableFields"
								:items="[component]"
							>
								<template #table-caption>{{ component.code | formatComponentCode }}</template>
								<template #cell(url)="{ item }">
									<a :href="item.url" target="_blank" rel="noopener noreferrer">{{ $t('View') }}</a>
								</template>
								<template #cell(specCheck)="{ item }">
									<ofs-badge
										:text="getBadgeStatusText(specCheckStatus(item))"
										:status="specCheckStatus(item)"
									/>
									<span
										v-if="specCheckStatus(item) === 'error'"
										class="FulfilmentItemDetails_DataTable_ErrorLink"
										@click="openErrorReportModal(item)"
									>
										{{ $t('Error report') }}
									</span>
								</template>
							</b-table>
						</div>
					</b-col>
				</b-row>
				<b-modal id="ErrorReportModal" :title="$t('Error Report')" size="lg">
					<div>
						<b-table id="FulfilmentItemDetails_ErrorModalTable" :fields="errorModalTableFields" :items="errorModalData.errorList">
							<template #cell(description)="{ value }">
								<div class="FulfilmentItemDetails_ErrorModalTable_ErrorDescription">{{ value }}</div>
								<div v-if="errorModalData.reportUrl" class="FulfilmentItemDetails_ErrorModalTable_ErrorLink">
									<a
										:href="errorModalData.reportUrl"
										target="_blank"
									>
										{{ errorModalData.reportUrl }}
									</a>
								</div>
							</template>
						</b-table>
					</div>
					<template #modal-footer><div></div></template>
				</b-modal>
			</section>
		</ofs-panel>
		<refetch-modal :components="item.components" :account-id="fulfilment.accountId" :item-id="item.id" :show="shouldShowRefetchModal" :on-close="closeRefetchModal" />
	</DefaultLayout>
</template>

<script>
import _ from 'lodash';
import {
	OfsPanel,
	OfsBadge,
	OfsFeatureButton,
	OfFormInput,
	OfFormSelect,
	OfMultiSelect,
	withForm,
	ContentHeader
} from '@workflow-solutions/ofs-vue-layout';
import { mapActions, mapGetters } from 'vuex';
import DefaultLayout from '../../../components/DefaultLayout';
import Loader from '../../../components/Loader';
import {
	displayError,
	getFulfilmentBadgeStatusText,
	getBadgeStatusText,
	getBadgeStatusVariant
} from '../../../lib/helpers';
import RefetchModal from './RefetchModal';
import { dateFormatMixin } from '../../../mixins/dateFormatMixin';
import { confirmModalMixin } from '../../../mixins/confirmModalMixin';
import isFulfilmentAdminMixin from '../../../mixins/isFulfilmentAdminMixin';

export default {
	components: {
		DefaultLayout,
		Loader,
		OfsPanel,
		OfsBadge,
		RefetchModal,
		ContentHeader
	},
	mixins: [isFulfilmentAdminMixin, dateFormatMixin(), confirmModalMixin()],
	filters: {
		formatComponentCode(code) {
			return code === 'text' ? 'inner content' : code;
		}
	},
	data() {
		const emptyFormatter = value => value || '-';
		const emptyDateFormatter = value => (value ? this.formatDate(value) : '-');

		const sourceOrderDetailsTableFields = [
			{ key: 'sourceItemId', label: this.$t('Source Item ID'), formatter: emptyFormatter },
			{ key: 'quantity', label: this.$t('Quantity'), formatter: emptyFormatter },
			{ key: 'sourceSpecId', label: this.$t('HP Spec'), formatter: emptyFormatter },
			{ key: 'hpSpecificationId', label: this.$t('HP Spec Template'), formatter: emptyFormatter },
			{ key: 'siteflowSKU', label: this.$t('Site Flow SKU'), formatter: emptyFormatter },
			{ key: 'sourceOrderId', label: this.$t('Source Order ID'), formatter: emptyFormatter },
			{ key: 'status', label: this.$t('Order Status'), formatter: emptyFormatter }
		];

		const fulfilmentDetailsTableFields = [
			{ key: 'fulfilmentItemId', label: this.$t('Item ID'), formatter: emptyFormatter },
			{ key: 'siteFlowSourceItemId', label: this.$t('Site Flow Source Item ID'), formatter: emptyFormatter },
			{ key: 'siteFlowSourceOrderId', label: this.$t('Site Flow Source Order ID'), formatter: emptyFormatter },
			{
				key: 'status',
				label: this.$t('Fulfilment Status'),
				formatter: value => (value === null || value ? String(value) : '-')
			}
		];

		const componentDetailsTableFields = [
			{ key: 'url', label: this.$t('URL') },
			{ key: 'pageCount', label: this.$t('Page Count') },
			{ key: 'specCheck', label: this.$t('Spec Check') }
		];

		const errorModalTableFields = [
			{ key: 'description', label: this.$t('Description') },
			{ key: 'timestamp', label: this.$t('Timestamp'), formatter: value => this.formatDate(value) }
		]

		return {
			emptyFormatter,
			emptyDateFormatter,
			sourceOrderDetailsTableFields,
			fulfilmentDetailsTableFields,
			componentDetailsTableFields,
			errorModalTableFields,
			errorModalData: {
				errorList: [],
				reportUrl: ''
			},
			isLoading: true,
			sourceOrder: {},
			shouldShowRefetchModal: false
		};
	},
	computed: {
		...mapGetters({
			fulfilment: 'fulfilment/fulfilment',
			fulfilmentItems: 'fulfilment/items',
			item: 'fulfilmentItem/fulfilmentItem',
			files: 'fulfilmentItem/files',
			specTemplate: 'hpSpecTemplate/hpSpecTemplate',
			accountList: 'account/accountList',
			lang: 'lang/lang'
		}),
		id() {
			return this.$route.params.id;
		},
		itemId() {
			return this.$route.params.itemId;
		},
		pageTitle() {
			return `Item ID ${_.get(this.item, 'sourceItemId')}`;
		},
		breadcrumbs() {
			return [
				{
					text: this.$t('Fulfilments'),
					to: {
						name: 'admin.fulfilments'
					}
				},
				{
					text: `${this.$t('Fulfilment')} ${_.get(this.fulfilment, 'siteFlowSourceOrderId')}`,
					to: {
						name: 'admin.fulfilments.view',
						params: { id: this.id }
					}
				},
				{
					text: this.pageTitle,
					active: true
				}
			];
		},
		status() {
			return `${_.get(this.fulfilment, 'status', 'null')}`;
		},
		itemStatus() {
			return _.get(this.item, 'status');
		},
		siteflowSKU() {
			return this.specTemplate?.siteflowSKU;
		},
		updatedAt() {
			return this.fulfilment?.[`on${_.capitalize(this.status)}At`] ?? this.fulfilment?.updatedAt;
		},
		fulfilmentDetails() {
			return {
				...this.fulfilment,
				fulfilmentItemId: this.fulfilmentItem._id
			};
		}
	},
	watch: {
		id: {
			handler: 'fetchData'
		},
		itemId: {
			handler: 'fetchData',
			immediate: true
		}
	},
	methods: {
		...mapActions({
			findFulfilmentOrder: 'fulfilmentOrder/findByIdWithAccount',
			findFulfilment: 'fulfilment/findById',
			findFulfilmentItemById: 'fulfilment/findItemById',
			findSpecTemplate: 'hpSpecTemplate/findById',
			findItem: 'fulfilmentItem/findByIdWithAccount',
			getItemsByShipmentId: 'fulfilmentItem/itemsByShipmentId',
			refetchItem: 'fulfilmentItem/refetch',
			getFiles: 'fulfilmentItem/getFilesByItemId'
		}),
		async fetchData() {
			this.isLoading = true;
			try {
				if (this.id && this.itemId) {
					await this.findFulfilment({ id: this.id, query: { query: { $populate: { path: 'items' } } } });
					this.sourceOrder = await this.findFulfilmentOrder({
						id: this.fulfilment.orderId,
						accountId: this.fulfilment.accountId
					});
					this.fulfilmentItem = await this.findFulfilmentItemById({ 
						id: this.itemId, 
						accountId: this.fulfilment.accountId
					});

					const { itemId } = this.fulfilmentItem;
					await this.findItem({ id: itemId, accountId: this.fulfilment.accountId });
					const { hpSpecificationId } = this.item;
					await this.findSpecTemplate({ id: hpSpecificationId });
					await this.getFiles({ id: itemId, accountId: this.fulfilment.accountId });
				}
			} catch (err) {
				this.$notify({ type: 'error', text: displayError(err) });
				this.$router.push({ name: 'admin.fulfilments' });
			} finally {
				this.isLoading = false;
			}
		},
		async refetch() {
			this.shouldShowRefetchModal = true;			
		},
		closeRefetchModal(reload) {
			this.shouldShowRefetchModal = false;
			
			if (reload) {
				this.fetchData();
			}
		},
		specCheckStatus({ fileId }) {
			const file = _.find(this.files, { id: fileId });
			return _.get(file, 'specCheck.status', 'null');
		},
		specCheckReportUrl({ fileId }) {
			const file = _.find(this.files, { id: fileId });
			return _.get(file, 'specCheck.reportUrl', 'null');
		},
		openErrorReportModal({ fileId }) {
			const file = _.find(this.files, { id: fileId });
			this.errorModalData = {
				errorList: _.get(file, 'specCheck.errorList'),
				reportUrl: _.get(file, 'specCheck.reportUrl')
			};

			this.$bvModal.show('ErrorReportModal');
		},
		getFulfilmentBadgeStatusText,
		getBadgeStatusText,
		getBadgeStatusVariant
	}
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

@mixin status-block($color) {
	background-color: rgba($color, 0.1);
	border-color: $color;
}

.FulfilmentItemDetails {
	&_Status {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 30px 0 0;
		padding: 15px 20px 16px 20px;
		border: 1px solid $of-color-grey-2;
		border-radius: 3px;

		&_RefreshButton {
			width: 72px;
			height: 29px;
			border: 1px solid $of-color-grey-2;
			border-radius: 3px;
			padding: 7px 12px;
		}

		&_UpdatedAt {
			display: flex;
			flex: 1;
			margin-left: 30px;
			flex-direction: row;
			justify-content: flex-start;

			&_Label {
				color: $of-color-grey-2;
			}

			&_Value {
				margin: 0 5px;
			}

			&_Ago {
				font-style: italic;
			}
		}
	}

	&_Status--null {
		@include status-block($of-color-status-null);
	}

	&_Status--submitted {
		@include status-block($of-color-status-live);
	}

	&_Status--printready {
		@include status-block($of-color-status-ready);
	}

	&_Status--dataready {
		@include status-block($of-color-status-dataready);
	}

	&_Status--error,
	&_Status--rejected,
	&_Status--submissionError {
		@include status-block($of-color-status-error);
	}

	&_Status--cancelled {
		@include status-block($of-color-status-cancelled);
	}

	&_Status--resubmitted {
		@include status-block($of-color-status-resubmitted);
	}

	#SourceOrderDetailsTable,
	#FulfilmentDetailsTable,
	#ComponentDetailsTable {
		width: 90%;

		tr td {
			border-top-width: 1px;
		}

		tr td {
			&::before {
				background-color: $of-color-base;
				font-size: $of-font-size-normal;
				text-transform: none;
				font-weight: $of-font-weight-regular;
				justify-content: flex-start;
			}
		}
	}

	&_DataTableTitle {
		font-weight: $of-font-weight-bold;
		color: $of-color-dark;
		margin: 30px 0 15px;
	}

	&_DataTable caption {
		color: $of-color-dark;
		font-weight: $of-font-weight-bold;
		text-transform: uppercase;
		background: $of-color-highlights;
		padding: 3px 12px;
	}

	&_DataTable_ErrorLink {
		margin-left: 16px;
		cursor: pointer;
		color: $of-color-aqua;

		&:hover {
			text-decoration: underline;
		}
	}

	.OfsBadge {
		height: 20px;
		line-height: 20px;
	}
}

#FulfilmentItemDetails_ErrorModalTable {
	td {
		vertical-align: text-top;
	}

	.FulfilmentItemDetails_ErrorModalTable_ErrorLink {
		margin-top: 30px;
	}

	.FulfilmentItemDetails_ErrorModalTable_ErrorDescription {
		word-break: break-all;
	}
}

</style>
