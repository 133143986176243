import DeviceTypeList from './DeviceTypeList';
import DeviceTypeEdit from './DeviceTypeEdit';

const routes = [
	{
		path: '/device-type',
		component: { template: '<router-view/>' },
		children: [
			{
				name: 'deviceType.list',
				path: 'list',
				component: DeviceTypeList
			},
			{
				name: 'deviceType.edit',
				path: 'edit/:id',
				component: DeviceTypeEdit
			},
			{
				name: 'deviceType.add',
				path: 'add',
				component: DeviceTypeEdit
			}
		]
	}
];

export default routes;
