import _ from 'lodash';

export default {
	data() {
		return {
			firstPage: 1,
			minPerPage: 10,
			maxPerPage: 50
		};
	},
	computed: {
		currentPage() {
			return _.toInteger(_.get(this.$route, 'query.currentPage', this.firstPage));
		},
		perPage() {
			let perPage = _.toInteger(_.get(this.$route, 'query.perPage', this.minPerPage));
			if (perPage > this.maxPerPage) {
				perPage = this.maxPerPage;
			}
			return perPage;
		},
		filter() {
			const queryFilter = _.get(this.$route, 'query.filter');
			return typeof queryFilter === 'string' ? queryFilter === 'true' : queryFilter;
		},
		queryFilters() {
			return this.$route.query.filters;
		}
	},
	mounted() {
		this.throttledFetchData = _.throttle(this.fetchData, 500);
	},
	methods: {
		updateUrl({
			name = this.$route.name,
			useParams,
			currentPage = this.currentPage,
			perPage = this.perPage,
			queryFilters = this.queryFilters,
			...params
		} = {}) {
			const query = {
				...params,
				currentPage,
				perPage
			};
			if (queryFilters) {
				query.filters = queryFilters;
			}

			const location = {
				name,
				query
			};

			if (useParams) {
				location.params = this.$route.params;
			}

			this.$router.replace(location).catch(() => {});;
		},
		handleTableChange({ currentPage, perPage, filter, selectedCols, sort }) {
			if (selectedCols) {
				this.selected = selectedCols;
			}
			if (sort) {
				this.sort = sort;
			}
			if (filter !== undefined) {
				this.updateUrl({ currentPage: this.firstPage, perPage: this.minPerPage, sort: this.sort, filter });
			} else if (this.filter !== undefined) {
				this.updateUrl({ currentPage, perPage, sort: this.sort, filter: this.filter });
			} else {
				this.updateUrl({ currentPage, perPage, sort: this.sort });
			}
		},
		setQueryWhereFromQueryFilters({ query }) {
			if (!query) {
				return;
			}

			if (!_.isEmpty(this.queryFilters)) {
				query.$where = _.assign(
					{},
					..._.map(this.queryFilters, (filter, key) =>
						_.isArray(filter) ? ({ [key]: { $in: filter } }) : ({ [key]: filter })
					)
				);
			}
		}
	},
	watch: {
		'$route.query'() {
			this.throttledFetchData();
		}
	}
};
