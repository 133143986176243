<template>
	<DefaultLayout class="OrderList">
		<OfsPanel class="mx-3 mb-3">
			<div class="dataready-summary">
				<h1>{{ $t('Summary') }}</h1>
				<b-button class="summary-button" @click="changeDateFilters(dateDefaults.lastHour)">
					<p>{{ $t('Last 1h') }}</p>
					<h2>{{ orderSummary.lastHour || 0 }}</h2>
				</b-button>
				<b-button class="summary-button" @click="changeDateFilters(dateDefaults.lastDay)">
					<p>{{ $t('Last day') }}</p>
					<h2>{{ orderSummary.lastDay || 0 }}</h2>
				</b-button>
				<b-button class="summary-button" @click="changeDateFilters(dateDefaults.lastThreeDays)">
					<p>{{ $t('Last 3 days') }}</p>
					<h2>{{ orderSummary.lastThreeDays || 0 }}</h2>
				</b-button>
				<b-button class="summary-button" @click="changeDateFilters(dateDefaults.lastWeek)">
					<p>{{ $t('Last week') }}</p>
					<h2>{{ orderSummary.lastWeek || 0 }}</h2>
				</b-button>
				<b-button
					v-if="!isLiveEnvironment"
					class="summary-button"
					@click="changeDateFilters(dateDefaults.lastThreeMonths)"
				>
					<p>{{ $t('Last 3 months') }}</p>
					<h2>{{ orderSummary.lastThreeMonths || 0 }}</h2>
				</b-button>
			</div>
			<div>
				<ListTable
					:table-title="$t('Data Ready Orders')"
					:config="config"
					bordered
					:items="paginatedOrders"
					:current-page="currentPage"
					:fields="fields"
					:total-items="filteredOrdersCount"
					:per-page="perPage"
					:selected="selected"
					:fetch-data="filterOrders"
					:is-busy="isLoading"
					:page-position-prefix="$t('Showing')"
					:page-position-join="$t('of')"
					selectable
					hover
					@row-clicked="handleRowClick"
					@table-change="handleTableChange"
					@row-selected="onOrderSelected"
				>
					<template #cell(actions)="{ item }">
						<b-button variant="primary" @click="refetch([item._id])"> {{ $t('Refetch') }}</b-button>
					</template>

					<template #cell(links)="{ item }">
						<a :href="buildSiteflowLink(item._id)" target="_blank">{{ $t('Site Flow order') }}</a>
						<br />
						<a :href="buildWorkqueuesLink(item._id)" target="_blank">{{ $t('Order workqueues') }}</a>
					</template>

					<template #TableButtons-Slot-left>
						<b-button
							v-if="selectedOrders.length"
							variant="primary"
							class="refetch-button"
							@click="refetchSelected()"
						>
							{{ $t('Refetch selected') }}</b-button
						>
						<b-button variant="primary" class="refetch-button" @click="refetchAll()">
							{{ $t('Refetch all') }}</b-button
						>
					</template>
					<template slot="empty" slot-scope="{}">
						<span>
							<i>{{ $t('No data') }}</i>
						</span>
					</template>
					<template slot="next-text" slot-scope="{}">
						{{ $t('Next') }}
					</template>
					<template slot="prev-text" slot-scope="{}">
						{{ $t('Prev') }}
					</template>
				</ListTable>
			</div>
		</OfsPanel>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ListTable, OfsPanel } from '@workflow-solutions/ofs-vue-layout';
import DefaultLayout from '../../components/DefaultLayout';
import navigationMixin from '../../mixins/navigationMixin';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _throttle from 'lodash/throttle';
import { $t } from '../../vuex';

const hoursToMs = 60 * 60 * 1000;

export default {
	name: 'DataReadyOrdersList',
	components: {
		ListTable,
		OfsPanel,
		DefaultLayout
	},
	mixins: [navigationMixin],
	data() {
		return {
			isLoading: false,
			dateDefaults: {
				minDate: new Date(Date.now() - hoursToMs * 24 * 7),
				maxDate: new Date(Date.now() - hoursToMs / 3), // 20 minutes ago
				lastHour: new Date(Date.now() - hoursToMs),
				lastDay: new Date(Date.now() - hoursToMs * 24),
				lastThreeDays: new Date(Date.now() - hoursToMs * 24 * 3),
				lastWeek: new Date(Date.now() - hoursToMs * 24 * 7),
				lastThreeMonths: new Date(Date.now() - hoursToMs * 24 * (90 - 1))
			},
			selectedFromDate: new Date(),
			selectedToDate: new Date(),
			selectedOrders: [],
			paginatedOrders: [],
			firstPage: 1,
			currentPage: 1,
			perPage: 10,
			fields: [
				{ key: '_id', label: this.$t('Id') },
				{ key: 'sourceName', label: this.$t('Source Name') },
				{ key: 'destinationName', label: this.$t('Destination Name') },
				{ key: 'date', label: this.$t('Date') },
				{ key: 'links', label: this.$t('Links') },
				{ key: 'actions', label: this.$t('Actions') }
			],
			config: {
				filter: { visible: false },
				search: { visible: false },
				breadcrumbs: { visible: false },
				refresh: { visible: false },
				columns: { visible: false }
			},
			selected: []
		};
	},
	computed: {
		...mapGetters({
			count: 'controlCentreDataReadyOrder/count',
			orders: 'controlCentreDataReadyOrder/order/dataready'
		}),
		filteredOrders() {
			const filtered = this.orders?.filter(
				order => new Date(order.date) >= this.selectedFromDate && new Date(order.date) <= this.selectedToDate
			);
			return filtered;
		},
		filteredOrdersCount() {
			return this.filteredOrders?.length || 0;
		},
		orderSummary() {
			const counters = {
				lastHour: 0,
				lastDay: 0,
				lastThreeDays: 0,
				lastWeek: 0,
				lastThreeMonths: 0,
				total: 0
			};
			return _.reduce(
				this.orders || [],
				(acc, order) => {
					const orderDate = new Date(order.date);
					if (orderDate > this.dateDefaults.lastHour) {
						acc.lastHour++;
					}
					if (orderDate > this.dateDefaults.lastDay) {
						acc.lastDay++;
					}
					if (orderDate > this.dateDefaults.lastThreeDays) {
						acc.lastThreeDays++;
					}
					if (orderDate > this.dateDefaults.lastWeek) {
						acc.lastWeek++;
					}
					if (orderDate > this.dateDefaults.lastThreeMonths) {
						acc.lastThreeMonths++;
					}
					acc.total++;
					return acc;
				},
				counters
			);
		},
		isLiveEnvironment() {
			return _get(window.$config, 'environment', 'dev') === 'live';
		}
	},
	mounted() {
		// increase range for testing purposes
		this.dateDefaults.minDate = this.isLiveEnvironment
			? this.dateDefaults.lastWeek
			: this.dateDefaults.lastThreeMonths;

		this.fetchData();
		this.changeDateFilters();
		this.selected = _map(this.fields, 'key');
	},
	methods: {
		...mapActions({
			fetchDataReadyOrders: 'controlCentreDataReadyOrder/findAll'
		}),
		async fetchData() {
			try {
				this.isLoading = true;
				const query = {
					query: {
						$where: {
							date: { $gte: this.dateDefaults.minDate, $lte: this.dateDefaults.maxDate }
						}
					}
				};
				await this.fetchDataReadyOrders({ query });
				this.filterOrders();
			} catch (err) {
				const message = $t(this.$errorUtils.displayError(err));
				this.$notify({ type: 'error ', text: message });
			} finally {
				this.isLoading = false;
			}
		},
		handleRowClick(item, index, event) {
			if (event.target.type === 'button') {
				return;
			}

			// prepared for future use to display more details
		},
		handleTableChange({ currentPage, perPage, filter, selectedCols, sort }) {
			if (selectedCols) {
				this.selected = selectedCols;
			}
			this.currentPage = currentPage;
			this.perPage = perPage;
			this.filterOrders();
		},
		filterOrders() {
			this.paginatedOrders = this.filteredOrders.slice(
				this.perPage * (this.currentPage - 1),
				this.perPage * this.currentPage
			);
		},
		changeDateFilters(fromDate, toDate) {
			this.selectedFromDate = fromDate || this.dateDefaults.minDate;
			this.selectedToDate = toDate || this.dateDefaults.maxDate;
			this.currentPage = 1;
			this.filterOrders();
		},
		onOrderSelected(orders) {
			this.selectedOrders = orders;
		},
		async refetchAll() {
			await this.refetch(_map(this.filteredOrders || [], '_id'));
		},
		async refetchSelected() {
			await this.refetch(_map(this.selectedOrders || [], '_id'));
		},
		async refetch(orderIds) {
			try {
				this.isLoading = true;
				const result = await this.$store.dispatch('controlCentreDataReadyOrder/refetch', { orderIds });
				this.fetchData();
				if (result.errors?.length > 0) {
					this.$notify({ type: 'info', text: $t('Order files refetched with some errors') });
				} else {
					this.$notify({ type: 'success', text: $t('Order files refetched') });
				}
			} catch (err) {
				const message = $t(this.$errorUtils.displayError(err));
				this.$notify({ type: 'error ', text: message });
			} finally {
				this.isLoading = false;
			}
		},
		buildSiteflowLink(orderId) {
			const siteflowUIBase = _get(window.$config, 'siteflowUIBase');
			return `${siteflowUIBase}/orders/#/order/view/${orderId}`;
		},
		buildWorkqueuesLink(orderId) {
			const workforceUIBase = _get(window.$config, 'workforceUIBase');
			const connectAppId = _get(window.$config, 'connectAppId');
			return `${workforceUIBase}/#/apps/${connectAppId}/workqueues?tags[]=orderId%3A${orderId}`;
		}
	}
};
</script>
<style lang="scss" scoped>
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';

.refetch-button {
	margin-left: 0.5em;
}

.summary-button {
	min-width: 7em;
	display: inline-table;
	margin: 0.5em;
	margin-bottom: 1em;
}
</style>
