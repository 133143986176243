<template>
	<div class="TestExpressions">
		<b-btn v-t="'Test expressions'" data-test-id="TestExpressionsButton" @click="showModal" />
		<b-modal hide-footer content-class="TestExpressions" v-model="isVisible" :title="$t('Test Expressions') ">
			<b-form>
				<b-col>
					<of-form-input
						name="expression"
						type="text"
						data-test-id="TestExpressionsInput"
						:label="$t('Enter shipto.state input you wish to test')"
						@keydown.enter.native="testExpressions">
						<template slot="append">
							<b-btn 
								v-t="'Test'" 
								@click="testExpressions"
								:disabled="!this.formData || !this.formData.expression"
							/>
						</template>
					</of-form-input>
				</b-col>
				<hr class="form-divider" />
				<div>
					<b-col>
						<b-row class="testExpressionResults-row">
							<h3 v-t="'Results'"/>
							<h3 class="results-number">{{numResultsText}}</h3>
						</b-row>
					</b-col>
					<div :class="{'testExpressionResults--error': isError, 
								  'testExpressionResults': true }">
						<b-col>
							<span>{{resultsText}}</span>
						</b-col>
						<div>
							<b-row class="testExpressionResults-row">
								<div 
									v-for="(item, index) in matchedResults"
									:key="index">
									<b-col class="p-1">
										<span class="testExpressionResults-items" v-text="item"/>
									</b-col>
								</div>
							</b-row>
						</div>
						<b-col>
							<span>{{resultsBottomText}}</span>
						</b-col>
					</div>
				</div>
			</b-form>
		</b-modal>
	</div>
</template>

<script>
import _ from 'lodash';
import { OfFormInput, withForm } from '@workflow-solutions/ofs-vue-layout';

export default {
	components: {
		OfFormInput,
	},
	mixins: [withForm('TestExpressions')],
	props: {
		states: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			resultsText: this.$t('Enter state above and press enter'),
			resultsBottomText: "",
			numResultsText: "",
			isError: false,
			isVisible: false,
			matchedResults: []
		};
	},
	computed: {},
	methods: {
		onClose() {
			this.isVisible = false;
		},
		showModal() {
			this.resultsText = this.$t('Enter state above and press enter');
			this.resultsBottomText = "";
			this.numResultsText = "";
			this.isError = false;
			this.isVisible = true;
			this.matchedResults = [];
			this.resetFormData();
		},
		testExpressions(event) {
			event.preventDefault();
			const matchedStates = this.states.reduce((matches, state) => {
				if (
					[state.displayName.toLowerCase(), state.normalisedState.toLowerCase()].includes(
						this.formData.expression.toLowerCase(),
					) ||
					_.some(state.regularExpressions.map(exp => exp.value), regex => new RegExp(regex, 'gi').test(this.formData.expression))
				) {
					return [...matches, state];
				}
				return matches;
			}, []);

			this.numResultsText = `(${matchedStates.length})`;
			this.matchedResults = matchedStates.map(state => state.displayName);
			if (matchedStates.length === 1) {
				this.isError = false
				this.resultsText = this.$t('1 state found');
				this.resultsBottomText = '';
			} else {
				this.isError = true;
				this.resultsText = matchedStates.length ? this.$t('Multiple states found') : this.$t('No state/region found');
				this.resultsBottomText = this.$t('Please check regular expressions or test input and try again');
			}
		}
	},
};
</script>

<style lang="scss">
@import '~@workflow-solutions/ofs-vue-layout/dist/style/variables';
.TestExpressions {
	margin-top: 15px;
	margin-right: 10px;
	display: flex;
	flex-direction: column;

	&-header {
		display: flex;
		margin-bottom: 5px;
	}

	.testExpressionResults {
		font-style: italic;
		color: $of-color-grey-2;

		&-row {
			margin-left: 0;
			margin-bottom: 5px;
		}

		&-number {
			font-style: normal;
			font-weight: normal;
			margin-left: 5px;
			color: $of-color-grey-2;
		}

		&--error {
			color: $of-color-red;
		}

		&-items {
			font-style: normal;
			color: $of-color-grey-1;
			background-color: $of-color-highlights;
			border-radius: 3px;
			padding: 4px 8px;
			font-size: 14px;
		}
	}
}


</style>
